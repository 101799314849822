<script setup>

import { CheckOutlined, EditOutlined } from '@ant-design/icons-vue'
import { useModelWrapper } from '@/helpers/ModelWrapper'
import { ref } from 'vue'


const props = defineProps({
  name: String,
})

const emit = defineEmits(['save', 'cancel', 'start-edit', 'update:name'])

const isSaving = ref(false)
const nameValue = useModelWrapper(props, emit, 'name')

const handleBlur = () => {
  if (!isSaving.value) {
    emit('cancel')
  }
}

const handleSave = () => {
  isSaving.value = true
  emit('save')
  setTimeout(() => {
    isSaving.value = false
  }, 50)
}
</script>

<template>
  <div class="editable-cell">
    <slot name="pre" />
    <div
      v-if="typeof nameValue === 'string'"
      class="editable-cell-input-wrapper"
    >
      <a-input
        :ref="(el) => el && el.focus()"
        v-model:value="nameValue"
        style="margin-left: 4px;"
        @blur="handleBlur"
        @press-enter="$emit('save')"
        @keydown.esc.prevent.stop="$emit('cancel')"
      />
      <CheckOutlined
        class="editable-cell-icon-check"
        @mousedown.stop="handleSave"
      />
    </div>
    <div
      v-else
      class="editable-cell-text-wrapper"
    >
      <a-tooltip :title="$t(`components.devicesTable.edit`)">
        <EditOutlined
          class="editable-cell-icon"
          @click.stop="$emit('start-edit')"
        />
      </a-tooltip>
      <span class="editable-cell-text-overflow">
        <slot name="text" />
      </span>
    </div>
  </div>
</template>

<style scoped lang="less">
.editable-cell {
  display: flex;
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
    display: flex;
    gap: 4px;
  }

  .editable-cell-text-overflow {
    overflow: hidden;
    display: inherit;
    text-overflow: ellipsis;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 6px;
  }

  .editable-cell-icon {
    justify-content: center;
    align-items: center;
    margin: 0;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: flex;
  color: var(--ant-primary-color);
}
</style>
