<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const activeObject = computed(() => store.getters['customTemplate/activeObject'])

const opacity = computed({
  get: () => activeObject.value ? (activeObject.value.opacity * 100) : 100,
  set: (value) => {
    if (activeObject.value) {
      store.dispatch('customTemplate/changeSelectedObjectOpacity', value / 100)
    }
  }
})

const color = computed({
  get: () => activeObject.value ? activeObject.value.fill : '#000000',
  set: (value) => {
    if (activeObject.value) {
      if (activeObject.value.type === 'group') {
        activeObject.value.getObjects().forEach(obj => obj.set({ fill: value }));
      } else {
        activeObject.value.set({ fill: value });
      }
      store.dispatch('customTemplate/setActiveObjectProperty', {
        name: 'fill',
        value: value
      })
    }
  }
})

</script>

<template>
  <div id="sticker-controls">
    <div style="display: flex; gap: 8px; width: 100%; align-items: center; width: 200px;">
      Opacity
      <a-slider
        id="test"
        v-model:value="opacity"
        style="flex: 1;"
      />
      {{ opacity.toFixed(0) }}%
    </div>
    <a-input
      v-model:value="color"
      style="width: 60px;"
      type="color"
    />
  </div>
</template>

<style scoped lang="less">
#sticker-controls {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
}
</style>
