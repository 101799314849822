import { error, success } from '@/utils'
import { playlistState } from '@/views/PlaylistPage/composables/playlistState'
import { computed } from 'vue'
import { useStore } from 'vuex'

const getSlideDtoWithoutWrapper = slide => slide.getDtoWithoutWrapper()

export function usePlaylistActions() {
  const store = useStore()
  const groupId = computed(() => store.getters['groups/currentGroupId'])


  const openModal = (modalKey) => {
    playlistState.showModals[modalKey] = true
  }

  const closeModal = (modalKey) => {
    playlistState.showModals[modalKey] = false
  }


  const updatePlaylist = ({
    input,
    changeLogs
  }) => {
    return store.dispatch('playlist/updatePlaylist', {
      input,
      changeLogs
    }).then(() => {
      success()
      resetMultipleSelection()
    }).catch((e) => {
      error(e.message)
    })
  }

  const startPlaylistEdit = ()=> {
    openModal('playlist')
  }

  const startPlaylistMove = () => {
    playlistState.duplicatePlaylistOnMove = false
    openModal('playlistMove')
  }

  const startPlaylistDuplicate = () => {
    playlistState.duplicatePlaylistOnMove = true
    openModal('playlistMove')
  }

  const setPreferredPlaylist = (playlistId) => {
    const preferredPlaylists = JSON.parse(window.localStorage.getItem('preferredGroupPlaylists')) || {}
    preferredPlaylists[groupId.value] = playlistId
    window.localStorage.setItem('preferredGroupPlaylists', JSON.stringify(preferredPlaylists))
  }

  const changeZoning = ({
    type,
    zones: zonesCount
  }) => {
    const zoneKeys = [...Object.keys(playlistState.zoneSlideLists)].slice(0, zonesCount)

    const zones = zoneKeys.map(zoneKey => {
      return { slides: playlistState.zoneSlideLists[zoneKey].map(getSlideDtoWithoutWrapper) }
    })

    const input = {
      layout: {
        type,
        zones: zones
      }
    }

    const changeLogs = [{
      zoneSettingsUpdated: {}
    }]

    updatePlaylist({
      input,
      changeLogs
    }).then(() => {
      playlistState.openedLayoutZones = [...zoneKeys]
    })
  }

  const resetSelection = (keepSlideIntact) => {
    !keepSlideIntact && playlistState.activeSlide?.resetSlide()
    playlistState.activeSlide = null
    playlistState.selectedZoneKey = null
  }

  const resetMultipleSelection = () => {
    playlistState.selectedSlideIds.clear()
  }

  const deactivateForcedPlaylist = async () => {
    const payload = {
      groupId: forcedPlaylist.value?.groupId,
      input: {
        data: {
          forcedPlaylistId: null
        }
      }
    }
    return store.dispatch('groups/updateGroupScheduleByGroupId', payload).then(() => {
      success()
    })
  }


  return {
    //Modals
    openModal,
    closeModal,
    //PlaylistActions
    startPlaylistEdit,
    startPlaylistMove,
    startPlaylistDuplicate,
    updatePlaylist,
    setPreferredPlaylist,
    //ZoningActions
    changeZoning,
    //General
    resetSelection,
    resetMultipleSelection
  }
}
