<template>
  <a-layout-header
    style="padding: 0; display: flex;"
    class="top-navbar"
  >
    <a-layout>
      <a-layout-sider
        :width="INNER_SIDER_WIDTH"
        class="top-navbar-title"
      >
        <a-spin
          :spinning="loading"
          class="group-title"
        >
          {{ groupId ? groupName : $t('components.groupsNavbar.loading') }}
        </a-spin>
      </a-layout-sider>
      <a-layout-content style="display: flex">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          theme="light"
          mode="horizontal"
          :style="{ lineHeight: '64px', flex: 1 }"
        >
          <template v-if="groupId">
            <a-menu-item key="playlists">
              <router-link :to="{name:'Groups'}">
                <PlayCircleOutlined />
                {{ $t('components.groupsNavbar.menuItems.playlists') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="schedule">
              <router-link :to="{name:'WeekLoop'}">
                <CalendarOutlined />
                {{ $t('components.groupsNavbar.menuItems.schedule') }}
              </router-link>
            </a-menu-item>
            <a-menu-item
              key="devices"
              @click="handleScreensMenuItemClick"
            >
              <router-link
                ref="screensMenuItemRef"
                :to="{name:'DevicesPage'}"
              >
                <WindowsOutlined />
                {{ $t('components.groupsNavbar.menuItems.screens') }}
              </router-link>
            </a-menu-item>
          </template>
        </a-menu>
      </a-layout-content>
    </a-layout>
  </a-layout-header>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { INNER_SIDER_WIDTH } from '@/constants'
import { useStore } from 'vuex'
import { CalendarOutlined, PlayCircleOutlined, WindowsOutlined } from '@ant-design/icons-vue'

const NAV_KEYS = ['playlists', 'schedule', 'devices']
export default defineComponent({
  name: 'GroupsNavbar',
  components: {
    PlayCircleOutlined,
    CalendarOutlined,
    WindowsOutlined
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const groupName = computed(() => store.getters['groups/currentGroupName'])
    const groupId = computed(() => store.getters['groups/currentGroupId'])
    const currentGroupIsLoading = computed(() => store.getters['groups/currentGroupIsLoading'])
    const currentGroupLoaded = computed(() => store.getters['groups/currentGroupLoaded'])
    const loading = computed(()=> !currentGroupLoaded.value || currentGroupIsLoading.value)
    const route = ref(useRoute())
    const defaultTab = getSelectedKeysFromRoute(route?.value?.matched)
    const screensMenuItemRef = ref(null)
    const onboardingService = inject('onboardingService')

    const selectedKeys = ref(defaultTab)

    function getSelectedKeysFromRoute (matched) {
      return NAV_KEYS.filter(key => matched.some(r => r.meta.key === key))
    }

    watch(route.value, ({ matched }) => {
      selectedKeys.value = getSelectedKeysFromRoute(matched)
    })

    watch(()=> screensMenuItemRef.value, (value) => {
      value && onboardingService.setRef(value?.$parent?.$el, 'screensMenu', () => router.push({name: 'DevicesPage'}))
    })

    watch(() =>groupId.value, () => {
      onboardingService.setLoadable('playlistZone', true)
      onboardingService.setLoadable('worldClock', true)
      onboardingService.setLoadable('weatherForecast', true)
    })

    const handleScreensMenuItemClick = () => {
      onboardingService.next()
    }

    return {
      screensMenuItemRef,
      selectedKeys,
      groupName,
      groupId,
      loading,
      INNER_SIDER_WIDTH,
      handleScreensMenuItemClick
    }
  }
})
</script>

<style lang="less" scoped>
.top-navbar {
  .anticon {
    margin-right: 8px;
  }
  .top-navbar-title {
    :deep(.ant-spin-container) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

</style>
