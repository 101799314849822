<script setup>
import { AppstoreOutlined, FontSizeOutlined, FileImageOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
const store = useStore()

const selectTab = (tab) => {
  store.dispatch('customTemplate/setActiveTab', tab)
}

const addText = () => {
  store.dispatch('customTemplate/addTextToCanvas')
}

</script>

<template>
  <div class="control-buttons">
    <a-button @click="selectTab('templates')">
      <template #icon>
        <AppstoreOutlined />
      </template>
    </a-button>
    <a-button @click="addText">
      <template #icon>
        <FontSizeOutlined />
      </template>
    </a-button>
    <a-button @click="selectTab('images')">
      <template #icon>
        <FileImageOutlined />
      </template>
    </a-button>
  </div>
</template>

<style scoped lang="less">
.control-buttons {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

</style>
