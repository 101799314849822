<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'

const store = useStore()
const notificationsEnabled = computed(() => store.getters['workspace/workspaceNotificationSettings'])
const loading = ref(false)

const toggleNotifications = async (enabled) => {
  loading.value = true
  store.dispatch('workspace/updateWorkspaceNotificationSettings', {
    notificationSettings: {
      deviceStatusChanges: {
        enabled
      }
    }
  }).then(()=>{
    success()
  }).catch((e) => {
    error(e.message)
  }).finally(() => {
    loading.value = false
  })
}

</script>

<template>
  <div>
    <a-typography-title :level="5">
      {{ $t('components.notificationsSettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-form layout="vertical">
      <a-form-item>
        <a-switch
          :checked="notificationsEnabled"
          :disabled="loading"
          @change="toggleNotifications"
        />
        <a-typography-text style="margin-left: 16px;">
          {{ $t('components.notificationsSettings.notifyMe') }}
        </a-typography-text>
      </a-form-item>
    </a-form>
    <a-divider />
    <a-typography-text type="secondary">
      {{ $t('components.notificationsSettings.notificationsDisclaimer') }}
    </a-typography-text>
  </div>
</template>

<style scoped>

</style>
