import {
  GET_AVAILABLE_CALENDARS_BY_GOOGLE_SOCIAL_ACCOUNT_ID,
  GET_AVAILABLE_CALENDARS_BY_MICROSOFT_SOCIAL_ACCOUNT_ID,
  GET_GOOGLE_ACCESS_TOKEN,
  LIST_SOCIAL_ACCOUNTS
} from '@/graphql/queries'
import {
  LINK_SOCIAL_ACCOUNT_CANVA,
  LINK_SOCIAL_ACCOUNT_DROPBOX,
  LINK_SOCIAL_ACCOUNT_GOOGLE,
  LINK_SOCIAL_ACCOUNT_INSTAGRAM,
  LINK_SOCIAL_ACCOUNT_MICROSOFT,
  LINK_SOCIAL_ACCOUNT_TWITTER,
  UNLINK_SOCIAL_ACCOUNT
} from '@/graphql/mutations'
import { handleActionError } from '@/helpers/ErrorHandler'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    socialAccounts: [],
    loading: {
      socialAccounts: true
    },
    socialAccountsLoading: true
  },
  actions: {
    async getSocialAccounts ({ commit }) {
      try {
        const { listSocialAccounts } = await apolloCall({
          commit,
          query: LIST_SOCIAL_ACCOUNTS,
          key: 'socialAccounts'
        })
        commit('SET_SOCIAL_ACCOUNTS', listSocialAccounts)
      } catch (e) {
        handleActionError(e)
      }
    },
    async linkSocialAccountCanva ({ getters, commit }, { token, nonce }) {
      await apolloCall({
        mutation: LINK_SOCIAL_ACCOUNT_CANVA,
        variables: { input: { token }, nonce }
      })
    },
    async linkTwitterAccount ({ getters, commit }, payload) {
      const { linkSocialAccountTwitter } = await apolloCall({
        mutation: LINK_SOCIAL_ACCOUNT_TWITTER,
        variables: { input: payload }
      })
      commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountTwitter])
    },
    async linkInstagramAccount ({ getters, commit }, payload) {
      const { linkSocialAccountsInstagram } = await apolloCall({
        mutation: LINK_SOCIAL_ACCOUNT_INSTAGRAM,
        variables: { input: payload }
      })
      commit('ADD_SOCIAL_ACCOUNT', linkSocialAccountsInstagram)
    },
    async linkGoogleAccount ({ getters, commit }, payload) {
      const { linkSocialAccountGoogle } = await apolloCall({
        mutation: LINK_SOCIAL_ACCOUNT_GOOGLE,
        variables: { input: payload }
      })
      commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountGoogle])
    },
    async getGoogleAccountAccessToken ({ getters, commit }, socialAccountId) {
      try {
        const { getSocialAccountGoogleAccessToken: accessToken } = await apolloCall({
          query: GET_GOOGLE_ACCESS_TOKEN,
          variables: { socialAccountId }
        })
        return accessToken
      } catch (e) {
        handleActionError(e)
      }
    },
    async linkMicrosoftAccount ({ getters, commit }, payload) {
      const { linkSocialAccountMicrosoft } = await apolloCall({
        mutation: LINK_SOCIAL_ACCOUNT_MICROSOFT,
        variables: { input: payload }
      })
      commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountMicrosoft])
    },
    async linkDropboxAccount ({ getters, commit }, payload) {
      const { linkSocialAccountDropbox } = await apolloCall({
        mutation: LINK_SOCIAL_ACCOUNT_DROPBOX,
        variables: { input: payload }
      })
      commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountDropbox])
    },
    async unlinkSocialAccount ({ getters, commit }, payload) {
      const { id } = payload
      return await apolloCall({
        mutation: UNLINK_SOCIAL_ACCOUNT,
        variables: { ...payload }
      }).then(() => {
        commit('REMOVE_SOCIAL_ACCOUNT', id)
      })
    },
    async getAvailableGoogleCalendarBySocialAccountId (ctx, googleSocialAccountId) {
      return await apolloCall({
        query: GET_AVAILABLE_CALENDARS_BY_GOOGLE_SOCIAL_ACCOUNT_ID,
        variables: { googleSocialAccountId },
        errorContext: 'getAvailableGoogleCalendarBySocialAccountId'
      }).then(({getAvailableCalendarsByGoogleSocialAccountId}) => getAvailableCalendarsByGoogleSocialAccountId)
    },
    async getAvailableMicrosoftCalendarBySocialAccountId (ctx, microsoftSocialAccountId) {
      return await apolloCall({
        query: GET_AVAILABLE_CALENDARS_BY_MICROSOFT_SOCIAL_ACCOUNT_ID,
        variables: { microsoftSocialAccountId },
        errorContext: 'getAvailableMicrosoftCalendarBySocialAccountId'
      }).then(({getAvailableCalendarsByMicrosoftSocialAccountId}) => getAvailableCalendarsByMicrosoftSocialAccountId)
    }
  },
  getters: {
    socialAccounts: state => state.socialAccounts,
    socialAccountsLoading: state => state.loading.socialAccounts,
    socialTwitterAccounts: state => state.socialAccounts.filter(sa => sa.type === 'TWITTER'),
    socialGoogleAccounts: state => state.socialAccounts.filter(sa => sa.type === 'GOOGLE'),
    socialInstagramAccounts: state => state.socialAccounts.filter(sa => sa.type === 'INSTAGRAM'),
    socialMicrosoftAccounts: state => state.socialAccounts.filter(sa => sa.type === 'MICROSOFT'),
    socialDropboxAccounts: state => state.socialAccounts.filter(sa => sa.type === 'DROPBOX')
  },
  mutations: {
    SET_SOCIAL_ACCOUNTS (state, socialAccounts) {
      state.socialAccounts = socialAccounts
      state.loading.socialAccounts = false
    },
    SET_LOADING_STATUS (state, { status, key }) {
      state.loading[key] = status
    },
    ADD_SOCIAL_ACCOUNT (state, socialAccounts) {
      if (state.socialAccounts) {
        for (const sa of socialAccounts) {
          const existing = state.socialAccounts.find(s => s.id === sa.id)
          if (existing) {
            Object.assign(existing, sa)
          }
          else {
            state.socialAccounts.push(sa)
          }
        }
      }
    },
    REMOVE_SOCIAL_ACCOUNT (state, socialAccountId) {
      state.socialAccounts = [...state.socialAccounts.filter(sa => socialAccountId !== sa.id)]
    }
  }
}
