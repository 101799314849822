<script setup>
import VerifyEmailHeader from '@/components/conditionalHeaders/components/VerifyEmailHeader.vue'
import UpdatePaymentMethodHeader from '@/components/conditionalHeaders/components/UpdatePaymentMethodHeader.vue'
import RenewSubscriptionHeader from '@/components/conditionalHeaders/components/RenewSubscriptionHeader.vue'
import WorkspaceFrozenNonAdminHeader from '@/components/conditionalHeaders/components/WorkspaceFrozenNonAdminHeader.vue'
import TrialHeader from '@/components/conditionalHeaders/components/TrialHeader.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import SubscriptionPausedHeader from '@/components/conditionalHeaders/components/SubscriptionPausedHeader.vue'
import InactiveSubscriptionHeader from '@/components/conditionalHeaders/components/InactiveSubscriptionHeader.vue'
import WorkspaceFrozenAdminHeader from '@/components/conditionalHeaders/components/WorkspaceFrozenAdminHeader.vue'
import CardExpirationHeader from '@/components/CardExpirationHeader.vue'
import WorkspaceFrozenTooManyDevices from '@/components/conditionalHeaders/components/WorkspaceFrozenTooManyDevices.vue'
import FirstPurchaseHeader from '@/components/conditionalHeaders/components/FirstPurchaseHeader.vue'
import moment from 'moment/moment'

const store = useStore()

const emailVerified = computed(() => store.getters['user/emailVerified'])
const isTrialTier = computed(() => store.getters['workspace/billing/isTrialTier'])
const isEducationStarterTier = computed(() => store.getters['workspace/billing/isEducationStarterTier'])
const isPastDue = computed(() => store.getters['workspace/billing/isPastDue'])
const isUnpaid = computed(() => store.getters['workspace/billing/isUnpaid'])
const isInactive = computed(() => store.getters['workspace/billing/isInactive'])
const isPaused = computed(() => store.getters['workspace/billing/isPaused'])
const isOwnerOrAdmin = computed(() => store.getters['workspace/role/isOwner'] || store.getters['workspace/role/isAdmin'])
const isNotOwnerOrAdmin = computed(()=>!isOwnerOrAdmin.value)
const isManuallyFrozen = computed(() => store.getters['workspace/isManuallyFrozen'])
const isFrozenTooManyDevices = computed(() => store.getters['workspace/isFrozenTooManyDevices'])
const isFrozen = computed(() => store.getters['workspace/isFrozen'])
const isEnterprize = computed(() => store.getters['workspace/billing/isEnterprize'])
const daysToCardExpiration = computed(() => store.getters['subscription/daysToCardExpiration'])

const showFirstPurchaseHeader = computed(() => {
  return (isTrialTier.value || isEducationStarterTier.value)
      && isDateBetween9And31December2024()
})

function isDateBetween9And31December2024() {
  const now = moment()
  const start = moment('2024-12-09').startOf('day')
  const end = moment('2024-12-31').endOf('day')

  return now.isBetween(start, end, null, '[]')
}

</script>

<template>
  <VerifyEmailHeader v-if="!emailVerified" />
  <WorkspaceFrozenNonAdminHeader v-else-if="isFrozen && isNotOwnerOrAdmin" />
  <template v-else-if="isOwnerOrAdmin && !isEnterprize">
    <FirstPurchaseHeader v-if="showFirstPurchaseHeader" />
    <WorkspaceFrozenAdminHeader v-if="isManuallyFrozen" />
    <WorkspaceFrozenTooManyDevices v-else-if="isFrozenTooManyDevices" />
    <TrialHeader v-else-if="isTrialTier" />
    <SubscriptionPausedHeader v-else-if="isPaused" />
    <InactiveSubscriptionHeader v-else-if="isInactive" />
    <UpdatePaymentMethodHeader v-else-if="isPastDue" />
    <RenewSubscriptionHeader v-else-if="isUnpaid" />
    <WorkspaceFrozenAdminHeader v-else-if="isFrozen" />
    <CardExpirationHeader v-else-if="daysToCardExpiration !== null && daysToCardExpiration <= 30" />
  </template>
  <template v-else-if="isOwnerOrAdmin && isEnterprize">
    <WorkspaceFrozenAdminHeader v-if="isFrozen" />
  </template>
</template>

<style scoped lang="less">

</style>
