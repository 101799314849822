<script setup>
import { computed, nextTick, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import SerifOutlined from '@/components/icons/SerifOutlined.vue'
import SansSerifOutlined from '@/components/icons/SansSerifOutlined.vue'
import DisplayOutlined from '@/components/icons/DisplayOutlined.vue'
import MonospaceOutlined from '@/components/icons/MonospaceOutlined.vue'

const store = useStore()
const fontsList = computed(() => store.getters['customTemplate/fonts/fonts'] || [])
const fontsLoading = computed(() => store.getters['customTemplate/fonts/fontsLoading'])

const category = ref('serif')
const search = ref('')
const fontsPreview = ref(null)

const activeObject = computed(() => store.getters['customTemplate/activeObject'])
const currentFont = computed(() => activeObject.value?.fontFamily)
const fontsByCategory = computed(() => fontsList.value?.filter(font => font.category === category.value))
const fontsBySearch = computed(() => fontsByCategory.value?.filter(font => font.family.toLowerCase().includes(search.value.toLowerCase())))
const fontsByPage = computed(() => {
  const start = (pagination.value.current - 1) * pagination.value.pageSize
  const end = start + pagination.value.pageSize
  return fontsBySearch.value?.slice(start, end)
})

const handlePageChange = async (page) => {
  pagination.value.current = page
  await nextTick()
  await loadCurrentPageFonts()
  fontsPreview.value?.scrollTo(0, 0)
}

const loadCurrentPageFonts = async () => {
  await store.dispatch('customTemplate/fonts/loadFonts', fontsByPage.value?.map(font => font.family))
}

const pagination = ref({
  current: 1,
  pageSize: 10
})

const CATEGORIES = [
  {
    label: 'Serif',
    value: 'serif',
    icon: SerifOutlined
  },
  {
    label: 'Sans-serif',
    value: 'sans-serif',
    icon: SansSerifOutlined
  },
  {
    label: 'Display',
    value: 'display',
    icon: DisplayOutlined
  },
  {
    label: 'Monospace',
    value: 'monospace',
    icon: MonospaceOutlined
  }
]

onBeforeMount(()=>{
  store.dispatch('customTemplate/fonts/fetchFontsList')
})

const changeCategory = (cat) => {
  category.value = cat
  handlePageChange(1)
}

const changeFont = (fontFamily) => {
  if (!fontFamily) return
  store.dispatch('customTemplate/loadAndSetFont', fontFamily)
}

handlePageChange(1)

</script>

<template>
  <a-typography-text>Current: {{ currentFont }}</a-typography-text>
  <div class="category-panel">
    <a-button
      v-for="cat in CATEGORIES"
      :key="cat.value"
      :type="cat.value === category ? 'primary' : 'default'"
      size="large"
      @click="changeCategory(cat.value)"
    >
      <template #icon>
        <component :is="cat.icon">
          {{ cat.label }}
        </component>
      </template>
    </a-button>
  </div>
  <a-typography-text style="text-align: center;">
    <small>{{ category }} - {{ fontsBySearch.length }} fonts</small>
  </a-typography-text>
  <a-input
    v-model:value="search"
    placeholder="Filter"
    @change="() => handlePageChange(1)"
    @keydown.delete.backspace.stop
  />
  <div
    ref="fontsPreview"
    class="fonts-preview"
  >
    <a-spin :spinning="fontsLoading">
      <a-list>
        <a-list-item
          v-for="font in fontsByPage"
          :key="font.family"
          :class="['font-item', {active: font.family === currentFont}]"
          @click="changeFont(font.family)"
        >
          <div style="display: flex; flex-direction: column">
            <a-typography-text><small>{{ font.family }}</small></a-typography-text>
            <a-typography-text :style="{fontFamily: font.family}">
              The big brown fox
            </a-typography-text>
          </div>
        </a-list-item>
      </a-list>
    </a-spin>
  </div>
  <div class="fonts-pagination">
    <a-pagination
      simple
      :current="pagination.current"
      :total="fontsBySearch.length"
      :page-size="pagination.pageSize"
      @change="handlePageChange"
    />
  </div>
</template>

<style scoped lang="less">
.category-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.fonts-preview {
  flex: 1;
  overflow-x: auto;
  .font-item {
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    &.active {
      background-color: var(--ant-primary-1);
    }
  }
}
.fonts-pagination {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
