<script setup>
import { FolderOutlined, InboxOutlined } from '@ant-design/icons-vue'
import { computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  open: Boolean
})

const emit = defineEmits(['close', 'move'])

const store = useStore()
const { t } = useI18n()

const expandedKeys = ref(['root'])
const folders = ref([])
const foldersLoading = ref(false)

const destinationState = reactive({
  selectedTitle: null,
  selectedTreeKeys: [],
  selectedParentDirectoryMediaId: null
})

const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])

const parentDirectoryMediaId = computed(() => store.getters['media/parentDirectoryMediaId'])
const initFoldersTree = async () => {
  expandedKeys.value = ['root']
  return fetchFolders({
        parentDirectoryMediaId: null
      }
  ).then(listMedia => {
    folders.value = [{
      title: t('components.storageView.rootFolder'),
      key: 'root',
      parentDirectoryMediaId: null,
      selectable: parentDirectoryMediaId.value !== null,
      isLeaf: false,
      isLoaded: true,
      children: getMediaFolderTreeView(listMedia)
    }]
  })
}

const onTreeExpand = (keys) => {
  expandedKeys.value = [...keys]
}

const onSelect = (data, { node }) => {
  if (selectedMediaIds.value?.includes(node.key)) return
  destinationState.selectedTreeKeys = [node.key]
  destinationState.selectedTitle = node.title
  destinationState.selectedParentDirectoryMediaId = node.parentDirectoryMediaId
}

const getMediaFolderTreeView = (listWorkspaceMedia) => {
  return listWorkspaceMedia.map(m => ({
    title: m.name,
    key: m.id,
    parentDirectoryMediaId: m.id,
    selectable: parentDirectoryMediaId.value !== m.id,
    isLeaf: !m.childMedia?.some(m => m.type === 'DIRECTORY')
  }))
}

const onLoadData = (treeNode) => {
  return new Promise(resolve => {
    if (treeNode.dataRef.isLoaded) {
      return resolve()
    }
    const parentDirectoryMediaId = treeNode.dataRef.isGroup ? null : treeNode.dataRef.key
    return fetchFolders({
          parentDirectoryMediaId
        }
    ).then(listMedia => {
      treeNode.dataRef.children = [...treeNode.dataRef.children ?? [], ...getMediaFolderTreeView(listMedia)]
      treeNode.dataRef.isLoaded = true
      folders.value = [...folders.value]
      return resolve()
    })
  })
}

const handleClose = () => {
  resetModal()
  emit('close')
}

const handleMove = () => {
  emit('move', destinationState.selectedParentDirectoryMediaId)
}

const fetchFolders = async () => {
  foldersLoading.value = true
  const result = await store.dispatch('media/fetchMedia', {
    mimetypes: ['DIRECTORY']
  })
  foldersLoading.value = false

  return result
}

const resetModal = () => {
  destinationState.selectedTreeKeys = []
  destinationState.selectedTitle = null
  destinationState.selectedParentDirectoryMediaId = null
}

watch(() => props.open, (open) => {
  open && initFoldersTree()
  !open && resetModal()
})

</script>

<template>
  <a-modal
    :open="open"
    width="572px"
    centered
    :closable="true"
    destroy-on-close
    :title="$t('components.storageView.moveFileModalTitle', { count: selectedMediaIds?.length, to: destinationState.selectedTitle }, selectedMediaIds?.length)"
    wrap-class-name="full-height-modal"
    @close="handleClose"
    @cancel="handleClose"
  >
    <template #footer>
      <a-button
        type="primary"
        :disabled="destinationState.selectedTreeKeys.length === 0"
        @click="handleMove"
      >
        {{ $t('components.storageView.moveButtonText') }}
      </a-button>
    </template>
    <a-spin :spinning="foldersLoading">
      <div style="min-height: 300px;">
        <a-tree
          v-if="open"
          :selected-keys="destinationState.selectedTreeKeys"
          :expanded-keys="expandedKeys"
          :load-data="onLoadData"
          :tree-data="folders"
          show-icon
          @expand="onTreeExpand"
          @select="onSelect"
        >
          <template #icon="{ isGroup }">
            <InboxOutlined v-if="isGroup" />
            <FolderOutlined v-else />
          </template>
        </a-tree>
      </div>
    </a-spin>
  </a-modal>
</template>

<style scoped lang="less">

</style>
