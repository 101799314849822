<script setup>
import EditorHeader from './components/EditorHeader.vue'
import EditorCanvas from './components/EditorCanvas.vue'
import EditorSider from './components/EditorSider.vue'
import ZoomSlider from '@/components/slideModal/CustomTemplateEditor/components/ZoomSlider.vue'
import ExportModal from '@/components/slideModal/CustomTemplateEditor/components/ExportModal.vue'
import { computed, inject, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()
const isActiveObject = computed(() => store.getters['customTemplate/isActiveObject'])
const { uploadBase64Image } = inject('mediaService')

const emit = defineEmits(['cancel', 'save'])

const showExportModal = ref(false)

onBeforeMount(() => {
  window.addEventListener('keydown', handleKeyDown)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown)
})

const handleKeyDown = (e) => {
  store.dispatch(`customTemplate/${isActiveObject.value ? 'handleKeydownOnActiveObject' : 'handleKeydownOnCanvas'}`, e)
}

const handleFileExported = async (fileId) => {
  await store.dispatch('customTemplate/setFileExportLoading', false)
  if (!fileId) return
  Modal.success({
    title: t('components.customTemplateEditor.exportSuccessModalTitle'),
    content: t('components.customTemplateEditor.exportSuccessModalContent'),
  })
}

const handleSave = async () => {
  const saveObj = await store.dispatch('customTemplate/getCanvasImageAndJson')
  await store.dispatch('customTemplate/disposeCanvas')
  emit('save', saveObj)
}

const handleCancel = () => {
  store.dispatch('customTemplate/disposeCanvas')
  emit('cancel')
}

const handleExport = async () => {
  showExportModal.value = true
}

const onExportModalOk = async (fileName) => {
  const image = await store.dispatch('customTemplate/getCanvasImage')
  await store.dispatch('customTemplate/setFileExportLoading', true)
  uploadBase64Image(image, fileName, 'image/png').then(handleFileExported)
}

</script>

<template>
  <a-layout id="canvas-editor">
    <ExportModal
      v-model:visible="showExportModal"
      @export="onExportModalOk"
    />
    <EditorSider />
    <a-layout>
      <EditorHeader
        @cancel="handleCancel"
        @save="handleSave"
        @export="handleExport"
      />
      <EditorCanvas />
    </a-layout>
    <ZoomSlider />
  </a-layout>
</template>

<style scoped lang="less">

</style>
