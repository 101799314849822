<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed, ref, watchEffect } from 'vue'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const TIME_FORMAT = 'h:mma'
const DATE_FORMAT = 'MMM Do'

const store = useStore()
const { t } = useI18n()

const formatSchedule = (events, userTimezone = 'UTC') => {
  if (!events || !events.length) return ''

  const daysMap = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const weeklyEvents = {}
  const oneTimeEvents = []

  events.forEach(event => {
    if (event.type === 'REPEATABLE_WEEKLY_WINDOW') {
      const startMs = event.startWeeklyWindowTimeMs
      const endMs = event.endWeeklyWindowTimeMs

      const startDay = Math.floor(startMs / (24 * 60 * 60 * 1000))
      const startTimeMs = startMs % (24 * 60 * 60 * 1000)
      const endTimeMs = endMs % (24 * 60 * 60 * 1000)

      const startTime = moment.utc(startTimeMs).format(TIME_FORMAT).toUpperCase()
      const endTime = moment.utc(endTimeMs).format(TIME_FORMAT).toUpperCase()

      if (!weeklyEvents[startDay]) {
        weeklyEvents[startDay] = []
      }
      weeklyEvents[startDay].push({
        startTime,
        endTime
      })
    } else if (event.type === 'ONETIME_WINDOW') {
      const startDate = dayjs(event.startDate).tz(userTimezone)
      const endDate = dayjs(event.endDate).tz(userTimezone)

      const dateFormatted = startDate.format(DATE_FORMAT)
      const startTime = startDate.format(TIME_FORMAT).toUpperCase()
      const endTime = endDate.format(TIME_FORMAT).toUpperCase()

      oneTimeEvents.push(t('components.scheduleLink.schedule.onetime', {
        date: dateFormatted,
        start: startTime,
        end: endTime
      }))
    }
  })

  const weeklyStrings = Object.entries(weeklyEvents)
      .map(([day, times]) => {
        const timeRanges = times.map(({
          startTime,
          endTime
        }) => `${startTime}-${endTime}`).join(', ')
        return t('components.scheduleLink.schedule.weekly', {
          day: t(`components.scheduleLink.days.${daysMap[day]}`),
          start: timeRanges
        })
      })

  return t('components.scheduleLink.schedule.final', {
    weekly: weeklyStrings.length ? t('components.scheduleLink.schedule.every', { days: weeklyStrings.join(', ') }) : '',
    onetime: oneTimeEvents.length ? (weeklyStrings.length ? ', ' : '') + oneTimeEvents.join(', ') : ''
  })
}

const playlistSchedule = computed(() => store.getters['playlist/playlistSchedule'])
const hasCalendarEvents = computed(() => playlistSchedule.value?.some(event => event.type === 'ONETIME_WINDOW'))
const routeName = computed(() => hasCalendarEvents.value ? 'Calendar' : 'WeekLoop')

const formattedSchedule = ref(formatSchedule(playlistSchedule.value))

watchEffect(() => {
  formattedSchedule.value = formatSchedule(playlistSchedule.value)
})
</script>

<template>
  <div
    v-if="formattedSchedule"
    style="margin-bottom: 24px; width: 60%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
  >
    <router-link :to="{ name: routeName }">
      <a-tooltip
        :title="$t('components.scheduleLink.scheduleTooltip')"
        placement="topLeft"
      >
        <a-typography-link>
          {{ formattedSchedule }}
        </a-typography-link>
      </a-tooltip>
    </router-link>
  </div>
</template>
