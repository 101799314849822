<script setup>
import { computed, ref } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import MediaSelectModal from '@/components/MediaSelectModal'
import { VideoSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: VideoSlide,
  groupId: String,
  zoning: Boolean,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close', 'save-multiple'])
const { isNewSlide, slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, VideoSlide, {setMute: props.zoning})

const showMediaSelectModal = ref(isNewSlide)

const asset = ref(slide.value.video)
const duration = ref(slide.value.duration)
const mediaExists = ref(!!asset.value)
const thumbnail = computed(() => asset.value?.thumbnail)
const videoExtension = asset.value?.extension?.toUpperCase()
const videoName = asset.value?.name
const videoDuration = computed(() => asset.value?.metadata?.duration)
const formattedDuration = computed(() => {
  if (!videoDuration.value) return null

  const totalSeconds = Math.floor(+videoDuration.value)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return [hours, minutes, seconds]
      .map(unit => String(unit).padStart(2, '0'))
      .join(':')
})
const selectedMedia = ref(mediaExists.value ? [asset] : [])
const selectedMediaId = computed(() => selectedMedia.value?.[0]?.id)
const defaultDuration = (computed(()=>slide.value.customDuration ? parseInt(slide.value.customDuration) : null))

const onCloseMediaModal = () => {
  if (isNewSlide) {
    emit('close')
  }
}

const onSelectChange = (selection) => {
  if (selection.length === 0) return
  else if (selection.length === 1) {
    selectedMedia.value = selection
    const _duration = selection[0]?.metadata?.duration
    _duration && updateSlideDuration(Math.floor(+_duration))
    updateSlideData({
      mediaVideoReference: selection[0].mediaReference
    })
    !isNewSlide && slide.value.setVideo(selectedMedia.value?.[0])
    duration.value = _duration
    updateSlide()
    toggleDisabled(!selectedMediaId.value)
    emit('save')
  }
  else {
    const slides = [slide.value]
    for (let i = 0; i < selection.length; i++) {
      const newSlide = i === 0 ? slides[0] : new VideoSlide()
      newSlide.updateSlideData({
        mediaVideoReference: selection[i].mediaReference
      })
      const slideDuration = selection[i]?.metadata?.duration
      newSlide.setName(slide.value.name)
      newSlide.setDuration(Math.floor(+slideDuration))
      if (i === 0) continue
      slides.unshift(newSlide)
    }
    emit('save-multiple', slides)
  }
}

const updateSlideDuration = (duration) => {
  slide.value.setDuration(duration)
}

const replaceMedia = () => {
  showMediaSelectModal.value = true
}

</script>

<template>
  <template v-if="mediaExists">
    <DurationSlideStep
      :key="duration"
      :slide="slide"
      :default-duration="defaultDuration"
      @update-slide="updateSlide"
    />
    <SlideStep
      :sub-title="videoName"
      :title="$t('components.slides.video.slideStep1.title')"
      no-border
    >
      <template #extra>
        <a-button @click="replaceMedia">
          <template #icon>
            <SyncOutlined />
          </template>
          {{ $t('components.slides.video.slideStep1.replaceButtonsText') }}
        </a-button>
      </template>
      <div class="video-card">
        <div class="video-thumbnail">
          <img
            v-if="thumbnail"
            :src="thumbnail"
          >
          <SyncOutlined
            v-else
            spin
          />
        </div>
        <div class="video-desc">
          <a-typography-text>
            {{ $t('components.slides.video.slideStep1.extension', { ext: videoExtension?.toUpperCase() }) }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{
              videoDuration ? $t('components.slides.video.slideStep1.duration', { count: formattedDuration }) : $t('components.slides.video.slideStep1.durationUnknown')
            }}
          </a-typography-text>
        </div>
      </div>
    </SlideStep>
  </template>
  <MediaSelectModal
    v-model:visible="showMediaSelectModal"
    :exclude-headings="['actions']"
    :selected-media="selectedMedia"
    :folder-select-disabled="true"
    :types="['VIDEO']"
    select-on-upload
    selection="multiple"
    @close="onCloseMediaModal"
    @select="onSelectChange"
  />
</template>

<style lang="less" scoped>
.video-card {
  margin-bottom: 16px;
  overflow: auto;
  display: flex;

  .video-thumbnail {
    width: 384px;
    height: 216px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 384px;
      height: 216px;
      float: left;
      margin-right: 32px;
      border-radius: 4px;
    }
  }
}

</style>
