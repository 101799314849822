import {
  GET_COMPOSER_JSON,
  GET_OPEN_AI_COMPLETION,
  GET_TED_TALKS_SPEAKERS,
  GET_TED_TALKS_TOPICS,
  GET_YOUTUBE_VIDEO_INFO,
  GOOGLE_PLACE_AUTOCOMPLETE,
  LIST_TASTY_TAGS,
  SEARCH_PEXELS_PHOTOS,
  SEARCH_PEXELS_VIDEOS,
  SEARCH_TED_TALKS,
  SEARCH_TICKERS,
  TRIP_ADVISOR_LOCATION_SEARCH
} from '@/graphql/queries'
import { loadJson } from '@/utils'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    tedTalksSpeakers: [],
    tedTalksTopics: [],
    tastyTags: [],
    draggingSlideFromZone: null,
    defaultCustomTemplates: [],
    defaultCustomTemplatesLoaded: false
  },
  actions: {
    async getOpenAICompletion ({ commit }, prompt) {
      return await apolloCall({
        query: GET_OPEN_AI_COMPLETION,
        variables: { prompt }
      }).then(({ createOpenAICompletion }) => createOpenAICompletion)
    },
    async searchPexelPhotos ({ commit }, query) {
      return await apolloCall({
        query: SEARCH_PEXELS_PHOTOS,
        variables: { query }
      }).then(({ searchPexelsPhotos }) => searchPexelsPhotos)
    },
    async searchPexelVideos ({ commit }, query) {
      return await apolloCall({
        query: SEARCH_PEXELS_VIDEOS,
        variables: { query }
      }).then(({ searchPexelsVideos }) => searchPexelsVideos)
    },
    async getYoutubeVideoInfo ({ commit }, videoId) {
      return await apolloCall({
        query: GET_YOUTUBE_VIDEO_INFO,
        variables: { videoId }
      }).then(({ getYoutubeVideoInfo }) => getYoutubeVideoInfo)
    },
    async searchTickers ({ commit }, query) {
      return await apolloCall({
        query: SEARCH_TICKERS,
        variables: query
      }).then(({ searchTickers }) => searchTickers)
    },
    async getTedTalksTopics ({ commit }) {
      return await apolloCall({
        query: GET_TED_TALKS_TOPICS
      }).then(({ getTedTalksTopics }) => {
        commit('SET_TED_TALKS_TOPICS', getTedTalksTopics)
      })
    },
    async getTedTalksSpeakers ({ commit }) {
      return await apolloCall({
        query: GET_TED_TALKS_SPEAKERS
      }).then(({ getTedTalksSpeakers }) => {
        commit('SET_TED_TALKS_SPEAKERS', getTedTalksSpeakers)
      })
    },

    async searchTedTalks ({ commit }, { speaker, topic }) {
      return await apolloCall({
        query: SEARCH_TED_TALKS,
        variables: { speaker, topic }
      }).then(({ searchTedTalks }) => searchTedTalks)
    },
    async getTastyTags ({ commit }) {
      return await apolloCall({
        query: LIST_TASTY_TAGS
      }).then(({ listTastyTags }) => {
        commit('SET_TASTY_TAGS', listTastyTags)
      })
    },
    async fetchComposerJson ({ commit }, widgetId) {
      return await apolloCall({
        query: GET_COMPOSER_JSON,
        variables: { id: widgetId },
      }).then(async ({ getWidgetById }) => {
        return await loadJson(getWidgetById.jsonMedia.url)
      })
    },
    async fetchDefaultComposerSlides ({ commit }) {
      commit('SET_DEFAULT_CUSTOM_TEMPLATES_LOADED', false)
      try {
        const json = await loadJson(process.env.VUE_APP_CUSTOM_TEMPLATES_JSON)
        commit('SET_DEFAULT_CUSTOM_TEMPLATES', json)
      } catch (e) {
        commit('SET_DEFAULT_CUSTOM_TEMPLATES', [])
      } finally {
        commit('SET_DEFAULT_CUSTOM_TEMPLATES_LOADED', true)
      }
    },
    async getGooglePlaceAutocomplete ({ commit }, { query }) {
      return await apolloCall({
        query: GOOGLE_PLACE_AUTOCOMPLETE,
        variables: { query }
      }).then(({ googlePlaceAutocomplete }) => googlePlaceAutocomplete)
    },
    async searchTripAdvisorLocation ({ commit }, { searchQuery }) {
      return await apolloCall({
        query: TRIP_ADVISOR_LOCATION_SEARCH,
        variables: { searchQuery }
      }).then(({ tripAdvisorLocationSearch }) => tripAdvisorLocationSearch)
    },
    async setDraggingSlideZone ({ commit }, zoneKey) {
      commit('SET_DRAGGING_SLIDE_FROM_ZONE', zoneKey)
    }
  },
  getters: {
    tedTalksSpeakers: state => state.tedTalksSpeakers,
    tedTalksTopics: state => state.tedTalksTopics,
    tastyTags: state => state.tastyTags,
    draggingSlideFromZone: state => state.draggingSlideFromZone,
    defaultCustomTemplates: state => state.defaultCustomTemplates,
    defaultCustomTemplatesLoaded: state => state.defaultCustomTemplatesLoaded
  },
  mutations: {
    SET_DRAGGING_SLIDE_FROM_ZONE (state, zoneKey) {
      state.draggingSlideFromZone = zoneKey
    },
    SET_TASTY_TAGS (state, tastyTags) {
      state.tastyTags = tastyTags
    },
    SET_DEFAULT_CUSTOM_TEMPLATES_LOADED (state, loaded) {
      state.defaultCustomTemplatesLoaded = loaded
    },
    SET_DEFAULT_CUSTOM_TEMPLATES (state, templates) {
      state.defaultCustomTemplates = templates
    },
    SET_TED_TALKS_SPEAKERS (state, tedTalksSpeakers) {
      state.tedTalksSpeakers = tedTalksSpeakers
    },
    SET_TED_TALKS_TOPICS (state, tedTalksTopics) {
      state.tedTalksTopics = tedTalksTopics
    }
  }
}
