<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { isURL } from 'validator'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { DEFAULT_PROTOCOL } from '@/constants'
import {
  extractVideoIdFromYoutubeLink,
  getUrlProtocol,
  ifIsYoutubeLink,
  normalizeUrl,
  youtubeDurationToSeconds
} from '@/utils'
import { useStore } from 'vuex'
import { StreamSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: StreamSlide,
  groupId: String,
  zoning: Boolean,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, StreamSlide, {
  setMute: props.zoning,
  disableOnNewSlide: true
})



const SUPPORTED_PROTOCOLS = ['https', 'rtp', 'rtsp', 'rtmp', 'mms']

const store = useStore()
const { duration:slideDuration } = slide.value
const {
  ccEnabled,
  url,
  duration,
  thumbnailUrl: thumbnail
} = slide.value.slideData
const inputRef = ref(null)
const protocol = ref(getUrlProtocol(url) || DEFAULT_PROTOCOL)
const durationValue = ref(slideDuration)
const streamUrl = ref(normalizeUrl(url))
const closeCaptionEnabled = ref(ccEnabled)
const youtubeVideoDuration = ref(duration)
const thumbnailUrl = ref(thumbnail)

const isYouTubeLink = computed(()=> {
  return ifIsYoutubeLink(protocol.value+streamUrl.value)
})

const showCCEnabled = computed(()=> !!closeCaptionEnabled.value && isYouTubeLink.value)

onMounted(() => {
  inputRef.value && inputRef.value.focus()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}

const normalizeInput = () => {
  const stripUrl = normalizeUrl(streamUrl.value)
  const urlProtocol = getUrlProtocol(streamUrl.value)
  if (urlProtocol) {
    protocol.value = urlProtocol
  }
  if (!isURL(protocol.value + stripUrl, { protocols: SUPPORTED_PROTOCOLS })) {
    toggleDisabled(true)
    return
  }
  toggleDisabled(false)
  streamUrl.value = stripUrl
}

normalizeInput()


const handleDurationChange = (duration) => {
  durationValue.value = duration
  updateSlide()
}

const handleYoutubeVideoDuration = async(url) => {
  if (isYouTubeLink.value) {
    const videoId = extractVideoIdFromYoutubeLink(url)
    if (!videoId) {
      thumbnailUrl.value = null
      youtubeVideoDuration.value = null
      return
    }
    const videoInfo = await getYoutubeVideoInfo(videoId)
    const { duration } = videoInfo?.contentDetails || {}
    youtubeVideoDuration.value = duration ? youtubeDurationToSeconds(duration) : null
    thumbnailUrl.value = videoInfo?.snippet?.thumbnails?.medium?.url
  } else {
    thumbnailUrl.value = null
    youtubeVideoDuration.value = null
  }
}

const handleStreamUrlChange = async () => {
  normalizeInput()
  await handleYoutubeVideoDuration(streamUrl.value)
  updateSlideData({
    url: protocol.value + streamUrl.value,
    duration: youtubeVideoDuration.value,
    thumbnailUrl: thumbnailUrl.value
  })
  slide.value.setDuration(youtubeVideoDuration.value || slideDuration)
  durationValue.value = youtubeVideoDuration.value || slideDuration
  updateSlide()
}

const handleProtocolChange = () => {
  handleStreamUrlChange()
}

const handleCCChange = (e) => {
  onCCEnabledChange(e.target.checked)
}

const onCCEnabledChange = (enabled) => {
  closeCaptionEnabled.value = enabled
  updateSlideData({
    ccEnabled: closeCaptionEnabled.value
  })
  updateSlide()
}

const getYoutubeVideoInfo = (videoId) => {
  return store.dispatch('slides/getYoutubeVideoInfo', videoId)
}

watch(()=> showCCEnabled.value, (show) => {
  updateSlideData({
    ccEnabled: show ? closeCaptionEnabled.value : false
  })
})

const protocolOptions = SUPPORTED_PROTOCOLS.map(p => ({
  label: p + '://',
  value: p + '://'
}))

</script>

<template>
  <DurationSlideStep
    :key="durationValue"
    :slide="slide"
    @update-slide="handleDurationChange"
  />
  <SlideStep
    :sub-title="$t('components.slides.stream.slideStep1.subTitle')"
    :title="$t('components.slides.stream.slideStep1.title')"
  >
    <a-form @submit="onSubmit">
      <button
        style="display: none"
        type="submit"
      />
      <a-input
        ref="inputRef"
        v-model:value="streamUrl"
        :placeholder="$t('components.slides.stream.slideStep1.streamUrlPlaceholder')"
        @change="handleStreamUrlChange"
      >
        <template #addonBefore>
          <a-select
            v-model:value="protocol"
            :options="protocolOptions"
            style="width: 90px"
            @change="handleProtocolChange"
          />
        </template>
      </a-input>
    </a-form>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.stream.slideStep2.title')"
    no-border
  >
    <a-checkbox
      :checked="showCCEnabled"
      :disabled="!isYouTubeLink"
      @change="handleCCChange"
    >
      {{ $t('components.slides.stream.slideStep2.showCC') }}
    </a-checkbox>
  </SlideStep>
</template>

<style lang="less">

</style>
