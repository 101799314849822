<script setup>
</script>

<template>
  <div>
    <a-result
      style="margin-top: 60px;"
      status="success"
      :title="$t('components.authModal.emailValidationSuccessTitle')"
      :sub-title="$t('components.authModal.emailValidationSuccessSubtitle')"
    />
    <router-link
      :to="{name: 'Login'}"
      replace
    >
      <a-button
        type="primary"
        block
        size="large"
      >
        {{ $t('components.authModal.loginButtonText') }}
      </a-button>
    </router-link>
  </div>
</template>

<style lang="less" scoped></style>
