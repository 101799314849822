<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import ImagesList from '@/components/slideModal/CustomTemplateEditor/components/ImagesList.vue'
const store = useStore()

const mediaList = computed(() => store.getters['media/mediaList']
    .filter(m => m.mimetype?.startsWith('IMAGE'))
    .map(m => {
  return {
    key: m.id,
    src: m.url,
    thumbnail: m.generatedMedia?.find(gm => gm.tag === 'preview')?.url
  }}
))

const mediaListLoading = computed(() => store.getters['media/mediaListLoading'])

const handleAddImageToCanvas = ({ src }) => {
  store.dispatch('customTemplate/addImageToCanvas', src)
}

const handleImageUpload = (imageUrl) => {
  handleAddImageToCanvas({ src: imageUrl })
}

onBeforeMount(() => {
  store.dispatch('media/getMediaList')
})

</script>

<template>
  <ImagesList
    show-upload
    :loading="mediaListLoading"
    :list="mediaList"
    @upload="handleImageUpload"
    @click="handleAddImageToCanvas"
  />
</template>

<style scoped lang="less">

</style>
