<script setup>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { FullscreenOutlined } from '@ant-design/icons-vue'

const store = useStore()
const currentZoom = computed(() => store.getters['customTemplate/zoom'])
const changeZoom = (value) => {
  store.dispatch('customTemplate/changeZoom', value)
}

const zoom = ref(currentZoom.value)

watch(currentZoom,(value) => {
  zoom.value = value
})


</script>

<template>
  <div id="zoom-slider">
    <FullscreenOutlined
      style="font-size: 18px"
      class="icon"
    />
    <span>{{ zoom }}%</span>
    <a-slider
      v-model:value="zoom"
      :min="10"
      :max="100"
      :step="1"
      :tip-formatter="null"
      class="slider"
      @change="changeZoom"
    />
  </div>
</template>

<style scoped lang="less">
#zoom-slider {
  position: absolute;
  top: 60px;
  right: 16px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  padding: 10px;
  width: 68px;
  height: 40px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
  transition: width 0.2s linear;
  .slider {
    display: none;
  }
  &:hover {
    width: 250px;
    .icon {
      display: none;
    }
    .slider {
      display: block;
    }

  }
  .ant-slider {
    width: 100%;
  }
}
</style>
