<script setup>
import { onMounted, ref, watch } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { SocialLinkedInSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: SocialLinkedInSlide,
  groupId: String,
  disabled: Boolean,
  zoning: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, SocialLinkedInSlide)

const inputRef = ref(null)
const usernameValue = ref(slide.value.slideData.username)
const showLikesCount = ref(slide.value.slideData.showLikesCount)
const isMuted = props.zoning || slide.value.slideData.muted
slide.value.setMute(isMuted)

onMounted(() => {
  inputRef.value && inputRef.value.focus()
  onChange()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}

const linkedInUsernamePattern = /^[a-zA-Z0-9-]+$/

const validateUsername = () => {
  if (!usernameValue.value || !linkedInUsernamePattern.test(usernameValue.value)) {
    toggleDisabled(true)
    return false
  }

  toggleDisabled(false)
  return true
}

const onChange = () => {
  if (!validateUsername()) return

  updateSlideData({
    username: usernameValue.value?.toLowerCase()
  })
  updateSlide()
  toggleDisabled(false)
}

watch(showLikesCount, (newValue) => {
  updateSlideData({ showLikesCount: newValue })
  updateSlide()
})

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.linkedIn.slideStep1.subTitle')"
    :title="$t('components.slides.linkedIn.slideStep1.title')"
    multiline
  >
    <a-form @submit="onSubmit">
      <a-input
        ref="inputRef"
        v-model:value="usernameValue"
        :placeholder="$t('components.slides.linkedIn.slideStep1.usernamePlaceholder')"
        @change="onChange"
      />
    </a-form>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.linkedIn.slideStep2.title')"
    :sub-title="$t('components.slides.linkedIn.slideStep2.subTitle')"
    multiline
    no-border
  >
    <a-checkbox
      v-model:checked="showLikesCount"
    >
      {{ $t('components.slides.linkedIn.slideStep2.showLikesCount') }}
    </a-checkbox>
  </SlideStep>
</template>

<style lang="less">

</style>
