<script setup>
import { computed, ref, toRaw, watchEffect } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { NEWS_CATEGORIES } from '@/constants'
import { NewsSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const COUNTRIES_LIST = [null, 'AE', 'AF', 'AR', 'ASIA', 'AT', 'AU', 'BA', 'BD', 'BE', 'BO', 'BR', 'CA', 'CH', 'CL', 'CN', 'CO', 'CZ', 'DE', 'DK', 'EC', 'EE', 'EG', 'ES', 'EU', 'FI', 'FR', 'GB', 'GH', 'GR', 'HK', 'HU', 'ID', 'IE', 'IL', 'IN', 'INT', 'IQ', 'IR', 'IT', 'JP', 'KE', 'KH', 'KR', 'KW', 'LB', 'LU', 'MM', 'MX', 'MY', 'NG', 'NK', 'NL', 'NO', 'NP', 'NZ', 'PA', 'PE', 'PH', 'PK', 'PL', 'PS', 'PT', 'PY', 'QA', 'RO', 'RS', 'RU', 'SA', 'SE', 'SG', 'SI', 'TH', 'TR', 'TW', 'US', 'UY', 'VE', 'VN', 'ZW']

const props = defineProps({
  title: String,
  slideObj: Object,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, NewsSlide)

const { t } = useI18n()
const countryCodeValue = ref(slide.value.slideData.countryCode)
const checkedList = ref(slide.value.slideData.topics)

const categoriesOptions = ref([
  {
    label: t('components.slides.news.categories.all'),
    value: 'all',
    indeterminate: false
  },
  ...NEWS_CATEGORIES.map(category => ({
    label: t(`components.slides.news.categories.${category}`),
    value: category
  }))
])

const prevState = ref([])
const topics = computed(() => checkedList.value.filter(l => l !== 'all'))

watchEffect(() => {
  countryCodeValue.value = slide.value.slideData.countryCode
  checkedList.value = slide.value.slideData.topics
})

const onCountryChange = (country) => {
  updateSlideData({ countryCode: country })
  updateSlide()
}

const filterOption = (input, option) => {
  return option.key.toLowerCase().includes(input.toLowerCase())
}

const onTopicsChange = () => {
  const categoriesLength = categoriesOptions.value.length - 1
  const selectAllPressed = !prevState.value.includes('all') && checkedList.value.includes('all')
  const deselectAllPressed = prevState.value.includes('all') && !checkedList.value.includes('all')

  if (selectAllPressed || (categoriesLength === checkedList.value.length && prevState.value.length !== categoriesLength + 1)) {
    checkedList.value = categoriesOptions.value.map(({ value }) => value)
  } else if (deselectAllPressed) {
    checkedList.value = []
  } else {
    checkedList.value = checkedList.value.filter(value => value !== 'all')
  }

  prevState.value = checkedList.value
  categoriesOptions.value[0].indeterminate = !!checkedList.value.length && checkedList.value.length < categoriesLength

  updateSlideData({ topics: toRaw(topics.value) })
  updateSlide()
  toggleDisabled(!topics.value.length)
}

onTopicsChange()
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.news.slideStep1.subTitle')"
    :title="$t('components.slides.news.slideStep1.title')"
  >
    <a-checkbox-group
      v-model:value="checkedList"
      :options="categoriesOptions"
      class="multirow-checkbox-group"
      @change="onTopicsChange"
    />
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.news.slideStep2.subTitle')"
    :title="$t('components.slides.news.slideStep2.title')"
  >
    <a-select
      v-model:value="countryCodeValue"
      style="width: 200px;"
      show-search
      :filter-option="filterOption"
      @change="onCountryChange"
    >
      <a-select-option
        v-for="country in COUNTRIES_LIST"
        :key="$t(`components.slides.news.countries.${country}`)"
        :value="country"
      >
        {{ $t(`components.slides.news.countries.${country}`) }}
      </a-select-option>
    </a-select>
  </SlideStep>
  <a-alert
    :message="$t('components.slides.news.infoMessage')"
    show-icon
    type="info"
  />
</template>

<style lang="less">
.compact-form {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

</style>
