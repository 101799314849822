import { reactive } from 'vue'

export const DEFAULT_ZONE_KEY = 'MAIN'
const FILTER = 'filter'

export const playlistState = reactive({
  activeSlide: null,
  selectedSlideIds: new Set(),
  selectedZoneKey: null,
  openedLayoutZones: [DEFAULT_ZONE_KEY],
  activeSlideType: null,
  activeSlideZoneKey: DEFAULT_ZONE_KEY,
  zoneSlideLists: {
    MAIN: [],
    ZONE_A: [],
    ZONE_B: []
  },
  zoneSlideListsFiltered: {
    MAIN: [],
    ZONE_A: [],
    ZONE_B: []
  },
  filterSlidesState: {
    input: '',
    type: FILTER
  },
  showModals: {
    customTemplate: false,
    aiContentSlide: false,
    smartTemplate: false,
    slideInfo: false,
    editObjectFit: false,
    playlist: false,
    slideExport: false,
    playlistMove: false,
    slide: false,
    playlistExpiration: false,
  },
  duplicatePlaylistOnMove: false
})
