<script setup>
import { BlockOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'

const store = useStore()

const moveForward = () => {
  store.dispatch('customTemplate/moveActiveObjectForwards')
}

const moveBack = () => {
  store.dispatch('customTemplate/moveActiveObjectBackwards')
}
</script>

<template>
  <div id="general-controls">
    <a-tooltip title="Move forward">
      <a-button
        @click="moveForward"
      >
        <template #icon>
          <BlockOutlined />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Move backward">
      <a-button
        @click="moveBack"
      >
        <template #icon>
          <BlockOutlined rotate="90" />
        </template>
      </a-button>
    </a-tooltip>
  </div>
</template>

<style scoped lang="less">
#general-controls {
  display: flex;
  gap: 8px;
}
</style>
