<script setup>
import { useStore } from 'vuex'
import { BlockOutlined, UndoOutlined, RedoOutlined } from '@ant-design/icons-vue'
import { computed } from 'vue'
import TextControls from '@/components/slideModal/CustomTemplateEditor/components/ActionBar/components/TextControls.vue'
import StickerControls
  from '@/components/slideModal/CustomTemplateEditor/components/ActionBar/components/StickerControls.vue'
import GeneralControls
  from '@/components/slideModal/CustomTemplateEditor/components/ActionBar/components/GeneralControls.vue'
const store = useStore()

const isActiveObject = computed(() => store.getters['customTemplate/isActiveObject'])
const activeObject = computed(() => store.getters['customTemplate/activeObject'])
const isActiveObjectText = computed(() => (activeObject.value?.type === 'textbox' || activeObject.value?.type === 'i-text'))
const isActiveObjectSvg= computed(() => (activeObject.value?.type === 'svg' || activeObject.value?.type === 'group'))
const canUndo = computed(() => store.getters['customTemplate/canUndo'])
const canRedo = computed(() => store.getters['customTemplate/canRedo'])
const historyIndex = computed(() => store.getters['customTemplate/historyIndex'])
const history = computed(() => store.getters['customTemplate/history'])

const undo = () => {
    store.dispatch('customTemplate/undo')
}

const redo = () => {
  store.dispatch('customTemplate/redo')
}
</script>

<template>
  <div
    class="action-bar"
  >
    <!--    {{ historyIndex }} / {{ history?.[historyIndex] }}-->
    <div class="action-bar-wrapper">
      <a-tooltip title="Undo">
        <a-button
          :disabled="!canUndo"
          @click="undo"
        >
          <template #icon>
            <UndoOutlined />
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip title="Redo">
        <a-button
          :disabled="!canRedo"
          @click="redo"
        >
          <template #icon>
            <RedoOutlined />
          </template>
        </a-button>
      </a-tooltip>
      <GeneralControls v-if="isActiveObject" />
      <TextControls v-if="isActiveObjectText" />
      <StickerControls v-if="isActiveObjectSvg" />
    </div>
  </div>
</template>

<style scoped lang="less">
.action-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;position: absolute;
  z-index: 100;
  top: 12px;
  pointer-events: none;
  .action-bar-wrapper {
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    gap: 8px;
    padding: 0 8px;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
  }
}
</style>
