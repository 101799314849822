<script setup>
import { computed, ref } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import MediaSelectModal from '@/components/MediaSelectModal'
import { DocumentSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: DocumentSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { isNewSlide, slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, DocumentSlide, {
  disableOnNewSlide: true
})

const showMediaSelectModal = ref(isNewSlide)
const asset = ref(slide.value.document)
const mediaExists = ref(!!asset.value)
const thumbnail = computed(() => asset.value?.thumbnail)
const documentExtension = asset.value?.extension?.toUpperCase()
const documentName = asset.value?.name
const documentPagesCount = computed(() => asset.value?.metadata?.numPages || 'Unknown')
const selectedMedia = ref(mediaExists.value ? [asset] : [])
const selectedMediaId = computed(() => selectedMedia.value?.[0]?.id)

const onCloseMediaModal = () => {
  if (isNewSlide) {
    emit('close')
  }
}

const onSelectChange = (selection) => {
  selectedMedia.value = selection
  updateSlideData({
    mediaDocumentReference: selectedMedia.value?.map(m => m.mediaReference)?.[0]
  })
  !isNewSlide.value && slide.value.setDocument(selectedMedia.value?.[0])
  updateSlide()
  toggleDisabled(!selectedMediaId.value)
  emit('save')
}

const replaceMedia = () => {
  showMediaSelectModal.value = true
}

</script>

<template>
  <template v-if="mediaExists">
    <DurationSlideStep
      :slide="slide"
      show-duration-per-item
      @update-slide="updateSlide"
    />
    <SlideStep
      :sub-title="documentName"
      :title="$t('components.slides.document.slideStep1.title')"
      no-border
    >
      <template #extra>
        <a-button @click="replaceMedia">
          <template #icon>
            <SyncOutlined />
          </template>
          {{ $t('components.slides.document.slideStep1.replaceButtonsText') }}
        </a-button>
      </template>
      <div class="document-card">
        <div class="document-thumbnail">
          <img
            v-if="thumbnail"
            :src="thumbnail"
          >
          <SyncOutlined
            v-else
            spin
          />
        </div>
        <div class="document-desc">
          <a-typography-text>
            {{ $t('components.slides.document.slideStep1.extension', { ext: documentExtension?.toUpperCase() }) }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{
              documentPagesCount ? $t('components.slides.document.slideStep1.pages', { count: documentPagesCount }) : $t('components.slides.document.slideStep1.pagesUnknown')
            }}
          </a-typography-text>
        </div>
      </div>
    </SlideStep>
  </template>
  <MediaSelectModal
    v-model:visible="showMediaSelectModal"
    select-on-upload
    :exclude-headings="['actions']"
    :selected-media="selectedMedia"
    :types="['DOCUMENT']"
    selection="single"
    @close="onCloseMediaModal"
    @select="onSelectChange"
  />
</template>

<style lang="less">
.document-card {
  margin-bottom: 16px;
  overflow: auto;
  display: flex;

  .document-thumbnail {
    width: 384px;
    height: 216px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 384px;
      height: 216px;
      float: left;
      margin-right: 32px;
      border-radius: 4px;
    }
  }
}

</style>
