<script setup>
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { getSocialHashtagsOrMentionsOnChange, SocialTiktokSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const DEFAULT_MAX_DURATION = 10

const props = defineProps({
  title: String,
  slideObj: SocialTiktokSlide,
  groupId: String,
  disabled: Boolean,
  zoning: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, SocialTiktokSlide, {
  setMute: props.zoning,
  disableOnNewSlide: true
})

const { hashtags, mentions, maxTimeMsPerVideo, showLikesCount } = slide.value.slideData
const loading = ref(false)
const state = reactive({
  hashtags: hashtags || [],
  mentions: mentions || [],
  maxTimeMsPerVideo,
  showLikesCount
})
const isValid = computed(() => state.hashtags?.length > 0 || state.mentions?.length > 0)
const maxDurationIsSet = ref(state.maxTimeMsPerVideo !== null)
const maxDurationState = ref(state.maxTimeMsPerVideo ? state.maxTimeMsPerVideo / 1000 : DEFAULT_MAX_DURATION)

const onHashtagsChange = (value) => {
  state.hashtags = getSocialHashtagsOrMentionsOnChange(value)
  updateSlideData({
    hashtags: state.hashtags.length ? state.hashtags : null,
  })
}

const onMentionsChange = (value) => {
  state.mentions = getSocialHashtagsOrMentionsOnChange(value)
  updateSlideData({
    mentions: state.mentions.length ? state.mentions : null,
  })
}

watch(() => state.showLikesCount, () => {
  updateSlideData({
    showLikesCount: state.showLikesCount
  })
  updateSlide()
})

watch(()=> maxDurationIsSet.value, () => {
  updateSlideData({
    maxTimeMsPerVideo: maxDurationIsSet.value ? maxDurationState.value * 1000 : null
  })
  updateSlide()
})

watch(() => maxDurationState.value, () => {
  updateSlideData({
    maxTimeMsPerVideo: maxDurationIsSet.value ? maxDurationState.value * 1000 : null
  })
  updateSlide()
})

watchEffect(() => {
  toggleDisabled(!isValid.value)
})

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.socialTikTok.slideStep1.subTitle')"
    :title="$t('components.slides.socialTikTok.slideStep1.title')"
  >
    <a-select
      :disabled="loading"
      :placeholder="$t('components.slides.socialTikTok.slideStep1.hashtagsInputPlaceholder')"
      :value="state.hashtags"
      mode="tags"
      style="width: 100%;"
      @change="onHashtagsChange"
    />
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.socialTikTok.slideStep2.subTitle')"
    :title="$t('components.slides.socialTikTok.slideStep2.title')"
  >
    <a-select
      :disabled="loading"
      :placeholder="$t('components.slides.socialTikTok.slideStep2.mentionsInputPlaceholder')"
      :value="state.mentions"
      mode="tags"
      style="width: 100%"
      @change="onMentionsChange"
    />
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialTikTok.slideStep3.title')"
  >
    <a-radio-group v-model:value="maxDurationIsSet">
      <div style="margin-bottom: 8px;">
        <a-radio
          :value="false"
        >
          {{ $t('components.slides.socialTikTok.slideStep3.maxDurationNotSet') }}
        </a-radio>
      </div>
      <div>
        <a-radio
          :value="true"
        >
          <div style="display: inline-flex; align-items: center; gap: 8px;">
            {{ $t('components.slides.socialTikTok.slideStep3.maxDuration') }} <a-input-number
              v-model:value="maxDurationState"
              :disabled="!maxDurationIsSet"
              min="5"
              max="180"
              style="width: 60px;"
            /> {{ $t('components.slides.socialTikTok.slideStep3.seconds') }}
          </div>
        </a-radio>
      </div>
    </a-radio-group>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialTikTok.slideStep4.title')"
    :sub-title="$t('components.slides.socialTikTok.slideStep4.subTitle')"
    multiline
    no-border
  >
    <a-checkbox v-model:checked="state.showLikesCount">
      {{ $t('components.slides.socialTikTok.slideStep4.showLikesCount') }}
    </a-checkbox>
  </SlideStep>
</template>

<style scoped>

</style>
