<script setup>
import { onMounted, ref } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { YelpSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: YelpSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, YelpSlide, {disableOnNewSlide: true})

const inputRef = ref(null)
const businessIdOrAlias = ref(slide.value.slideData.businessIdOrAlias)

onMounted(() => {
  inputRef.value && inputRef.value.focus()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}


const handleBusinessIdOrAliasChange = () => {
  if (!businessIdOrAlias.value) {
    toggleDisabled(true)
    return true
  }
  updateSlideData({
    businessIdOrAlias: businessIdOrAlias.value?.toLowerCase()
  })
  updateSlide()
  toggleDisabled(false)
}

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.yelp.slideStep1.subTitle')"
    :title="$t('components.slides.yelp.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-input
        ref="inputRef"
        v-model:value="businessIdOrAlias"
        :placeholder="$t('components.slides.yelp.slideStep1.yelpBusinessIdOrAliasPlaceholder')"
        @change="handleBusinessIdOrAliasChange"
      />
    </a-form>
  </SlideStep>
</template>

<style lang="less">

</style>
