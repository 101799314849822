<script setup>
import InlineSvg from 'vue-inline-svg'

const props = defineProps({
  type: String,
  text: String,
  active: Boolean,
  image: String,
  disabled: Boolean,
  centered: Boolean
})

const emit = defineEmits(['change-type'])

const changeType = () => {
  emit('change-type', props.type)
}
</script>

<template>
  <div
    class="slide-type"
    :class="{ active }"
    :disabled="disabled"
    @click="changeType"
  >
    <figure>
      <div class="icon-container">
        <InlineSvg
          :src="image"
          :fill="active ? 'white' : 'black'"
        />
      </div>
      <figcaption :style="{textAlign: centered ? 'center': 'left'}">
        {{ text }}
      </figcaption>
    </figure>
  </div>
</template>

<style scoped lang="less">
.icon-container {
  svg {

  }
}

</style>
