<script setup>
import { computed, ref } from 'vue'
import MediaTab from '@/components/slideModal/CustomTemplateEditor/components/tabs/ImagesTab/components/MediaTab.vue'
import StickersTab
  from '@/components/slideModal/CustomTemplateEditor/components/tabs/ImagesTab/components/StickersTab.vue'

const TABS_OPTIONS =[
  {
    label: 'Stickers',
    value: 'Stickers'
  },
  {
    label: 'Media',
    value: 'Media'
  }
]
const TAB_OPTIONS_COMPONENTS_MAP = {
  'Media': MediaTab,
  'Stickers': StickersTab,
}
const activeTab = ref(TABS_OPTIONS[0].value)
const component = computed(() => TAB_OPTIONS_COMPONENTS_MAP[activeTab.value])
</script>

<template>
  <a-segmented
    v-model:value="activeTab"
    :options="TABS_OPTIONS"
    block
  />
  <component :is="component" />
</template>

<style scoped lang="less">

</style>
