<script setup>
import { ref } from 'vue'

defineProps({
  visible: Boolean
})

const emit = defineEmits(['export', 'update:visible'])

const name = ref('')

const closeModal = () => {
  name.value = ''
  emit('update:visible', false)
}

const handleExport = () => {
  if (!name.value) return
  emit('export', name.value)
  closeModal()
}
</script>

<template>
  <a-modal
    :open="visible"
    :title="$t('components.customTemplateEditor.exportNameModalTitle')"
    width="400px"
    destroy-on-close
    :ok-button-props="{ disabled: !name }"
    @close="closeModal"
    @cancel="closeModal"
    @ok="handleExport"
  >
    <a-input
      :ref="(el) => el && el.focus()"
      v-model:value="name"
      :placeholder="$t('components.customTemplateEditor.fileNamePlaceholder')"
      @keydown.enter="handleExport"
    />
  </a-modal>
</template>

<style scoped lang="less" />
