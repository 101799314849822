<script setup>
import { ref, watch } from 'vue'
import { CustomSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import CustomTemplateEditor from '@/components/slideModal/CustomTemplateEditor/CustomTemplateEditor.vue'
import { ASPECT_RATIO_MAP } from '@/constants'

const props = defineProps({
  visible: Boolean,
  slideObj: CustomSlide,
  zoningLayoutType: String,
  zoneKey: String
})

const emit = defineEmits(['update', 'update:visible', 'close'])

const store = useStore()
const showEditor = ref(false)
const slide = ref(props.slideObj || new CustomSlide())
const aspectRatio = ref(slide.value?.slideData?.aspectRatio)

const handleUpdate = ({ img, json }) => {
  updateSlide({ img, json })
  emit('update:visible', false)
  emit('close')
}

const handleClose = () => {
  emit('update:visible', false)
  emit('close')
}

const updateSlide = ({img, json, aspectRatio: ar}) => {
  slide.value.setSlideData({
    imageBase64: img,
    json,
    aspectRatio: ar || aspectRatio.value
  })
  emit('update', slide.value?.getDto())
}

watch(() => props.visible, async (visible) => {
  if (visible) {
    slide.value = props.slideObj || new CustomSlide()
    aspectRatio.value = slide.value?.slideData?.aspectRatio || ASPECT_RATIO_MAP[props.zoningLayoutType]?.[props.zoneKey]
    const jsonUrl = slide.value?.jsonMedia?.url
    await store.dispatch('customTemplate/initEditor', {jsonUrl, aspectRatio: aspectRatio.value})
    showEditor.value = true
  }
  else {
    slide.value = null
    aspectRatio.value = null
    showEditor.value = false
  }
})
</script>

<template>
  <a-modal
    :open="visible"
    :closable="false"
    width="100%"
    destroy-on-close
    wrap-class-name="custom-template-modal"
    :footer="null"
    :keyboard="false"
  >
    <CustomTemplateEditor
      v-if="showEditor"
      @cancel="handleClose"
      @save="handleUpdate"
    />
  </a-modal>
</template>

<style lang="less">
@import '@/styles/variables.less';
.custom-template-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }

  .ant-modal-body {
    flex: 1;
    padding: 0;
    display: flex;
    overflow: hidden;

    > .ant-layout {
      flex: 1;

      > .ant-layout-content {
        background-color: #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: center;

        .preview {
          background: @menu-dark-submenu-bg;
          box-shadow: 0px 14px 40px -18px rgba(0, 0, 0, 0.75);
          width: 660px;
          aspect-ratio: 16/9;
          position: relative;
          font-size: 18.9px;
          overflow: hidden;
          border-radius: 8px;

          .text-wrapper {
            top: 0;
            left: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.2em;
            width: 100%;
            height: 100%;
            transition: all .2s ease;

            &:not(.left):not(.right) {
              .text {
                .text-inner {
                  .title {
                    text-align: center;
                  }
                }
              }
            }

            &.left {
              justify-content: flex-start;
            }

            &.right {
              justify-content: flex-end;
            }

            &.top {
              align-items: flex-start;
            }

            &.bottom {
              align-items: flex-end;
            }

            .text {
              font-family: 'Roboto', sans-serif;
              color: #fff;
              height: 100%;
              padding: 1.2em;
              width: 17em;
              transition: all .2s ease;
              position: relative;
              border-radius: 8px;
              overflow: hidden;
              backdrop-filter: blur(10px);

              .text-inner {
                z-index: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .title {
                  color: #fff;
                }

                .description {
                  font-size: 75%;
                  color: rgba(255, 255, 255, 0.7);
                }
              }

              .text-background {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
                border-radius: 8px;
                overflow: hidden;
              }
            }
          }

          img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }

          video {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
          }
        }

        .text-position-select-menu {
          margin-top: 16px;
          background-color: transparent;
          border-bottom: 0;
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-menu-item {
            color: rgba(42, 48, 55, 0.45);
            border-bottom-color: transparent;

            &:hover, &.ant-menu-item-selected {
              color: #2A3037;

              &::after {
                border-bottom-color: #2A3037;
              }
            }
          }
        }
      }

      > .ant-layout-sider {
        > .ant-layout-sider-children {
          display: flex;
          flex-direction: column;
          .ant-menu-light {
            background-color: transparent;
          }
          .background-select-menu {
            margin-top: 8px;
            border: 0;

            .ant-menu-item {
              padding-left: 0;

              &::after {
                left: 0;
              }
            }
          }

          .ant-layout-footer {
            background-color: transparent;
            padding: 16px;
            border-top: 1px solid rgba(0, 0, 0, .06);
          }
        }
      }
    }
  }
}
</style>
