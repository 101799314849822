<script setup>
import { InstagramFilled } from '@ant-design/icons-vue'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { getSocialHashtagsOrMentionsOnChange, SocialInstagramSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import { MAX_SOCIAL_ACCOUNTS_IN_SLIDE } from '@/constants'
import AddSocialAccountButton from '@/components/AddSocialAccountButton'
import { useI18n } from 'vue-i18n'
import singleTypeImage from '@/assets/slide-type-single-active.svg'
import photowallTypeImage from '@/assets/slide-type-photowall-active.svg'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'
import CardSelectList from '@/components/CardSelectList.vue'

const props = defineProps({
  title: String,
  slideObj: SocialInstagramSlide,
  groupId: String,
  disabled: Boolean,
  zoning: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, SocialInstagramSlide, {
  setMute: props.zoning,
  disableOnNewSlide: true
})

const store = useStore()
const { t } = useI18n()
const disableDurationPerItem = ref(false)
const {
  hashtags,
  feedType,
  includeOwnInstagramMedia,
  socialInstagramAccountIds,
  showLikesCount,
  view
} = slide.value.slideData
const state = reactive({
  accounts: [...socialInstagramAccountIds || []],
  hashtags: hashtags.map(h => h.replace('#', '')),
  feedType,
  showLikesCount,
  view,
  includeOwnInstagramMedia
})
const instagramAccounts = computed(() => store.getters['social/socialInstagramAccounts'].map(toStandard))
const isValid = computed(() => (state.hashtags?.length > 0 || state.includeOwnInstagramMedia) && state.accounts?.length > 0)
const viewDataState = reactive([
  {
    text: t('components.slides.socialInstagram.singleImage'),
    view: 'SINGLE',
    image: singleTypeImage,
  },
  {
    text: t('components.slides.socialInstagram.photowall'),
    view: 'PHOTOWALL',
    image: photowallTypeImage
  }
])

const isPhotowall = computed(() => state.view === 'PHOTOWALL')

const onHashtagsChange = (value) => {
  state.hashtags = getSocialHashtagsOrMentionsOnChange(value)
  updateSlideData({
    hashtags: state.hashtags.map(h => `#${h}`),
  })
  updateSlide()
}

const handleSocialAccountsChange = (socialAccounts) => {
  if (socialAccounts.length >= MAX_SOCIAL_ACCOUNTS_IN_SLIDE) {
    state.accounts = state.accounts.slice(0, MAX_SOCIAL_ACCOUNTS_IN_SLIDE)
  }
}

watch(() => state.accounts, (value) => {
  if (!value?.length) {
    return
  }
  const instagramAccountsIds = instagramAccounts.value.filter(a => state.accounts.includes(a.id)).map(a => a.id)
  updateSlideData({
    socialInstagramAccountIds: instagramAccountsIds.length ? instagramAccountsIds : null
  })
  updateSlide()
})

watch(() => state.feedType, (value) => {
  updateSlideData({
    feedType: value
  })
  updateSlide()
})

watch(() => state.includeOwnInstagramMedia, (value) => {
  updateSlideData({
    includeOwnInstagramMedia: value
  })
  updateSlide()
})

watch(() => state.showLikesCount, (value) => {
  updateSlideData({
    showLikesCount: value
  })
  updateSlide()
})

watch(() => state.view, (value) => {
  updateSlideData({
    view: value
  })
  updateSlide()
})

watch(() => instagramAccounts.value, (value, oldValue) => {
  if (value?.length - oldValue?.length === 1) {
    const newSocialAccount = value?.find(({ id }) => !oldValue.some(({ id: oldId }) => oldId === id))
    newSocialAccount && state.accounts?.push(newSocialAccount.id)
  }
})

watchEffect(()=>{
  disableDurationPerItem.value = isPhotowall.value;
})

watchEffect(() => {
  toggleDisabled(!isValid.value)
})

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    :disable-duration-per-item="disableDurationPerItem"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep1.title')"
  >
    <template #sub-title>
      <a-typography-link
        href="https://knowledgebase.kitcast.tv/how-to-connect-your-instagram-account"
        target="_blank"
      >
        {{ $t('components.slides.socialInstagram.slideStep1.subTitleLink') }}
      </a-typography-link>
      <br>
      {{ $t('components.slides.socialInstagram.slideStep1.subTitle') }}
    </template>
    <template #extra>
      <AddSocialAccountButton :socials="['instagram']" />
    </template>
    <a-select
      v-model:value="state.accounts"
      :placeholder="$t('components.slides.socialInstagram.slideStep1.accountSelectPlaceholder')"
      mode="multiple"
      style="width: 100%"
      @change="handleSocialAccountsChange"
    >
      <a-select-opt-group v-if="instagramAccounts.length > 0">
        <template #label>
          <span>
            <InstagramFilled />
            Instagram
          </span>
        </template>
        <a-select-option
          v-for="account in instagramAccounts"
          :key="account.id"
          :value="account.id"
        >
          <div>
            @ {{ account.name }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.socialInstagram.slideStep2.subTitle')"
    :title="$t('components.slides.socialInstagram.slideStep2.title')"
  >
    <a-select
      :placeholder="$t('components.slides.socialInstagram.slideStep2.tagsInputPlaceholder')"
      :value="state.hashtags"
      mode="tags"
      style="width: 100%"
      @change="onHashtagsChange"
    />
  </SlideStep>
  <SlideStep :title="$t('components.slides.socialInstagram.slideStep3.title')">
    <a-radio-group v-model:value="state.feedType">
      <a-radio value="TOP">
        {{ $t('components.slides.socialInstagram.slideStep3.popularFirst') }}
      </a-radio>
      <a-radio value="RECENT">
        {{ $t('components.slides.socialInstagram.slideStep3.recentFirst') }}
      </a-radio>
    </a-radio-group>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep4.title')"
    :sub-title="$t('components.slides.socialInstagram.slideStep4.subTitle')"
    multiline
  >
    <a-checkbox
      v-model:checked="state.includeOwnInstagramMedia"
    >
      {{ $t('components.slides.socialInstagram.slideStep4.includeOwnPosts') }}
    </a-checkbox>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep5.title')"
  >
    <CardSelectList
      v-model:view="state.view"
      :data="viewDataState"
    />
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialInstagram.slideStep6.title')"
    :sub-title="$t('components.slides.socialInstagram.slideStep6.subTitle')"
    multiline
    no-border
  >
    <a-checkbox
      v-model:checked="state.showLikesCount"
      :disabled="isPhotowall"
    >
      {{ $t('components.slides.socialInstagram.slideStep6.showLikesCount') }}
    </a-checkbox>
  </SlideStep>
</template>

<style scoped>

</style>
