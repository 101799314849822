<template>
  <a-list
    class="change-logs-list"
    :loading="playlistChangelogsLoading"
  >
    <a-list-item
      v-for="(logItem, index) in changeLogsList"
      :key="index+'v-'+logItem.version"
    >
      <a-list-item-meta>
        <template #title>
          {{ logItem.userName }}
        </template>
        <template #description>
          {{ logItem.toString($t) }}
        </template>
        <template #avatar>
          <a-avatar
            v-if="logItem.avatar"
            :size="48"
            :src="logItem.avatar"
          />
          <a-avatar
            v-else
            :size="48"
          >
            <template #icon>
              <KitcastNotification />
            </template>
          </a-avatar>
        </template>
      </a-list-item-meta>
      <div class="log-date">
        <a-typography-text>
          {{ logItem.date }}
        </a-typography-text>
      </div>
      <footer
        v-if="playlistVersion !== logItem.version"
        class="list-item-footer"
      >
        <a-button
          size="small"
          type="default"
          @click="publishPlaylistVersion(logItem.version)"
        >
          {{ $t('components.changeLogsList.revertButtonText') }}
        </a-button>
      </footer>
    </a-list-item>
  </a-list>
</template>

<script>
import { computed, defineComponent, watchEffect } from 'vue'
import { useStore } from 'vuex'
import KitcastNotification from '@/components/icons/KitcastNotification'
import { error, formatDate, getUserAvatarPlaceholder, success } from '@/utils'

class ChangeLogItem {
  #eventsCount
  #indicatorEvent
  #type
  #changeLogs
  #author
  constructor (version) {
    const changeLogsArray = version.changeLogs
    this.#indicatorEvent = changeLogsArray[0]
    this.#eventsCount = changeLogsArray.length
    this.#changeLogs = changeLogsArray
    this.#type = this.#indicatorEvent?.type
    this.#author = this.#indicatorEvent?.author
    this.userName = this.#getUserName()
    this.avatar = this.#getAvatar()
    this.date = this.#getDate()
    this.version = version.playlistVersion
  }

  #getAffectedWidgetsString = (t) => {
    switch (this.#type) {
      case 'ZONE_SETTINGS_UPDATED':
      case 'TRANSITION_TYPE_CHANGED':
      case 'MUSIC_TYPE_CHANGED':
      case 'UNKNOWN_CHANGES':
      case 'VERSION_PUBLISHED':
        return;
      default:
        return this.#eventsCount > 1
            ? t('components.changeLogsList.multipleWidgetsChanged', {count: this.#eventsCount})
            : t('components.changeLogsList.oneWidgetChanged', {slideName: this.#indicatorEvent?.data?.slideName})
    }
  }

  #getDate = () => {
    return formatDate(this.#indicatorEvent?.createdAt, 'MMM D, HH:mm')
  }

  #getAvatar = () => {
    return this.#author?.avatarMedia?.generatedMedia.find(m => m.tag === 'avatar64x64')?.url || getUserAvatarPlaceholder(this.#author?.id)
  }

  #getUserName = () => {
    return `${this.#author?.firstName}${this.#author?.lastName && ' ' + this.#author.lastName}`
  }

  #getAction = (t) => {
    const i18nKeyBase = 'components.changeLogsList.'
    let i18nKey
    switch (this.#type) {
      case 'SLIDE_CREATED':
        i18nKey = 'slideCreated'
        break
      case 'SLIDE_UPDATED':
        i18nKey = 'slideUpdated'
        break
      case 'SLIDE_DELETED':
        i18nKey = 'slideDeleted'
        break
      case 'SLIDE_DUPLICATED':
        i18nKey = 'slideDuplicated'
        break
      case 'SLIDE_IMPORTED':
        i18nKey = 'slideImported'
        break
      case 'SLIDE_CHANGED_ORDER':
        i18nKey = 'slideChangedOrder'
        break
      case 'SLIDE_CHANGED_DURATION':
        i18nKey = 'slideChangedDuration'
        break
      case 'SLIDE_MUTED':
        i18nKey = 'slideMuted'
        break
      case 'SLIDE_UNMUTED':
        i18nKey = 'slideUnmuted'
        break
      case 'ZONE_SETTINGS_UPDATED':
        i18nKey = 'zoneSettingsUpdated'
        break
      case 'UNKNOWN_CHANGES':
        i18nKey = 'unknownChanges'
        break
      case 'VERSION_PUBLISHED':
        i18nKey = 'versionPublished'
        break
      case 'TRANSITION_TYPE_CHANGED':
        i18nKey = 'transitionTypeChanged'
        break
      case 'MUSIC_TYPE_CHANGED':
        i18nKey = 'musicTypeChanged'
        break
    }
    return t(i18nKeyBase + i18nKey)
  }

  toString = (t) => {
    const affectedString = this.#getAffectedWidgetsString(t)
    return `${this.#getAction(t)} ${affectedString || ''}`
  }

}

export default defineComponent({
  name: 'ChangeLogsList',
  components: {
    KitcastNotification
  },
  setup () {
    const store = useStore()
    const playlistChangelogs = computed(() => store.getters['playlist/playlistChangelogs'])
    const playlistChangelogsLoading = computed(() => store.getters['playlist/playlistChangelogsLoading'])
    const playlistId = computed(() => store.getters['playlist/playlistId'])
    const playlistVersion = computed(() => store.getters['playlist/playlistVersion'])
    const changeLogsList = computed(() => playlistChangelogs.value?.map((version) => new ChangeLogItem(version)))


    const publishPlaylistVersion = (playlistVersion) => {
      store.dispatch('playlist/publishPlaylistVersion', {playlistId: playlistId.value, playlistVersion})
          .then(()=>{
            success()
          })
          .catch(e=>{
        error(e.message)
      })
    }

    watchEffect(()=>{
      store.dispatch('playlist/getPlaylistChangelogs', playlistId.value)
    })

    return {
      playlistVersion,
      changeLogsList,
      playlistChangelogsLoading,
      publishPlaylistVersion
    }
  }
})

</script>

<style scoped lang="less">
.change-logs-list :deep(.ant-list-item) {
  flex-wrap: wrap;
  .list-item-footer {
    margin-top: 12px;
    flex: 0 1 100%;
    text-align: end;
  }
}

</style>
