<script setup>

import ImagesListItem from '@/components/slideModal/CustomTemplateEditor/components/ImagesListItem.vue'
import { ACCEPTED_MIME_TYPES } from '@/constants'
import { CloudUploadOutlined } from '@ant-design/icons-vue'
import { ref } from 'vue'
const emit = defineEmits(['upload'])

const inputRef = ref(null)
const handleClick = () => {
  inputRef.value?.click()
}

const handleImagePick = (e) => {
  const file = e.target.files[0]
  if (!file) {
    return
  }
  if (!ACCEPTED_MIME_TYPES.IMAGE.includes(file.type)) {
    return
  }
  const reader = new FileReader()
  reader.onload = () => {
    emit('upload', reader.result)
  }
  reader.readAsDataURL(file)
}

</script>

<template>
  <ImagesListItem
    thumbnail="/images/upload.png"
    :icon="CloudUploadOutlined"
    :active="false"
    @click="handleClick"
  />
  <input
    ref="inputRef"
    style="display: none;"
    type="file"
    :accept="ACCEPTED_MIME_TYPES.IMAGE"
    @change="handleImagePick"
  >
</template>

<style scoped lang="less">

</style>
