<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import ImagesList from '@/components/slideModal/CustomTemplateEditor/components/ImagesList.vue'

const store = useStore()
const category = ref('')
const stickersByCategory = computed(() => store.getters['customTemplate/stickers'])
const stickersCategoryItems = computed(() => stickersByCategory.value?.[category.value] || [])

const categoryOptions = computed(() => Object.keys(stickersByCategory.value)
  .map(category => ({ label: category.toLowerCase(), value: category })))


watchEffect(() => {
  category.value = categoryOptions.value[0]?.value
})

const handleAddStickerToCanvas = ({ isSvg, src }) => {
  if (isSvg) {
    store.dispatch('customTemplate/addSvgToCanvas', src)
  } else {
    store.dispatch('customTemplate/addImageToCanvas', src)
  }
}

</script>

<template>
  <a-select
    v-model:value="category"
    style="width: 100%;"
    :options="categoryOptions"
  />
  <ImagesList
    :list="stickersCategoryItems"
    @click="handleAddStickerToCanvas"
  />
</template>

<style scoped lang="less">

</style>
