import ApolloClient from '@/apollo'
import {
  GET_DROPBOX_FILE_METADATA,
  GET_DROPBOX_FILES,
  GET_DROPBOX_FILES_CONTINUE, GET_MICROSOFT_FILES,
  LIST_WORKSPACE_MEDIA
} from '@/graphql/queries'
import {
  CREATE_WORKSPACE_DIRECTORY_MEDIA,
  CREATE_WORKSPACE_UPLOADABLE_FILE_MEDIA,
  CREATE_WORKSPACE_URL_FILE_MEDIA,
  DELETE_WORKSPACE_MEDIA_BY_ID,
  DELETE_WORKSPACE_MEDIA_BY_IDS,
  MOVE_WORKSPACE_MEDIA_BY_IDS,
  UPDATE_WORKSPACE_DIRECTORY_MEDIA_BY_ID,
  UPDATE_WORKSPACE_MEDIA_DELETE_OPTIONS,
  UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID
} from '@/graphql/mutations'
import axios from 'axios'
import { apolloCall } from '@/helpers/Graphql'

const POLL_INTERVAL = 5000 // 5 seconds

export default {
  namespaced: true,
  state: {
    mediaListGroupId: null,
    mediaList: [],
    mediaListLoading: false,
    loading: {
      mediaList: false
    },
    mediaListLoaded: false,
    mediaListSubscription: null,
    selectedMedia: [],
    uploadingQueue: {},
    parentDirectoryMediaId: null
  },
  actions: {
    async getMediaList ({ commit, getters, rootGetters }, groupId = null) {
      const parentDirectoryMediaId = rootGetters['media/parentDirectoryMediaId']
      const { listWorkspaceMedia } = await apolloCall({
        commit,
        query: LIST_WORKSPACE_MEDIA,
        variables: { filters: { parentDirectoryMediaId, groupId } },
        key: 'mediaList',
      })
      getters.mediaListGroupId !== groupId && commit('SET_MEDIA_LIST_GROUP_ID', groupId)
      commit('SET_MEDIA_LIST', listWorkspaceMedia)
    },
    async fetchMedia ({ commit, getters }, payload) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      const groupId = getters.mediaListGroupId
      const { listWorkspaceMedia } = await apolloCall({
        commit,
        query: LIST_WORKSPACE_MEDIA,
        variables: { filters: { parentDirectoryMediaId, groupId, ...payload } },
        key: 'mediaList',
      })
      return listWorkspaceMedia
    },
    async subscribeToMediaList ({ commit, getters }) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      const groupId = getters.mediaListGroupId
      const sub = ApolloClient.watchQuery({ query: LIST_WORKSPACE_MEDIA, variables: { filters: { parentDirectoryMediaId, groupId } }, pollInterval: POLL_INTERVAL, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { listWorkspaceMedia }, loading }) => {
          commit('SET_MEDIA_LIST', listWorkspaceMedia)
        })
      commit('SET_MEDIA_LIST_SUBSCRIPTION', sub)
    },
    async fetchDropboxFolder ({ commit }, payload) {
      const { dropboxFilesListFolder } = await apolloCall({
        commit,
        query: GET_DROPBOX_FILES,
        variables: payload,
        key: 'mediaList',
      })
      return dropboxFilesListFolder
    },
    async fetchSharepointFiles ({ commit }, payload) {
      const { microsoftMediaListItems } = await apolloCall({
        commit,
        query: GET_MICROSOFT_FILES,
        variables: payload,
        key: 'mediaList',
      })
      return microsoftMediaListItems
    },
    async getDropboxFileMetadata ({ commit }, { dropboxSocialAccountId, dropboxEntryId }) {
      const { dropboxFilesGetMetadata: { metadata} } = await apolloCall({
        commit,
        query: GET_DROPBOX_FILE_METADATA,
        variables: { dropboxSocialAccountId, dropboxEntryId }
      })
      return metadata
    },
    async continueFetchDropboxFolder ({ commit }, payload) {
      const { dropboxFilesListFolderContinue } = await apolloCall({
        commit,
        query: GET_DROPBOX_FILES_CONTINUE,
        variables: payload,
        key: 'mediaList',
      })
      return dropboxFilesListFolderContinue
    },
    async createMedia ({ commit, getters }, { currentGroup, currentDirectory, payload }) {
      const parentDirectoryMediaId = currentDirectory ? getters.parentDirectoryMediaId : null
      const groupId = currentGroup ? getters.mediaListGroupId : null
      const { createWorkspaceUploadableFileMedia } = await apolloCall({
        mutation: CREATE_WORKSPACE_UPLOADABLE_FILE_MEDIA,
        variables: { input: { parentDirectoryMediaId, groupId, ...payload } },
      })
      commit('ADD_MEDIA', createWorkspaceUploadableFileMedia)
      return createWorkspaceUploadableFileMedia
    },
    async createUrlMedia ({ commit, getters }, payload) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      const groupId = getters.mediaListGroupId
      const { createWorkspaceUrlFileMedia } = await apolloCall({
        mutation: CREATE_WORKSPACE_URL_FILE_MEDIA,
        variables: { input: { parentDirectoryMediaId, groupId, ...payload } },
      })
      commit('ADD_MEDIA', createWorkspaceUrlFileMedia)
      return createWorkspaceUrlFileMedia
    },
    async createDirectory ({ commit, getters }, name) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      const groupId = getters.mediaListGroupId
      const { createWorkspaceDirectoryMedia } = await apolloCall({
        commit,
        mutation: CREATE_WORKSPACE_DIRECTORY_MEDIA,
        variables: { input: { parentDirectoryMediaId, groupId, name } },
        key: 'mediaList'
      })
      commit('ADD_MEDIA', createWorkspaceDirectoryMedia)
      return createWorkspaceDirectoryMedia
    },
    async updateMedia ({ commit }, payload) {
      const { updateWorkspaceUploadableFileMediaById } = await apolloCall({
        commit,
        mutation: UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID,
        variables: payload,
        key: 'mediaList'
      })
      commit('UPDATE_MEDIA', updateWorkspaceUploadableFileMediaById)
    },
    async updateMediaDeleteOptions ({ commit }, { ids, autoDeleteOptions }) {
      await apolloCall({
        commit,
        mutation: UPDATE_WORKSPACE_MEDIA_DELETE_OPTIONS,
        variables: { ids, autoDeleteOptions },
        key: 'mediaList'
      })
      commit('UPDATE_MEDIA_AUTO_DELETE_OPTIONS', { ids, autoDeleteOptions })
    },
    async updateDirectory ({ commit }, payload) {
      const { updateWorkspaceDirectoryMediaById } = await apolloCall({
        commit,
        mutation: UPDATE_WORKSPACE_DIRECTORY_MEDIA_BY_ID,
        variables: payload,
        key: 'mediaList'
      })
      commit('UPDATE_MEDIA', updateWorkspaceDirectoryMediaById)
    },
    async updateUrlMedia ({ commit }, payload) {
      await apolloCall({
        mutation: UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID,
        variables: payload
      })
    },
    async deleteMedia ({ commit }, payload) {
      const { id } = payload
      await apolloCall({
        commit,
        mutation: DELETE_WORKSPACE_MEDIA_BY_ID,
        variables: payload,
        key: 'mediaList',
      })
      commit('REMOVE_MEDIA', id)
    },
    async deleteMediaBulk ({ commit }, payload) {
      const { ids } = payload
      await apolloCall({
        commit,
        mutation: DELETE_WORKSPACE_MEDIA_BY_IDS,
        variables: payload,
        key: 'mediaList'
      })
      commit('REMOVE_MEDIA_BULK', ids)
    },
    async moveMediaBulk ({ commit }, payload) {
      const { ids } = payload
      await apolloCall({
        mutation: MOVE_WORKSPACE_MEDIA_BY_IDS,
        variables: payload
      })
      commit('REMOVE_MEDIA_BULK', ids)
    },
    async uploadFile ({ commit, getters }, { url, file, id }) {
      const queueObj = { name: file.name, progress: 0, id, size: file.size }
      commit('SET_UPLOADING_QUEUE_ITEM', { id, item: queueObj })
      const options = {
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        },
        withCredentials: false,
        onUploadProgress: progressEvent => {
          const currentQueue = { ...getters.uploadingQueue }
          queueObj.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          currentQueue[id] = queueObj
          commit('SET_UPLOADING_QUEUE', currentQueue)
        }
      }
      return axios.put(url, file, options).then(() => {
        setTimeout(() => {
          commit('DELETE_UPLOADING_QUEUE_ITEM', id)
        }, 300)
      })
    },
    async resetModule ({ commit }) {
      commit('CLEAR_MEDIA_DATA')
    },
  },
  getters: {
    uploadingQueue: state => state.uploadingQueue,
    mediaList: state => state.mediaList,
    mediaListGroupId: state => state.mediaListGroupId || null,
    mediaListLoading: state => state.loading.mediaList,
    mediaListLoaded: state => state.mediaListLoaded,
    subscribed: state => !!state.mediaListSubscription,
    selectedMedia: state => state.selectedMedia,
    selectedMediaIds: state => state.selectedMedia.map(m => m.id),
    parentDirectoryMediaId: state => state.parentDirectoryMediaId
  },
  mutations: {
    SET_MEDIA_LIST (state, listMedia) {
      state.mediaList = listMedia
      state.mediaListLoaded = true
    },
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_MEDIA_LIST_GROUP_ID (state, groupId = null) {
      state.mediaListGroupId = groupId
    },
    ADD_MEDIA (state, media) {
      state.mediaList = [...state.mediaList, media]
    },
    UPDATE_MEDIA (state, media) {
      state.mediaList = [...state.mediaList.map(m => m.id === media.id ? media : m)]
    },
    UPDATE_MEDIA_AUTO_DELETE_OPTIONS (state, { ids, autoDeleteOptions }) {
      state.mediaList = [...state.mediaList.map(m => ids.includes(m.id) ? { ...m, autoDeleteOptions } : m)]
    },
    REMOVE_MEDIA (state, mediaId) {
      state.mediaList = [...state.mediaList.filter(m => mediaId !== m.id)]
    },
    REMOVE_MEDIA_BULK (state, mediaIds) {
      state.mediaList = [...state.mediaList.filter(m => !mediaIds.includes(m.id))]
    },
    SET_SELECTED_MEDIA (state, selectedMedia) {
      state.selectedMedia = selectedMedia
    },
    REMOVE_SELECTED_MEDIA_BY_ID (state, selectedMediaId) {
      state.selectedMedia = [...state.selectedMedia.filter(({id})=> id !== selectedMediaId)]
    },
    CLEAR_SELECTED_MEDIA (state) {
      state.selectedMedia = []
    },
    SET_PARENT_DIRECTORY_MEDIA_ID (state, groupId) {
      state.parentDirectoryMediaId = groupId
    },
    SET_MEDIA_LIST_SUBSCRIPTION (state, subscription) {
      state.mediaListSubscription = subscription
    },
    UNSUBSCRIBE_FROM_MEDIA (state) {
      state.mediaListSubscription && state.mediaListSubscription.unsubscribe()
      state.mediaListSubscription = null
    },
    CLEAR_PARENT_DIRECTORY_MEDIA_ID (state) {
      state.parentDirectoryMediaId = null
    },
    SET_UPLOADING_QUEUE (state, queue) {
      state.uploadingQueue = queue
    },
    SET_UPLOADING_QUEUE_ITEM (state, { id, item }) {
      state.uploadingQueue[id] = item
    },
    DELETE_UPLOADING_QUEUE_ITEM (state, id) {
      delete state.uploadingQueue[id]
      state.uploadingQueue = { ...state.uploadingQueue }
    },
    CLEAR_MEDIA_DATA (state) {
      state.mediaList = []
      state.mediaListLoading = false
      state.mediaListSubscription = null
      state.selectedMedia = []
      state.uploadingQueue = {}
      state.parentDirectoryMediaId = null
    }
  }
}
