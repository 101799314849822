<template>
  <icon>
    <template #component>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="2"
            fill="#000000"
          >
            <g
              id="Fonts-style-Thumbnails/2-Hover"
              transform="translate(12.000000, 13.000000)"
            >
              <polygon
                id="M"
                points="25.256 23 22.152 23 22.152 5.336 14.408 23 11.528 23 3.848 5.336 3.848 23 0.744 23 0.744 0.312 5 0.312 13 18.776 21.064 0.312 25.256 0.312"
              />
            </g>
          </g>
        </g>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AlignIcon',
  components: {
    Icon
  }
})
</script>
