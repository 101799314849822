export const migrateFabricJSON = (json) => {
  if (json.version && parseFloat(json.version) >= 6) {
    return json
  }
  if (!json.state || !json.state.objects) {
    return { version: '6.6.1', objects: [] }
  }

  const backgroundObject = json.state.background ? {
    background: json.state.background
  } : {
    backgroundImage: json.state.backgroundImage
  }
  return {
    version: '6.6.1',
    objects: json.state.objects,
    ...backgroundObject,
    width: json.canvasWidth || 1920,
    height: json.canvasHeight || 1080
  }
}
