import canvasManager from '@/components/slideModal/CustomTemplateEditor/canvasManager'
import { Rect } from 'fabric'

const actionTypeToHistoryType = {
  'drag': 'move'
}

const actionTypeRewriteSame = ['move', 'drag']

const setAppropriateTab = (commit, target) => {
  if (target.name === 'text' || target.type === 'textbox' || target.fontSize) {
    commit('SET_ACTIVE_TAB', 'fonts')
  } else if (target.type === 'path-group' || target.type === 'path' || (target.paths && target.paths.length > 0)) {
    commit('SET_ACTIVE_TAB', 'images')
  } else if (target.name === 'image' || target.type === 'image' || target.src) {
    commit('SET_ACTIVE_TAB', 'filters')
  }
}

export const initFabricCanvas = async (commit, dispatch, { jsonUrl, dimensions }) => {

  const canvas = canvasManager.init('canvas')
  canvas.preserveObjectStacking = true

  canvas.on('selection:created', function (e) {
    const target = e.selected[0]
    if (!target) return
    commit('SET_ACTIVE_OBJECT', target)

    setAppropriateTab(commit, target)
  })

  canvas.on('selection:cleared', function () {
    dispatch('setActiveTab', 'background')
    commit('SET_ACTIVE_OBJECT', null)
  })

  canvas.on('selection:updated', (e) => {
    const target = e.selected[0]
    if (!target) return
    commit('SET_ACTIVE_OBJECT', target)

    setAppropriateTab(commit, target)
  })

  canvas.on('object:rotating', function (e) {
    // Snap to 45 degree angle
    const obj = e.target
    if (!obj || !e.e.shiftKey) return

    const snapAngle = 45

    const center = obj.getCenterPoint()
    obj.angle = Math.round(obj.angle / snapAngle) * snapAngle
    obj.setPositionByOrigin(center, 'center', 'center')
    canvas.requestRenderAll()
  })

  canvas.on("object:moving", function (e) {
    const obj = e.target
    if (!obj || !e.e.shiftKey) return

    const snapTolerance = 15
    const objects = canvas.getObjects().filter((o) => o !== obj) // Исключаем сам объект
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height

    let closestX = null
    let closestY = null
    let minDistX = snapTolerance
    let minDistY = snapTolerance

    const objLeft = obj.left
    const objTop = obj.top
    const objRight = obj.left + obj.width * obj.scaleX
    const objBottom = obj.top + obj.height * obj.scaleY
    const objCenterX = obj.left + (obj.width * obj.scaleX) / 2
    const objCenterY = obj.top + (obj.height * obj.scaleY) / 2

    const snapTargetsX = [0, canvasWidth]
    const snapTargetsY = [0, canvasHeight]

    objects.forEach((other) => {
      const otherLeft = other.left
      const otherTop = other.top
      const otherRight = other.left + other.width * other.scaleX
      const otherBottom = other.top + other.height * other.scaleY
      const otherCenterX = other.left + (other.width * other.scaleX) / 2
      const otherCenterY = other.top + (other.height * other.scaleY) / 2

      snapTargetsX.push(otherLeft, otherRight, otherCenterX)
      snapTargetsY.push(otherTop, otherBottom, otherCenterY)

      if (Math.abs(objCenterX - otherCenterX) < snapTolerance) {
        closestX = otherCenterX - obj.width * obj.scaleX / 2
      }
      if (Math.abs(objCenterY - otherCenterY) < snapTolerance) {
        closestY = otherCenterY - obj.height * obj.scaleY / 2
      }
    })

    if (Math.abs(objCenterX - canvasWidth / 2) < snapTolerance) {
      closestX = canvasWidth / 2 - obj.width * obj.scaleX / 2
    }
    if (Math.abs(objCenterY - canvasHeight / 2) < snapTolerance) {
      closestY = canvasHeight / 2 - obj.height * obj.scaleY / 2
    }

    snapTargetsX.forEach((target) => {
      if (Math.abs(objLeft - target) < minDistX) {
        closestX = target
        minDistX = Math.abs(objLeft - target)
      }
      if (Math.abs(objRight - target) < minDistX) {
        closestX = target - obj.width * obj.scaleX
        minDistX = Math.abs(objRight - target)
      }
    })

    snapTargetsY.forEach((target) => {
      if (Math.abs(objTop - target) < minDistY) {
        closestY = target
        minDistY = Math.abs(objTop - target)
      }
      if (Math.abs(objBottom - target) < minDistY) {
        closestY = target - obj.height * obj.scaleY
        minDistY = Math.abs(objBottom - target)
      }
    })

    if (closestX !== null) {
      obj.left = closestX
    }
    if (closestY !== null) {
      obj.top = closestY
    }

    obj.setCoords()
    canvas.requestRenderAll()
  })

  canvas.on("object:modified", function (e) {
    const obj = e.target
    if (!obj) return

    const actionType = e.transform ? e.transform.action : "unknown"

    commit("ADD_TO_HISTORY", {
      actionType: actionTypeToHistoryType[actionType] || actionType,
      rewriteSame: actionTypeRewriteSame.includes(actionType),
    })
  })

  if (jsonUrl) {
    await dispatch('loadJsonFromUrl', jsonUrl)
  }
  else {
    canvas.set({
      background: 'white',
      backgroundColor: 'white'
    })
    canvas.setDimensions(dimensions)
    canvas.renderAll()
    dispatch('autoAdjustZoom')
  }

  canvas.renderAll()
  commit('INITIALIZE_HISTORY')


  return canvas
}
