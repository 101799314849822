import {
  LOGIN_USER,
  LOGIN_USER_GOOGLE_SSO,
  LOGIN_USER_MICROSOFT_SSO,
  LOGIN_USER_OIDC_SSO,
  LOGOUT_USER,
  PASS_TWO_FACTOR_CHECK,
  REGISTER_USER,
  SET_WORKSPACE_ID_FOR_SESSION
} from '@/graphql/mutations'
import {
  CHECK_SESSION_STATUS,
  GET_WORKSPACE_ID_BY_SHORT_ID,
  GET_WORKSPACE_PUBLIC_INFO_BY_WHITE_LABEL
} from '@/graphql/queries'
import ApolloClient, { recreateWsLink } from '@/apollo'
import router from '@/router'
import { DEFAULT_ACCENT_COLOR } from '@/constants'
import { handleError, handleLoginError, TwoFactorAuthError } from '@/helpers/ErrorHandler'
import { i18n } from '@/i18n'
import { apolloCall } from '@/helpers/Graphql'

async function safeDispatch(dispatchFn, ...args){
  try {
    return await dispatchFn(...args);
  } catch (e) {
    console.error(`Error in ${dispatchFn.name}:`, e);
    handleError(e);
  }
}

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('auth-token') || null,
    authStatus: false,
    loading: {
      init: false,
      login: false,
      redirectingToV1: false
    },
    forcedWorkspaceId: null,
    logo: null,
    favicon: null,
    accentColor: null,
    windowBlurTime: null,
    workspaceDomain: null,
    redirectingToV1: false
  },
  actions: {
    async register ({ commit, dispatch }, authDetails) {
      const { registerUser: { accessToken: token } } = await apolloCall({
        mutation: REGISTER_USER,
        variables: authDetails
      })
      dispatch('authorize', token)
    },
    async registerByInvite ({ commit, dispatch }, { inviteToken, input, workspaceId }) {
      const { registerUser: { accessToken: token } } = await apolloCall({
        mutation: REGISTER_USER,
        variables: { input }
      })
      dispatch('authorize', token)
      commit('SET_FORCED_WORKSPACE_ID', workspaceId)
      await dispatch('user/acceptInviteByToken', inviteToken, { root: true })
    },
    async login({ commit, dispatch, getters }, authDetails) {
      const whitelabel = getters.workspaceDomain
      const authPayload = { ...authDetails, ...(whitelabel && { whitelabel }) }
      const response = await apolloCall({
        commit,
        mutation: LOGIN_USER,
        variables: authPayload,
        onError: async (e) => await handleLoginError(e, { dispatch }),
        key: 'login'
      })
      const { loginUser: { accessToken: token, preferableWorkspaceId, user: { id } } } = response
      dispatch('authorize', token)
      dispatch('workspace/setPreferredWorkspace', { workspaceId: preferableWorkspaceId, userId: id }, {root: true})
    },
    async loginToV1 ({ commit, dispatch, getters }, { extensions, e }) {
      const v1AccessToken = extensions.v1AccessToken
      if (!extensions || !extensions?.code === 'USER_EXISTS_IN_V1' || !v1AccessToken) {
        throw e
      }
      commit('SET_LOADING_STATUS', {key: 'redirectingToV1', value: true})
      const dashboardUrl = process.env.VUE_APP_V1_DASHBOARD_URL || 'https://dashboard.kitcast.tv'
      const escapedToken = encodeURIComponent(v1AccessToken)
      location.href = `${dashboardUrl}?v1Token=${escapedToken}`
      throw new Error(i18n.global.t(`errors.codes.REDIRECT_TO_V1`))
    },
    async loginToCanva ({ commit, dispatch, getters }, authDetails) {
      const { loginUser: { accessToken: token } } = await apolloCall({
        commit,
        mutation: LOGIN_USER,
        variables: authDetails,
        key: 'login',
        onError: async (e) => await handleLoginError(e, { dispatch })
      })
      commit('SET_TOKEN_ONCE', token)
    },
    async loginWith2Fa ({ commit, dispatch, getters }, { preAuthToken, twoFactorCode }) {
      const { passTwoFactorCheck: { accessToken: token } } = await apolloCall({
        commit,
        mutation: PASS_TWO_FACTOR_CHECK,
        variables: { preAuthToken, twoFactorCode },
        key: 'login'
      })
      dispatch('authorize', token)
    },
    async checkIfSessionIsActive () {
      await apolloCall({
        query: CHECK_SESSION_STATUS
      })
    },
    async loginWithGoogle ({ commit, dispatch, rootState }, input) {
      input = {
        ...input,
        ...(rootState.whitelabel ? { whitelabel: rootState.whitelabel } : {})
      }
      const { loginUserSsoGoogle: { accessToken: token, isSignUp } } = await apolloCall({
        commit,
        mutation: LOGIN_USER_GOOGLE_SSO,
        variables: input,
        key: 'login',
        onError: async (e) => await handleLoginError(e, { dispatch })
      })
      dispatch('authorize', token)
      return isSignUp
    },
    async loginWithMicrosoft ({ commit, dispatch, rootState }, input) {
      const scopes = (process.env.VUE_APP_MICROSOFT_SCOPES || '').split(',')
      input = {
        ...input,
        ...(rootState.whitelabel ? { whitelabel: rootState.whitelabel } : {}),
        scopes
      }
      const { loginUserSsoMicrosoft: { accessToken: token, isSignUp } } = await apolloCall({
        commit,
        mutation: LOGIN_USER_MICROSOFT_SSO,
        variables: input,
        key: 'login',
        onError: async (e) => await handleLoginError(e, { dispatch })
      })
      dispatch('authorize', token)
      return isSignUp
    },
    async loginWithOIDC ({ commit, dispatch, rootState }, input) {
      const { loginUserSsoOidc: { accessToken: token, isSignUp } } = await apolloCall({
        commit,
        mutation: LOGIN_USER_OIDC_SSO,
        variables: input,
        key: 'login',
        onError: async (e) => await handleLoginError(e, { dispatch })
      })
      dispatch('authorize', token)
      return isSignUp
    },
    async authorize ({ commit, dispatch }, token) {
      commit('SET_TOKEN', token)
    },
    async initUser ({ commit, dispatch, getters, rootGetters }, { skipWorkspaceSessionSet } = {}) {
      commit('SET_LOADING_STATUS', {status: true, key: 'init'})
      try {
      await dispatch('initializeAppConfig')
      await dispatch('initializeUserData', {skipWorkspaceSessionSet})
      await dispatch('workspace/setWorkspace', null, {root: true})
      await dispatch('workspace/handleEDU', null, {root: true})
      await dispatch('initializePaymentMethods')
      await dispatch('initializeGroups')
      } catch (e) {
        console.error('Failed to initialize user data:', e)
        await dispatch('resetUser')
      } finally {
        commit('SET_LOADING_STATUS', {status: false, key: 'init'})
      }
      commit('SET_LOADING_STATUS', {status: false, key: 'init'})
    },
    async initializeAppConfig({ dispatch }) {
      await safeDispatch(() => dispatch('setApplicationPublicConfig', null, { root: true }))
      await dispatch('user/setLanguage', null, { root: true })
      await dispatch('user/initIntercom', null, { root: true })
    },
    async initializeUserData({ commit, dispatch, getters }, {skipWorkspaceSessionSet}) {
      const domain = getters.workspaceDomain
      await Promise.all([
        dispatch('workspace/getAvailableWorkspaces', null, {root: true}),
        dispatch('user/getUserData', null, { root: true })
      ])
      if (!domain && !skipWorkspaceSessionSet) {
        await dispatch('setWorkspaceForSession')
      }
      commit('LOGIN_USER')
    },
    async initializeGroups({ dispatch, rootGetters }) {
      const setEmptySmartGroups = !rootGetters['workspace/features/smartGroupsEnabled'] || null
      await Promise.all([
        dispatch('groups/getAvailableStandardGroups', null, { root: true }),
        dispatch('groups/getAvailableSmartGroups', setEmptySmartGroups, { root: true })
      ])
    },
    async initializePaymentMethods ({ dispatch, rootGetters }) {
      if (rootGetters['workspace/role/isAdmin'] || rootGetters['workspace/role/isOwner']) {
        await dispatch('subscription/getPaymentMethod', null, { root: true })
      }
    },
    async logOut ({ dispatch }) {
      await Promise.all([
        dispatch('resetUser'),
        apolloCall({
          mutation: LOGOUT_USER,
          onError: (e) => console.log(e.message)
        })
      ])
    },
    async resetUser ({ commit, dispatch }) {
      await commit('LOGOUT_USER')
      await router.push({ name: 'Login' })
      await ApolloClient.cache.reset()
      await dispatch('user/clearUser', null, { root: true })
      await dispatch('workspace/resetWorkspace', null, { root: true })
      recreateWsLink()
    },
    async getWhitelabelSettings ({ commit }, whitelabel) {
      const { getWorkspacePublicInfoByWhitelabel } = await apolloCall({
        query: GET_WORKSPACE_PUBLIC_INFO_BY_WHITE_LABEL,
        variables: { whitelabel }
      })
      const settings = {
        logo: getWorkspacePublicInfoByWhitelabel?.whitelabelLogoMedia?.generatedMedia?.[0]?.url,
        favicon: getWorkspacePublicInfoByWhitelabel?.whitelabelFaviconMedia?.generatedMedia?.[0]?.url || getWorkspacePublicInfoByWhitelabel.whitelabelFaviconMedia?.url,
        accentColor: getWorkspacePublicInfoByWhitelabel?.settings?.whitelabelAccentColor || DEFAULT_ACCENT_COLOR
      }
      commit('SET_BASE_SETTINGS', settings)
    },
    async getWorkspaceIdByShortId ({ commit }, shortId) {
      const { getWorkspacePublicInfoByShortId: {id, ssoAvailable} } = await apolloCall({
        query: GET_WORKSPACE_ID_BY_SHORT_ID,
        variables: { shortId }
      })
      return {id, ssoAvailable}
    },
    async setWorkspaceForSession ({dispatch, rootGetters, getters} ) {
      const savedWorkspaceId = await dispatch('workspace/getPreferredWorkspace', null, {root: true})
      const preferableWorkspaceId = getters.forcedWorkspaceId || savedWorkspaceId
      const availableWorkspaces = rootGetters['workspace/workspacesList']
      const workspaceIds = availableWorkspaces?.map(({id}) => id) || []
      let workspaceId
      if (preferableWorkspaceId && workspaceIds.includes(preferableWorkspaceId)) {
        workspaceId = preferableWorkspaceId
      }
      else {
        workspaceId = workspaceIds[0]
      }
      await apolloCall({
        mutation: SET_WORKSPACE_ID_FOR_SESSION,
        variables: { workspaceId },
        onError: (e) => console.log(e.message)
      })
      dispatch('workspace/setPreferredWorkspace', { workspaceId }, {root: true})
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    forcedWorkspaceId: state => state.forcedWorkspaceId,
    loggingIn: state => state.loading?.login,
    initializing: state => state.loading?.init,
    redirectingToV1: state => state.loading?.redirectingToV1,
    token: state => state.token,
    workspaceDomain: state => state.workspaceDomain,
    logo: state => state.logo,
    favicon: state => state.favicon,
    accentColor: state => state.accentColor,
    windowBlurTime: state => state.windowBlurTime
  },
  mutations: {
    SET_TOKEN_ONCE (state, token) {
      state.token = token
    },
    SET_TOKEN (state, token) {
      localStorage.setItem('auth-token', token)
      state.token = token
    },
    SET_FORCED_WORKSPACE_ID (state, workspaceId) {
      state.forcedWorkspaceId = workspaceId
    },
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    LOGIN_USER (state) {
      state.authStatus = true
    },
    SET_ACCENT_COLOR (state, accentColor) {
      if (state.accentColor) {
        state.accentColor = accentColor
      }
    },
    SET_BASE_SETTINGS (state, { logo, favicon, accentColor }) {
      state.logo = logo
      state.favicon = favicon
      state.accentColor = accentColor
    },
    REMOVE_LOGO (state) {
      state.logo = null
    },
    REMOVE_FAVICON (state) {
      state.favicon = null
    },
    SET_WINDOW_BLUR_TIME (state, windowBlurTime) {
      state.windowBlurTime = windowBlurTime
    },
    SET_WORKSPACE_DOMAIN (state, domain) {
      state.workspaceDomain = domain
    },
    LOGOUT_USER (state) {
      state.authStatus = false
      state.token = null
      state.forcedWorkspaceId = null
      state.sessionsList = null
      state.user = null
      localStorage.removeItem('auth-token')
    }
  }
}
