<template>
  <div class="auth-page">
    <div class="auth-page-wrapper">
      <header>
        <img
          :src="require( `@/assets/kitcast-logo.svg`)"
          alt=""
        >
        <a-typography-text
          strong
          style="font-size: 16px;"
        >
          {{ $t('components.authForm.header') }}
        </a-typography-text>
      </header>
      <div
        v-if="initialized"
        class="auth-form"
      >
        <div
          ref="formWrapper"
          class="auth-form-wrapper"
        >
          <div
            ref="formSizer"
            class="div"
          >
            <div class="auth-form-title">
              <a-typography-title
                :level="2"
                style="margin-bottom: 0;"
              >
                {{ $t('components.authForm.title') }}
              </a-typography-title>
              <a-typography-text type="secondary">
                {{ $t('components.authForm.subtitle') }}
              </a-typography-text>
            </div>
            <a-form
              v-if="!show2Fa && !showAuthenticated"
              ref="formRef"
              layout="vertical"
              :model="formState"
              style="margin-top: 32px;"
              :rules="rules"
              @submit.prevent="connectCanva"
            >
              <a-form-item
                :label="$t('components.authForm.emailLabel')"
                name="email"
              >
                <a-input
                  v-model:value="formState.email"
                  placeholder="name@company.com"
                />
              </a-form-item>
              <a-form-item
                :label="$t('components.authForm.passwordLabel')"
                name="password"
              >
                <a-input
                  v-model:value="formState.password"
                  :placeholder="$t('components.authForm.passwordPlaceholder')"
                  type="password"
                />
              </a-form-item>
              <a-form-item style="margin-top: 24px; margin-bottom: 8px;">
                <a-button
                  type="primary"
                  block
                  html-type="submit"
                  size="large"
                  :loading="loading"
                >
                  {{ $t('components.authForm.connectButtonText') }}
                </a-button>
              </a-form-item>
              <div style="text-align: center">
                <a-typography-link
                  :disabled="loading"
                  :href="signUpUrl"
                  target="_blank"
                >
                  {{ $t('components.authForm.noAccount') }}
                </a-typography-link>
              </div>
            </a-form>
            <a-form v-else-if="!show2Fa">
              <a-form-item style="margin-top: 24px; margin-bottom: 8px;">
                <a-button
                  type="primary"
                  block
                  html-type="submit"
                  size="large"
                  :loading="connectingCanva"
                  @click="continueWithCurrentAccount"
                >
                  {{ $t('components.authForm.continueWith', {account: fullName}) }}
                </a-button>
              </a-form-item>
              <div style="text-align: center">
                <a-typography-link
                  :disabled="connectingCanva"
                  @click="handleGoToLogin"
                >
                  {{ $t('components.authForm.useOtherAccount') }}
                </a-typography-link>
              </div>
            </a-form>
            <a-form
              v-else
              ref="2FaFormRef"
              layout="vertical"
              style="margin-top: 32px;"
              @submit.prevent="connectCanvaWith2Fa"
            >
              <a-form-item
                :label="$t('components.authForm.codeLabel')"
                name="code"
              >
                <a-input
                  v-model:value="code2Fa"
                  :placeholder="$t('components.authForm.codePlaceholder')"
                  maxlength="6"
                  type="text"
                />
              </a-form-item>
              <a-form-item style="margin-top: 24px; margin-bottom: 8px;">
                <a-button
                  type="primary"
                  :loading="loading"
                  block
                  html-type="submit"
                  size="large"
                >
                  {{ $t('components.authForm.connectButtonText') }}
                </a-button>
              </a-form-item>
              <div style="text-align: center">
                <a-typography-link
                  :disabled="loading"
                  @click="handleCancel2Fa"
                >
                  {{ $t('components.authForm.useOtherAccount') }}
                </a-typography-link>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { error } from '@/utils'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'OuterAuthPage',
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const fullName = computed(() => store.getters['user/fullName'])
    const formWrapper = ref()
    const formSizer = ref()
    const formRef = ref()
    const showAuthenticated = ref(false)
    const show2Fa = ref(false)
    const code2Fa = ref('')
    const preAuthToken = ref('')
    const loading = computed(() => store.getters['auth/loggingIn'])
    const connectingCanva = ref(false)
    const initialized = ref(false)
    const formState = reactive({
      email: '',
      password: ''
    })
    const rules = computed(()=>{
      return {
        email: [{
          required: true,
          type: 'email',
          trigger: 'blur',
          message: t('components.loginForm.emailInvalidError')
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: t('components.loginForm.passwordInvalidError')
        }]
      }
    })

    const userAuthToken = computed(() => route.query?.canva_user_token)
    const state = computed(() => route.query?.state)
    const nonce = computed(() => route.query?.nonce)

    const signUpUrl = computed(() => {
      const url = new URL(router.resolve({ name: 'Signup' }).href, window.location.origin)
      url.searchParams.set('utm_source', 'canva.com')
      url.searchParams.set('utm_medium', 'referral')
      url.searchParams.set('utm_campaign', 'integration')

      return url.toString()
    })

    onMounted(async () => {
      toggleGlobalStyles(true)
      if (!userAuthToken.value || !state.value) {
        await router.push({name: 'Login'})
        return
      }
      if (isAuthenticated.value && !authStatus.value) {
        await store.dispatch('user/getUserData')
        showAuthenticated.value = true
      } else if (authStatus.value) {
        showAuthenticated.value = true
      }
      initialized.value = true
      handleContainerHeightChange()
    })

    const connectCanva = async () => {
      setTimeout(async () => {
        try {
          await formRef.value.validate()
        } catch (e) {
          return
        }
        const input = toRaw(formState)
        input.email = input.email?.toLowerCase()
        await store.dispatch('auth/loginToCanva', input)
          .then((extensions) => {
            continueWithCurrentAccount()
          }).catch((e) => {
            if(e.message === 'REQUIRES_2FA') {
              preAuthToken.value = e.preAuthToken
              show2Fa.value = true
              return
            }
            error(e.message)
          })
      })
    }

    const connectCanvaWith2Fa = async () => {
      if (code2Fa.value?.length < 6) return
      await store.dispatch('auth/connectCanvaWith2Fa', { preAuthToken: preAuthToken.value, twoFactorCode: code2Fa.value })
          .then(() => {
            continueWithCurrentAccount()
          }).catch((e) => {
            error('Code is incorrect')
          })
    }

    const reset2FaState = () => {
      show2Fa.value = false
      code2Fa.value = ''
      preAuthToken.value = ''
    }

    const handleCancel2Fa = () => {
      reset2FaState()
    }

    const handleContainerHeightChange = () => {
      nextTick(()=>{
        if (!formWrapper.value) return
        formWrapper.value.style.height = formSizer.value?.scrollHeight + 'px'
      })
    }

    const handleGoToLogin = () => {
      showAuthenticated.value = false
    }

    const continueWithCurrentAccount = () => {
      connectingCanva.value = true
      store.dispatch('social/linkSocialAccountCanva',{token: userAuthToken.value, nonce: nonce.value})
        .then(() => {
          redirectToCanva()
        }).catch((e) => {
          error(e.message)
        }).finally(() => {
          connectingCanva.value = false
        })
    }

    const redirectToCanva = () => {
      window.location.href = 'https://www.canva.com/apps/configured?success=true&state=' + state.value
    }

    const toggleGlobalStyles = (add) => {
      const body = document.body
      if (add) {
        body.classList.add('auth-page-active')
      } else {
        body.classList.remove('auth-page-active')
      }
    }

    onUnmounted(() => {
      toggleGlobalStyles(false)
    })

    watch([showAuthenticated, show2Fa], () => {
      handleContainerHeightChange()
    })

    return {
      formState,
      loading,
      connectingCanva,
      rules,
      code2Fa,
      formWrapper,
      formSizer,
      show2Fa,
      showAuthenticated,
      initialized,
      fullName,
      signUpUrl,
      formRef,
      handleCancel2Fa,
      connectCanvaWith2Fa,
      connectCanva,
      handleGoToLogin,
      continueWithCurrentAccount
    }
  }
})
</script>

<style scoped lang="less">
  @header-height: 60px;
  .auth-page {
    min-height: 100vh;
    .auth-page-wrapper {
      max-width: 600px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: @header-height;
        padding: 16px;
        width: 100%;
      }
      .auth-form {
        flex: 1;
        display: flex;
        align-items: center;
        margin-bottom: @header-height;
        width: 510px;
        .auth-form-wrapper {
          padding: 32px;
          width: 100%;
          transition: height 0.3s ease;
          .auth-form-title {
            text-align: center;
          }
        }
      }
    }
  }
</style>
