<script setup>

import { QuestionCircleOutlined } from '@ant-design/icons-vue'

const handleChatLinkClick = () => {
  window.HubSpotConversations?.widget?.open()
}

</script>

<template>
  <a-popover
    placement="right"
  >
    <template #content>
      <div style="display: flex; flex-direction: column">
        <a-typography-link
          type="secondary"
          target="_blank"
          href="https://knowledgebase.kitcast.tv/"
        >
          {{ $t('components.rootSidebarInfoMenuItem.wiki') }}
        </a-typography-link>
        <a-typography-link
          type="secondary"
          href="https://kitcast.tv/contact"
          target="_blank"
        >
          {{ $t('components.rootSidebarInfoMenuItem.contactUs') }}
        </a-typography-link>
        <a-typography-link
          type="secondary"
          @click="handleChatLinkClick"
        >
          {{ $t('components.rootSidebarInfoMenuItem.chat') }}
        </a-typography-link>
        <a-typography-link
          type="secondary"
          href="https://kitcast.tv/"
          target="_blank"
        >
          {{ $t('components.rootSidebarInfoMenuItem.web') }}
        </a-typography-link>
      </div>
    </template>
    <a-menu-item>
      <template #icon>
        <QuestionCircleOutlined />
      </template>
    </a-menu-item>
  </a-popover>
</template>

<style scoped lang="less">

</style>
