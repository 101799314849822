import {
  GET_CURRENT_WORKSPACE,
  GET_CURRENT_WORKSPACE_EDU_TIER_APPLICATION,
  GET_WORKSPACE_SSO_IDENTITY_PROVIDER,
  GET_WORKSPACES
} from '@/graphql/queries'
import {
  CREATE_WORKSPACE,
  SET_WORKSPACE_SSO_IDENTITY_PROVIDER,
  UNSET_WORKSPACE_SSO_IDENTITY_PROVIDER,
  UPDATE_WORKSPACE_BY_ID,
  UPDATE_WORKSPACE_NOTIFICATION_SETTINGS,
} from '@/graphql/mutations'
import router from '@/router'
import { handleActionError, } from '@/helpers/ErrorHandler'
import members from '@/store/workspace/members'
import invites from '@/store/workspace/invites'
import activityLogs from '@/store/workspace/activityLogs'
import media from '@/store/workspace/media'
import settings from '@/store/workspace/settings'
import features from '@/store/workspace/features'
import role from '@/store/workspace/role'
import billing from '@/store/workspace/billing'
import { apolloCall } from '@/helpers/Graphql'

const redirectTo = (name, params = {}) => {
  return router.replace({ name, params })
}

export default {
  namespaced: true,
  state: {
    workspace: null,
    workspacesList: [],
    workspaceLoaded: false,
    workspaceSSOIdentityProvider: null,
    changingWorkspace: false
  },
  actions: {
    async getWorkspaceInfo ({ commit }) {
      try {
        const { getCurrentWorkspace: workspace } = await apolloCall({
          commit,
          query: GET_CURRENT_WORKSPACE,
          loadingMutation: 'SET_WORKSPACE_LOADED',
          key: 'workspace'
        })
        commit('SET_CURRENT_WORKSPACE', { workspace })
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async updateEduTierApplication ({ commit }) {
      try {
        const { getCurrentWorkspace: workspace } = await apolloCall({
          query: GET_CURRENT_WORKSPACE_EDU_TIER_APPLICATION
        })
        commit('UPDATE_CURRENT_WORKSPACE', { eduTierApplication: workspace.eduTierApplication })
      } catch (e) {
        handleActionError(e)
      }
    },
    async getAvailableWorkspaces ({ commit }) {
      try {
        const { listMyWorkspaces } = await apolloCall({
          query: GET_WORKSPACES,
        })
        commit('SET_AVAILABLE_WORKSPACES', listMyWorkspaces)
      } catch (e) {
        handleActionError(e)
      }
    },
    async createAndChangeWorkspace ({ dispatch }, input) {
      try {
        const { createWorkspace: { id } } = await apolloCall({
          mutation: CREATE_WORKSPACE,
          variables: { input }
        })
        dispatch('changeWorkspace', id)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async changeWorkspace ({dispatch, commit, getters, rootGetters}, workspaceId) {
      if (getters.workspaceId === workspaceId) return
      commit('SET_CHANGING_WORKSPACE', true)
      await dispatch('resetWorkspace')
      await dispatch('getAvailableWorkspaces')
      commit('auth/SET_FORCED_WORKSPACE_ID', workspaceId, { root: true })
      await dispatch('auth/setWorkspaceForSession', null, {root: true})
      await dispatch('setWorkspace')
      await Promise.all([
        dispatch('groups/getAvailableStandardGroups', null, {root: true}),
        dispatch('groups/getAvailableSmartGroups', !rootGetters['workspace/features/smartGroupsEnabled'] ? true : null, {root: true}),
      ])
      await redirectTo('Login')
      commit('SET_CHANGING_WORKSPACE', false)
    },
    async setWorkspace ({dispatch, getters}) {
      await dispatch('getWorkspaceInfo')
      const isOwnerOrAdmin = getters['role/isOwner'] || getters['role/isAdmin']
      await Promise.all([
        (async () => {
          if (isOwnerOrAdmin) {
            await dispatch('subscription/getWorkspaceSubscription', null, {root: true})
          }
        })(),
        dispatch('social/getSocialAccounts', null, { root: true }),
        dispatch('devices/getAllDevices', null, { root: true })
      ])
    },
    async handleEDU ({getters, rootGetters, dispatch}) {
      const userIsComplete = rootGetters['user/userIsMigrated']
      const { applied, available, shown } = getters.eduTierApplication
      if (!applied && !shown && available && userIsComplete) {
        dispatch('openModal', {modal: 'eduAppliance'}, { root: true })
      }
    },
    async updateEDUAppliance ({dispatch}, input) {
      return dispatch('updateWorkspace', {eduTierApplication: input})
    },
    async resetWorkspace ({commit, dispatch}) {
      await commit('CLEAR_CONFIG', null, { root: true })
      await commit('CLEAR_PREV_ROUTE', null, { root: true })
      await Promise.all([
        dispatch('resetModule'),
        dispatch('groups/resetModule', null, { root: true }),
        dispatch('subscription/resetModule', null, { root: true }),
        dispatch('media/resetModule', null, { root: true }),
        dispatch('playlist/resetModule', null, { root: true }),
        dispatch('devices/resetModule', null, { root: true }),
        dispatch('closeAllModals', null, { root: true }),
        dispatch('notifications/resetModule', null, { root: true }),
        dispatch('tags/resetModule', null, { root: true }),
        dispatch('members/resetModule'),
        dispatch('invites/resetModule'),
        dispatch('activityLogs/resetModule')
      ])
    },
    async getWorkspaceSSOIdentityProvider ({ commit }) {
      try {
        const { getSsoIdentityProvider } = await apolloCall({
          query: GET_WORKSPACE_SSO_IDENTITY_PROVIDER
        })
        commit('SET_SSO_IDENTITY_PROVIDER', getSsoIdentityProvider)
      } catch (e) {
        handleActionError(e)
      }
    },
    async setWorkspaceSSOIdentityProvider ({ commit }, { clientId, clientSecret, issuer }) {
      try {
        const { setSsoIdentityProviderOidc } = await apolloCall({
          mutation: SET_WORKSPACE_SSO_IDENTITY_PROVIDER,
          variables: { input: { clientId, clientSecret, issuer } }
        })
        commit('SET_SSO_IDENTITY_PROVIDER', setSsoIdentityProviderOidc)
      } catch (e) {
        handleActionError(e)
      }
    },
    async unsetWorkspaceSSOIdentityProvider ({ commit }) {
      try {
        await apolloCall({ mutation: UNSET_WORKSPACE_SSO_IDENTITY_PROVIDER })
        commit('UNSET_SSO_IDENTITY_PROVIDER')
      } catch (e) {
        handleActionError(e)
      }
    },
    async updateWorkspaceNotificationSettings ({ commit }, settings) {
      try {
        const { updateMyCurrentWorkspaceMemberRoleSettings } = await apolloCall({
          mutation: UPDATE_WORKSPACE_NOTIFICATION_SETTINGS,
          variables: { input: settings }
        })
        commit('UPDATE_CURRENT_WORKSPACE_MEMBER_ROLE', updateMyCurrentWorkspaceMemberRoleSettings)
      } catch (e) {
        handleActionError(e)
      }
    },
    async updateWorkspace ({ commit, getters }, input) {
      const id = getters.workspaceId
      try {
        const { updateWorkspaceById } = await apolloCall({
          mutation: UPDATE_WORKSPACE_BY_ID,
          variables: { id, input }
        })
        commit('UPDATE_CURRENT_WORKSPACE', updateWorkspaceById)
      } catch (e) {
        handleActionError(e)
      }
    },
    setPreferredWorkspace({rootGetters}, { workspaceId, userId }) {
      userId = userId || rootGetters['user/userId']
      localStorage.setItem(`preferredWorkspace_${userId}`, workspaceId)
    },
    getPreferredWorkspace({rootGetters}) {
      const userId = rootGetters['user/userId']
      return localStorage.getItem(`preferredWorkspace_${userId}`)
    },
    resetModule ({ commit }) {
      commit('CLEAR_WORKSPACE_DATA')
    }
  },
  getters: {
    workspaceId: state => state.workspace?.id || '',
    workspaceName: state => state.workspace?.name || '',
    workspaceShortId: state => state.workspace?.shortId || '',
    workspace: state => state.workspace,
    workspacesList: state => state.workspacesList,
    pendingStripeSubscriptionUpdate: state => state.workspace?.pendingStripeSubscriptionUpdate,
    eduTierApplication: state => state.workspace?.eduTierApplication || {},
    workspaceNotificationSettings: state => state.workspace?.myMemberRole?.notificationSettings?.deviceStatusChanges?.enabled,
    workspaceWhitelabelDomain: state => state.workspace?.whitelabel,
    workspaceSSOIdentityProvider: state => state.workspaceSSOIdentityProvider,
    changingWorkspace: state => state.changingWorkspace,
    deviceInitializationToken: state => state.workspace?.deviceInitializationToken,
    isFrozen: state => state.workspace?.frozen,
    frozenReason: state => state.workspace?.frozenReason,
    isManuallyFrozen: state => state.workspace?.frozen && state.workspace?.frozenReason === 'MANUALLY_FROZEN',
    isFrozenTooManyDevices: state => state.workspace?.frozen && state.workspace?.frozenReason === 'FROZEN_TOO_MANY_DEVICES',
    menuItems: state => state.workspace?.appearance?.menuItems || [],
    enterpriseRequestFormSubmittedAt: state => state.workspace?.enterpriseRequestFormSubmittedAt,
    workspaceLoaded: state => state.workspaceLoaded,
  },
  mutations: {
    SET_CURRENT_WORKSPACE (state, { workspace }) {
      state.workspace = workspace
    },
    UPDATE_CURRENT_WORKSPACE (state, payload) {
      state.workspace = { ...state.workspace, ...payload }
      if (!state.workspacesList?.length) return
      state.workspacesList = state.workspacesList.map(workspace => workspace.id === state.workspace.id ? state.workspace : workspace)
    },
    UPDATE_CURRENT_WORKSPACE_MEMBER_ROLE (state, payload) {
      state.workspace = { ...state.workspace, myMemberRole: { ...state.workspace.myMemberRole, ...payload } }
    },
    SET_SSO_IDENTITY_PROVIDER (state, provider) {
      state.workspaceSSOIdentityProvider = provider
    },
    UNSET_SSO_IDENTITY_PROVIDER (state) {
      state.workspaceSSOIdentityProvider = null
    },
    SET_AVAILABLE_WORKSPACES (state, workspacesList) {
      state.workspacesList = workspacesList
    },
    SET_WORKSPACE_LOADED (state, { status }) {
      state.workspaceLoaded = !status
    },
    SET_CHANGING_WORKSPACE (state, status) {
      state.changingWorkspace = status
    },
    CLEAR_WORKSPACE_DATA (state) {
      state.workspace = null
      state.workspaceLoaded = false
      state.workspaceSSOIdentityProvider = null
    }
  },
  modules: {
    members,
    invites,
    activityLogs,
    media,
    settings,
    features,
    role,
    billing
  }
}
