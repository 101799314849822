<template>
  <EditModal
    v-model:visible="showPlaylistModal"
    @update="onPlaylistCreate"
  />
  <GeneralSider>
    <div>
      <div class="playlists-heading">
        <a-typography-text type="secondary">
          {{ $t(`components.playlistSider.scheduled`) }}
        </a-typography-text>
        <div>
          <a-tooltip
            :title="$t(`components.playlistSider.sortBy`)"
            style="user-select: none"
          >
            <a-button
              shape="circle"
              @click="sortOrderIsAsc ? handleSortPlaylistToggle() : handleSortPlaylistToggle()"
            >
              <SortAscendingOutlined
                v-if="sortOrderIsAsc"
              />
              <SortDescendingOutlined
                v-else
              />
            </a-button>
          </a-tooltip>
          <a-tooltip
            :title="$t(`components.playlistSider.newPlaylist`)"
            style="user-select: none"
          >
            <a-button
              shape="circle"
              @click.prevent.stop="onPlaylistCreateStart"
            >
              <PlusOutlined />
            </a-button>
          </a-tooltip>
        </div>
      </div>
      <a-spin :spinning="playlistsLoading">
        <div ref="playlistSiderRef">
          <a-menu
            v-model:selectedKeys="selectedKeys"
            :style="{minHeight: !scheduledPlaylists.length ? '48px' : 'auto'}"
            theme="light"
            mode="inline"
          >
            <a-menu-item
              v-for="playlist in scheduledPlaylists"
              :key="playlist.id"
              class="playlist-menu-item"
              :class="{inactive: playlist.paused}"
            >
              <router-link
                :to="{
                  name: 'PlaylistPage',
                  params: { playlistId: playlist.id }
                }"
              >
                <a-badge
                  v-if="!playlist.isMainPlaylist"
                  :color="playlist.color || DEFAULT_PLAYLIST_COLOR"
                  dot
                />
                <span v-else>
                  <PushpinOutlined
                    class="pin-icon"
                    :style="{color: playlist.color || DEFAULT_PLAYLIST_COLOR}"
                  />
                </span>
                <span>{{ playlist.name }}</span>
                <Transition name="fade">
                  <div
                    v-if="playlist.paused"
                    class="status"
                  >
                    <a-tooltip
                      :title="$t('components.playlistPage.paused')"
                    >
                      <PauseCircleFilled />
                    </a-tooltip>
                  </div>
                </Transition>
              </router-link>
            </a-menu-item>
          </a-menu>
          <template v-if="!playlistsLoading && unscheduledPlaylists.length">
            <a-typography-text
              type="secondary"
              class="unscheduled-title"
            >
              {{ $t(`components.playlistSider.unscheduled`) }}
              <a-tooltip :title="$t(`components.playlistSider.unscheduledTooltip`)">
                <InfoCircleOutlined
                  style="margin-left: 4px;"
                />
              </a-tooltip>
            </a-typography-text>
            <a-menu
              v-model:selectedKeys="selectedKeys"
              style="min-height: 100px;"
              theme="light"
              mode="inline"
            >
              <a-menu-item
                v-for="playlist in unscheduledPlaylists"
                :key="playlist.id"
                class="playlist-menu-item"
                :class="{inactive: playlist.paused}"
              >
                <router-link
                  :to="{
                    name: 'PlaylistPage',
                    params: { playlistId: playlist.id }
                  }"
                >
                  <a-badge
                    v-if="!playlist.isMainPlaylist"
                    :color="playlist.color || DEFAULT_PLAYLIST_COLOR"
                    dot
                  />
                  <span v-else>
                    <PushpinOutlined
                      class="pin-icon"
                      :style="{color: playlist.color || DEFAULT_PLAYLIST_COLOR}"
                    />
                  </span>
                  <span>{{ playlist.name }}</span>
                  <Transition name="fade">
                    <div
                      v-if="playlist.paused"
                      class="status"
                    >
                      <a-tooltip
                        :title="$t('components.playlistPage.paused')"
                      >
                        <PauseCircleFilled />
                      </a-tooltip>
                    </div>
                  </Transition>
                </router-link>
              </a-menu-item>
            </a-menu>
          </template>
        </div>
      </a-spin>
    </div>
  </GeneralSider>
</template>

<script>
import { computed, defineComponent, inject, onBeforeMount, ref, watch } from 'vue'

import {
  PauseCircleFilled,
  PlusOutlined,
  PushpinOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  InfoCircleOutlined
} from '@ant-design/icons-vue'
import EditModal from './EditModal'
import { useRoute, useRouter } from 'vue-router'
import { DEFAULT_PLAYLIST_COLOR, DEFAULT_TRANSITION, DEFAULT_ZONING_LAYOUT } from '@/constants'
import { useStore } from 'vuex'
import { success, error } from '@/utils'
import GeneralSider from '@/components/siders/GeneralSider.vue'

const ASC = 'ASC'
const DESC = 'DESC'

const sortPlaylistByName = (a, b, order) => {
  const isAsc = order === ASC
  if (a.isMainPlaylist && !b.isMainPlaylist) {
    return -1;
  }
  if (!a.isMainPlaylist && b.isMainPlaylist) {
    return 1;
  }
  if (a.name < b.name) {
    return isAsc ? -1 : 1;
  }
  if (a.name > b.name) {
    return isAsc ? 1 : -1;
  }
  return 0;
}

export default defineComponent({
  name: 'PlaylistsSider',
  components: {
    GeneralSider,
    EditModal,
    SortAscendingOutlined,
    SortDescendingOutlined,
    PauseCircleFilled,
    PushpinOutlined,
    PlusOutlined,
    InfoCircleOutlined
  },
  props: {
    groupId: String,
    playlistId: String
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const onboardingService = inject('onboardingService')
    const playlistSiderRef = ref(null)
    const sortPlaylistBy = ref(localStorage.getItem('playlist-sort-order') || ASC)
    const scheduledPlaylists = computed(() => [...store.getters['groups/currentGroupPlaylistsScheduled'] || []].sort((a,b)=>sortPlaylistByName(a, b, sortPlaylistBy.value)))
    const unscheduledPlaylists = computed(() => [...store.getters['groups/currentGroupPlaylistsUnscheduled'] || []].sort((a,b)=>sortPlaylistByName(a, b, sortPlaylistBy.value)))
    const playlistsLoading = computed(() => store.getters['groups/playlistsFetching'])
    const showPlaylistModal = ref(false)
    const selectedKeys = computed(() => [route.params.playlistId])
    const sortOrderIsAsc = computed(()=>sortPlaylistBy.value === ASC)

    const onPlaylistCreateStart = () => {
      showPlaylistModal.value = true
    }

    const onPlaylistCreate = ({ name, color }) => {
      store.dispatch('groups/createCurrentGroupPlaylist', {
        name,
        color,
        layout: {
          type: DEFAULT_ZONING_LAYOUT,
          transitionType: DEFAULT_TRANSITION,
          zones: [{slides: []}]
        }
      }).then((playlist) => {
        const nextId = playlist.id
        success()
        router.push({ name: 'PlaylistPage', params: { playlistId: nextId } })
        selectedKeys.value = [nextId]
      }).catch(e =>{
        error(e.message)
      })
    }

    onBeforeMount(async ()=>{
      await store.dispatch('groups/fetchCurrentGroupPlaylists')
    })

    const handleSortPlaylistToggle = () => {
      const newOrder = sortPlaylistBy.value === ASC ? DESC : ASC
      localStorage.setItem('playlist-sort-order', newOrder)
      sortPlaylistBy.value = newOrder
    }

    watch(() => playlistSiderRef.value, (value) => {
      if (value) {
        onboardingService.setRef(value, 'playlistZone', () => {})
      }
    })

    return {
      scheduledPlaylists,
      unscheduledPlaylists,
      playlistsLoading,
      selectedKeys,
      showPlaylistModal,
      sortOrderIsAsc,
      playlistSiderRef,
      DEFAULT_PLAYLIST_COLOR,
      handleSortPlaylistToggle,
      onPlaylistCreateStart,
      onPlaylistCreate
    }
  }
})
</script>

<style lang="less">
  @import '../../../styles/variables';
  .playlists-heading {
    margin: 16px 0 8px 0;
    padding: 0 4px 0 24px;
    font-size: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item.playlist-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .unscheduled-title {
    display: block;
    margin: 16px 0 4px 0;
    padding: 0 4px 0 24px;
    font-size: 90%;
  }
  .playlist-menu-item {
    padding-left: 0;
    .status {
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
      color: @text-color-secondary-dark;
      display: flex;
      align-items: center;
    }
    .ant-badge-status-dot, .pin-icon {
      width: 16px;
      height: 16px;
      transition: opacity .2s ease;
    }
    .pin-icon {
      margin-right: 8px;
    }
    &.inactive {
      background-color: @bg-light-grey;
      .ant-badge-status-dot {
        opacity: 0.3;
      }
    }
  }
  .menu-item-with-action {
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
