import assets from '@/assets/composer.json'

const stickers =[
  {"name":"bubbles","items":104,"type":"png"},
  {"name":"stars","items":6,"type":"png","darkbg":true},
  {"name":"transportation","items":22,"type":"svg"},
  {"name":"doodles","items":100,"type":"svg"},
  {"name":"landmarks","items":100,"type":"svg"},
  {"name":"beach","items":22,"type":"svg"},
  {"name":"animals","items":10,"type":"png"},
  {"name":"clouds","items":15,"type":"png","darkbg":true}
]

const stickersByCategory = {}
stickers.forEach(sticker => {
  const { name, items, type, darkbg } = sticker
  stickersByCategory[name] = []
  for (let i = 1; i <= items; i++) {
    const path = require(`@/assets/images/stickers/${name}/${i}.${type}`)
    stickersByCategory[name].push({
      src: path,
      thumbnail: path,
      darkBg: darkbg,
      key: path,
      contain: true,
      isSvg: type === 'svg'
    })
  }
})

export function getComposerAssets () {
  let mappedAssets = null

  function mapAssets() {
    const mAssets = {}
    Object.keys(assets).forEach(cat => {
      mAssets[cat] = assets[cat].map(item => {
        return {
          src: process.env.VUE_APP_STATIC_ASSETS + item.path,
          thumbnail: process.env.VUE_APP_STATIC_ASSETS + item.thumbnail,
          key: item.path,
          contain: true
        }
      })
    })
    return {...stickersByCategory, ...mAssets}
  }

  return function () {
    if (!mappedAssets) {
      mappedAssets = mapAssets()
    }
    return mappedAssets
  }
}
