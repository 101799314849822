import { loadJson } from '@/utils'
import { createFabricFilter } from '@/components/slideModal/CustomTemplateEditor/utils/createFabricFilter'
import { filters } from '@/components/slideModal/CustomTemplateEditor/assets'

const matchFilterMap = {
  sepia2: "sepia"
}

const filterNameMap = {
  sepia2: "sepia", // No sepia in 6.6.1
  removewhite: "removecolor", // RemoveWhite = RemoveColor
  gradienttransparency: "colormatrix", // GradientTransparency = ColorMatrix with mode "gradientTransparency"
}

const updateFiltersInJson = (json) => {
  if (!json.state?.objects) return json

  json.state.objects.forEach(obj => {
    obj.filters = (obj.filters || []).map(filter => {
      if (!filter.type) return null
      let name = filter.type?.toLowerCase()
      name = matchFilterMap[name] || name
      const f = filters.find(f => f.name.toLowerCase() === name.toLowerCase())
      if (!f) return null
      return createFabricFilter({ ...f, name: filterNameMap[name] || name})
    })
      .filter(Boolean)
  })

  return json
}

export const getTemplateJsonFromUrl = async (url) => {
  const baseUrl = 'https://dashboard.next.kitcast.tv'

  const stickersContext = require.context('@/assets/images/stickers', true, /\.(png|jpe?g|svg)$/)

  const resolveLocalPath = (src) => {
    try {
      const relativePath = src.replace(`${baseUrl}/pixie/assets/images/stickers/`, '')
      return stickersContext(`./${relativePath}`)
    } catch (e) {
      console.warn(`Image not found locally: ${src}`)
      return src
    }
  }

  const replacements = {
    [`${baseUrl}/composer`]: `${process.env.VUE_APP_STATIC_ASSETS}/composer`,
    [`${baseUrl}/pixie/assets/images/gradients`]: `${process.env.VUE_APP_STATIC_ASSETS}/composer/images/gradients`,
    [`${baseUrl}/pixie/assets/images/patterns`]: `${process.env.VUE_APP_STATIC_ASSETS}/composer/images/patterns`,
    [`${baseUrl}/pixie/assets/images/stickers/`]: resolveLocalPath
  }

  let json = await loadJson(url)
  if (json.version > '6') return json
  if (!json.state?.objects) return json || {}

  json.state.objects = json.state.objects.filter((obj) => {
    if (obj.type === "image" || obj.type === "backgroundImage") {
      if (typeof obj.src !== 'string') {
        return false
      }
      if (obj.src.startsWith('data:application/pdf')) {
        return false
      }
      for (const [key, value] of Object.entries(replacements)) {
        if (obj.src.startsWith(key)) {
          obj.src = typeof value === 'function' ? value(obj.src) : obj.src.replace(key, value)
          break
        }
      }
      obj.crossOrigin = "anonymous"
    }
    if (obj.type === "path-group") {
      obj.type = "group"
      if (obj.paths) {
        obj.objects = obj.paths
        delete obj.paths
      }
    }
    return true
  })

  if (json.state.backgroundImage) {
    json.state.backgroundImage.crossOrigin = "anonymous"
    let updatedSrc = json.state.backgroundImage.src
    for (const [key, value] of Object.entries(replacements)) {
      if (updatedSrc?.startsWith(key)) {
        updatedSrc = typeof value === 'function' ? value(updatedSrc) : updatedSrc.replace(key, value)
        break
      }
    }
    json.state.backgroundImage.src = updatedSrc
    delete json.state.background
  } else if (json.state.background && typeof json.state.background !== 'string') {
    let updatedSrc = json.state.background.source
    for (const [key, value] of Object.entries(replacements)) {
      if (updatedSrc?.startsWith(key)) {
        updatedSrc = typeof value === 'function' ? value(updatedSrc) : updatedSrc.replace(key, value)
        break
      }
    }
    json.state.backgroundImage = {
      cropX: 0,
      cropY: 0,
      type: "Image",
      version: "6.6.1",
      originX: "center",
      originY: "center",
      left: json.canvasWidth / 2,
      top: json.canvasHeight / 2,
      width: json.canvasWidth,
      height: json.canvasHeight,
      fill: "rgb(0,0,0)",
      stroke: null,
      strokeWidth: 0,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      src: updatedSrc,
      crossOrigin: "anonymous",
      filters: []
    }
    delete json.state.background
  }
  json = updateFiltersInJson(json)
  // console.log(json)
  return json || {}
}
