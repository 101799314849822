<script setup>
import { useStore } from 'vuex'
import { computed, onBeforeMount, ref } from 'vue'
import ImagesList from '@/components/slideModal/CustomTemplateEditor/components/ImagesList.vue'

const store = useStore()
const category = ref('all')
const searchTag = computed(() => category.value === 'all' ? '' : category.value)
const templatesList = computed(() => {
  return store.getters['customTemplate/templatesByTag'](searchTag.value)
      ?.map(template => ({
        jsonUrl: template.jsonUrl,
        thumbnail: template.previewUrl,
        key: template.previewUrl,
      }))
})

const templatesLoading = computed(() => store.getters['customTemplate/templatesLoading'])
const templatesCategories = computed(() => store.getters['customTemplate/templatesCategories'])
const templatesCategoriesOptions = computed(() => [
  { label: 'All', value: 'all' },
  ...templatesCategories.value.map(category => ({ label: category, value: category }))
])

const handleTemplateChange = ({jsonUrl}) => {
  jsonUrl && store.dispatch('customTemplate/loadJsonFromUrl', jsonUrl)
}


onBeforeMount(() => {
  store.dispatch('customTemplate/fetchTemplates')
})


</script>

<template>
  <strong>{{ $t('components.customTemplateEditor.category') }}</strong>
  <a-select
    v-model:value="category"
    style="width: 100%;"
    :options="templatesCategoriesOptions"
  />
  <ImagesList
    :key="category"
    :loading="templatesLoading"
    :list="templatesList"
    @click="handleTemplateChange"
  />
</template>

<style scoped lang="less">

</style>
