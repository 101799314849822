<script setup>
import { computed, reactive, ref } from 'vue'
import ColorGradientTab from './components/ColorGradientTab.vue'
import ImageTab from './components/ImageTab.vue'

const TABS_OPTIONS =[
  {
    label: 'Color/Gradient',
    value: 'Color/Gradient'
  },
  {
    label: 'Image',
    value: 'Image'
  },
]
const TAB_OPTIONS_COMPONENTS_MAP = {
  'Color/Gradient': ColorGradientTab,
  'Image': ImageTab,
}
const activeTab = ref(TABS_OPTIONS[0].value)
const component = computed(() => TAB_OPTIONS_COMPONENTS_MAP[activeTab.value])
</script>

<template>
  <a-segmented
    v-model:value="activeTab"
    :options="TABS_OPTIONS"
    block
  />
  <component :is="component" />
</template>

<style scoped lang="less">

</style>
