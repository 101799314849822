<script setup>

import TabWrapper from '@/components/slideModal/CustomTemplateEditor/components/TabWrapper.vue'
import ImagesList from '@/components/slideModal/CustomTemplateEditor/components/ImagesList.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const patternsList = computed(() => store.getters['customTemplate/patterns'])

const handlePatternChange = (item) => {
  store.dispatch('customTemplate/setCanvasBackgroundPattern', item.src)
}

const handleImageUpload = (url) => {
  store.dispatch('customTemplate/setCanvasBackgroundImage', url)
}

</script>

<template>
  <TabWrapper>
    <ImagesList
      show-upload
      :list="patternsList"
      @click="handlePatternChange"
      @upload="handleImageUpload"
    />
  </TabWrapper>
</template>

<style scoped lang="less">

</style>
