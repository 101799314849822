import { openLoginWindow } from '@/utils'

const APIKey = process.env.VUE_APP_GOOGLE_TIMEZONE_API_KEY

export const getTimezoneByLocation = (location) => {
  return fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=1458000000&key=${APIKey}`, {
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.status !== 'OK') {
        throw new Error(json.error)
      }
      return json
    })
}

export const openLoginWithGoogleWindow = () => {
  openLoginWindow(process.env.VUE_APP_GOOGLE_LOGIN_LINK, 'google')
}
