<script setup>

</script>

<template>
  <div class="tab">
    <slot />
  </div>
</template>

<style scoped lang="less">
.tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}

</style>
