<script setup>
import InfoHeader from '@/components/InfoHeader.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
const store = useStore()
const trialIsOver = computed(() => store.getters['workspace/billing/trialIsOver'])
const hasFreeDevices = computed(() => !!store.getters['workspace/billing/freeDevicesQuantity'])
const trialDaysLeft = computed(() => store.getters['workspace/billing/trialDaysLeft'])
const openUpgradeSubscriptionModal = () => {
  store.dispatch('handleUpdateSubscription')
}
</script>

<template>
  <InfoHeader
    v-if="!trialIsOver || !hasFreeDevices"
    :type="trialIsOver ? 'danger' : 'default'"
  >
    <template v-if="trialIsOver">
      <i18n-t
        keypath="components.trialHeader.trialIsOverText"
        tag="label"
        for="components.trialHeader.trialIsOverLink"
      >
        <a
          class="upgrade-link"
          @click="openUpgradeSubscriptionModal"
        >{{ $t('components.trialHeader.trialIsOverLink') }}</a>
      </i18n-t>
    </template>
    <template v-else>
      <a-space>
        <span>{{ $t('components.trialHeader.trialEndsIn', {days: trialDaysLeft}, trialDaysLeft ) }}</span>
        <i18n-t
          keypath="components.trialHeader.trialEndsUpgradeText"
          tag="label"
          for="components.trialHeader.trialEndsUpgradeLink"
        >
          <a
            class="upgrade-link"
            @click="openUpgradeSubscriptionModal"
          >{{ $t('components.trialHeader.trialEndsUpgradeLink') }}</a>
        </i18n-t>
      </a-space>
    </template>
  </InfoHeader>
</template>

<style scoped lang="less">
.upgrade-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
