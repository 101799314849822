<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash'
import { FolderFilled, LeftOutlined, RightOutlined, RollbackOutlined, HomeOutlined } from '@ant-design/icons-vue'
import { error, formatDate, formatFileSize, getIconNameByMimetype } from '@/utils'
import { ACCEPTED_MIME_TYPES, FALLBACK_IMAGE, MEDIA_TYPES, MEDIA_TYPES_ENUM, TABLE_PAGE_LIMIT } from '@/constants'
import mime from 'mime-types'
import { filesIcons } from '@/helpers/Icons'
import FixedFooter from '@/components/FixedFooter'
import { SlideAsset } from '@/helpers/Slides'
import StickyFooter from '@/components/StickyFooter.vue'

const COLUMNS = [
  {
    title: '',
    dataIndex: 'mimeType',
    align: 'center',
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    fixed: 'left',
    defaultSortOrder: 'descend'
  }, {
    title: 'fileName',
    dataIndex: 'name',
    key: 'fileName',
    resizable: true,
    ellipsis: true,
    width: 240,
    maxWidth: 800,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.name.localeCompare(b.name),
      multiple: 2
    }
  }, {
    title: 'modifiedDate',
    dataIndex: 'client_modified',
    key: 'modifiedDate',
    width: 200,
    minWidth: 100,
    maxWidth: 400,
    sorter: {
      compare: (a, b) => a.createdAtSeconds - b.createdAtSeconds
    }
  }, {
    title: 'preview',
    dataIndex: 'preview_url',
    key: 'preview',
    width: 100,
    minWidth: 100,
    maxWidth: 110
  }, {
    title: 'size',
    dataIndex: 'size',
    key: 'size',
    width: 100,
    minWidth: 100,
    maxWidth: 300,
    sorter: {
      compare: (a, b) => a.size - b.size
    }
  }]

const SOURCE_TYPE = 'DROPBOX_ACCOUNT'

const props = defineProps({
  dropboxAccountId: String,
  mediaTypes: {
    type: String,
    validator (value) {
      return MEDIA_TYPES.indexOf(value) !== -1
    }
  },
  selection: {
    type: String,
    validator (value) {
      return ['single', 'multiple', 'none'].indexOf(value) !== -1
    }
  },
  excludeHeadings: {
    type: Array,
    default: () => []
  }
})

const store = useStore()
const currentPage = ref(0)
const currentPath = ref([])
const folderState = reactive({
  files: [],
  hasMore: null,
  cursor: null
})
const selectedKeys = ref([])
const loading = ref(true)
const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])
const isBackDisabled = computed(() => !currentPath.value?.length || loading.value)
const isPagePrevDisabled = computed(() => currentPage.value === 0 || loading.value)
const isPageNextDisabled = computed(() => (!folderState?.hasMore && !nextPageLoaded.value) || loading.value)
const currentPageFiles = computed(() => folderState.files?.[currentPage.value])
const nextPageLoaded = computed(() => !!folderState.files?.[currentPage.value + 1])
const path = computed(() => currentPath.value?.at(-1) || '')
const acceptedMimeTypes = props.mediaTypes ? ACCEPTED_MIME_TYPES[props.mediaTypes] : null
const durationForVideoIds = reactive({})

const isVideo = computed(() => props.mediaTypes?.includes(MEDIA_TYPES_ENUM.VIDEO))

onMounted(() => {
  props.dropboxAccountId && fetchFolderFiles()
})

const bindEntriesToState = ({ entries, cursor, has_more: hasMore }) => {
  const files = entries.map(e => {
    const fileMimeType = mime.lookup(e.name)
    const mimeType = e.__typename === 'DropboxListFolderEntryFolder' ? 'folder' : fileMimeType
    const icon = filesIcons[getIconNameByMimetype(mimeType)]
    return { ...e, mimeType, icon, key: e.id, id: e.id, name: e.name, disabled: acceptedMimeTypes && !acceptedMimeTypes.includes(fileMimeType) }
  })
  folderState.files.push(files)
  folderState.cursor = cursor
  folderState.hasMore = hasMore
}

const fetchFolderFiles = async () => {
  loading.value = true
  const data = await store.dispatch('media/fetchDropboxFolder', {
    dropboxSocialAccountId: props.dropboxAccountId,
    params: {
      path: path.value?.id,
      limit: TABLE_PAGE_LIMIT
    }
  }).catch(e => {
    error(e.message)
  })
  bindEntriesToState(data)
  loading.value = false
}

const fetchFolderFilesContinue = async () => {
  loading.value = true
  const data = await store.dispatch('media/continueFetchDropboxFolder', {
    dropboxSocialAccountId: props.dropboxAccountId,
    params: {
      cursor: folderState.cursor
    }
  }).catch(e => {
    error(e.message)
  })
  bindEntriesToState(data)
  loading.value = false
}

const resetFolderState = () => {
  currentPage.value = 0
  folderState.files = []
  folderState.cursor = null
  folderState.hasMore = null
}

const openFolder = ({ id, name }) => {
  currentPath.value.push({id, name})
  resetFolderState()
  fetchFolderFiles()
}

const goToFolder = (p) => {
  if (!p) {
    if (currentPath.value.length === 0) return
    currentPath.value.pop()
  } else {
    if (path.value === p) return
    currentPath.value = currentPath.value.slice(0, currentPath.value.indexOf(p) + 1)
  }
  resetFolderState()
  fetchFolderFiles()
}

const goBack = () => {
  currentPath.value.pop()
  resetFolderState()
  fetchFolderFiles()
}

const nextPage = async () => {
  if (!nextPageLoaded.value) {
    await fetchFolderFilesContinue()
  }
  currentPage.value++
}

const prevPage = () => {
  currentPage.value--
}

const onSelectChange = (selection) => {
  selectedKeys.value = cloneDeep(selection)
  selectMedia(selection)
}

const selectMedia = async (selection = []) => {
  store.commit('media/SET_SELECTED_MEDIA', [])
  const selected = await Promise.all(
    selection.map(async id => {
      const { name } = currentPageFiles.value.find(f => f.id === id) || {}
      const isFileVideo = isVideo.value && mime.lookup(name).includes('video')
      let duration = durationForVideoIds[id] || null

      if (isFileVideo && !duration) {
        try {
          const metadata = await store.dispatch('media/getDropboxFileMetadata', {
            dropboxSocialAccountId: props.dropboxAccountId,
            dropboxEntryId: id
          })
          duration = metadata?.duration ? metadata.duration / 1000 : null
        } catch (e) {
          duration = null
        }
        durationForVideoIds[id] = duration
      }

      return new SlideAsset({
        id,
        name,
        sourceType: SOURCE_TYPE,
        metadata: {
          duration
        },
        mediaReference: {
          socialDropboxAccountId: props.dropboxAccountId,
          dropboxMediaId: id,
          sourceType: SOURCE_TYPE
        }
      })
    })
  )
  store.commit('media/SET_SELECTED_MEDIA', selected)
}

const rowSelection = computed(() => {
  if (props.selection === 'multiple') {
    return {
      onChange: onSelectChange,
      selectedRowKeys: selectedKeys.value,
      getCheckboxProps: record => ({
        disabled: record.disabled
      })
    }
  }
  return null
})

const customRow = (record) => {
  if (props.selection === 'single') {
    return {
      onClick: () => {
        if (record.disabled) return
        selectedKeys.value = [record.id]
        selectMedia([record.id])
      }
    }
  } else if (props.selection === 'multiple') {
    return {
      onClick: () => {
        if (record.disabled) return
        if (!selectedKeys.value?.includes(record.id)) {
          selectedKeys.value.push(record.id)
        } else {
          selectedKeys.value = selectedKeys.value.filter(r => r !== record.id)
        }
        selectMedia(cloneDeep(selectedKeys.value))
      }
    }
  }
  return null
}

const rowClassName = (record) => {
  const className = props.selection === 'single' && !record.disabled ? 'selectable ' : ''
  return selectedKeys.value?.includes(record.id) ? className + 'ant-table-row-selected selected' : className
}

const deselectAll = () => {
  store.commit('media/CLEAR_SELECTED_MEDIA')
}

const handleResizeColumn = (w, col) => {
  col.width = w
}

watch(selectedMediaIds, (mediaIds) => {
  selectedKeys.value = mediaIds
})
</script>

<template>
  <a-layout-content style="overflow-x: auto">
    <a-space>
      <a-button
        :style="{margin: '0 0 0 16px'}"
        :disabled="isBackDisabled"
        @click="goBack"
      >
        <template #icon>
          <RollbackOutlined />
        </template>
        {{ $t('components.dropboxView.backButtonText') }}
      </a-button>
      <a-breadcrumb
        separator=">"
      >
        <a-tooltip :title="$t('components.dropboxView.home')">
          <a-breadcrumb-item
            href=""
            @click.stop.prevent="goToFolder(undefined)"
          >
            <HomeOutlined />
          </a-breadcrumb-item>
        </a-tooltip>
        <a-breadcrumb-item
          v-for="(p, i) in currentPath"
          :key="i"
        >
          <a
            @click="goToFolder(p)"
          >
            {{ p.name }}
          </a>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </a-space>
    <a-table
      class="storage-table table-padded"
      size="small"
      :scroll="{ x: 600, y: 500 }"
      :loading="loading"
      :data-source="currentPageFiles"
      :columns="COLUMNS"
      position="topCenter"
      :pagination="false"
      :row-selection="rowSelection"
      :custom-row="customRow"
      :row-class-name="rowClassName"
      @resize-column="handleResizeColumn"
    >
      <template #headerCell="{ column }">
        <template v-if="column.key">
          {{ $t(`components.dropboxView.${column.key}`) }}
        </template>
      </template>
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.dataIndex === 'mimeType'">
          <a-tooltip v-if="text === 'folder'">
            <template #title>
              {{ $t('components.dropboxView.goToFolderTooltipTitle') }}
            </template>
            <FolderFilled
              style="font-size: 18px; cursor: pointer"
              @click.prevent.stop="openFolder({id: record.id, name: record.name})"
            />
          </a-tooltip>
          <component
            :is="record.icon"
            v-else
          />
        </template>
        <template v-else-if="column.dataIndex === 'name'">
          <template v-if="record.mimeType === 'folder'">
            <a
              class="table-link"
              @click.prevent.stop="openFolder({id: record.id, name: record.name})"
            >
              {{ text }}
            </a>
          </template>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <template v-else-if="column.dataIndex === 'size'">
          {{ formatFileSize(text) }}
        </template>
        <template v-else-if="column.dataIndex === 'client_modified'">
          {{ formatDate(text) }}
        </template>
        <template v-else-if="column.dataIndex === 'preview_url'">
          <a-image
            v-if="text"
            :height="40"
            :src="text"
            :preview="false"
            :fallback="FALLBACK_IMAGE"
            @click.stop.prevent
          />
          <div
            v-else
            class="img-spacer"
          />
        </template>
        <template v-else>
          {{ text }}
        </template>
      </template>
    </a-table>
    <div class="pagination">
      <a-space>
        <a-button
          size="small"
          :disabled="isPagePrevDisabled"
          @click="prevPage"
        >
          <template #icon>
            <LeftOutlined />
          </template>
        </a-button>
        <a-button
          size="small"
          :disabled="isPageNextDisabled"
          @click="nextPage"
        >
          <template #icon>
            <RightOutlined />
          </template>
        </a-button>
      </a-space>
    </div>
  </a-layout-content>
  <StickyFooter v-if="selectedMediaIds?.length">
    <FixedFooter>
      <template #left>
        {{ selectedMediaIds.length }} {{ selectedMediaIds.length === 1 ? 'File' : 'Files' }} Selected
      </template>
      <a-button
        size="small"
        @click="deselectAll"
      >
        {{ $t('components.dropboxView.deselectAllButtonText') }}
      </a-button>
    </FixedFooter>
  </StickyFooter>
</template>

<style lang="less" scoped>
  .img-spacer {
    height: 40px;
  }
  .pagination {
    margin: 0 16px 16px;
    display: flex;
    justify-content: flex-end;
  }
</style>
