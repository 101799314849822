<script>
import { computed, defineComponent, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_NAME_JOB_TITLE_REGEXP, COMPANY_SIZE_LIST, INDUSTRY_LIST, NAME_REGEXP } from '@/constants'
import { error, success } from '@/utils'
import { useI18n } from 'vue-i18n'
import AuthHeading from '@/views/AuthPage/components/AuthHeading.vue'
import { isURL } from 'validator'

export default defineComponent({
  name: 'UpdateUserInfoModal',
  components: { AuthHeading },

  setup () {
    const store = useStore()
    const { t } = useI18n()
    const user = computed(() => store.getters['user/user'])
    const userIsComplete = computed(() => store.getters['user/userIsComplete'])
    const isTrialTier = computed(() => store.getters['workspace/billing/isTrialTier'])
    const formRef = ref()
    const phoneObject = ref(null)
    const loading = ref(false)

    const formState = ref({})

    const rules = computed( ()=> {
      return {
        companyName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.updateUserInfoModal.companyNameInvalidError')
        },{
          trigger: 'blur',
          pattern: COMPANY_NAME_JOB_TITLE_REGEXP,
          message: t('components.updateUserInfoModal.companyNameInvalidPatternError')
        }],
        companyIndustry: [{
          required: true,
          trigger: 'blur',
          message: t('components.updateUserInfoModal.industryInvalidError')
        }],
        jobTitle: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.updateUserInfoModal.jobTitleInvalidError')
        },{
          trigger: 'blur',
          pattern: COMPANY_NAME_JOB_TITLE_REGEXP,
          message: t('components.updateUserInfoModal.jobTitleInvalidPatternError')
        }],
        companyWebsite: [{
          required: true,
          trigger: 'blur',
          message: t('components.updateUserInfoModal.companyWebsiteInvalidError')
        }],
        firstName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.updateUserInfoModal.firstNameInvalidError')
        }, {
          trigger: 'blur',
          pattern: NAME_REGEXP,
          message: t('components.updateUserInfoModal.firstNameInvalidPatternError')
        }],
        lastName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.updateUserInfoModal.lastNameInvalidError')
        }, {
          trigger: 'blur',
          pattern: NAME_REGEXP,
          message: t('components.updateUserInfoModal.lastNameInvalidPatternError')
        }],
        phone: [{
          required: true,
          trigger: 'blur',
          message: t('components.updateUserInfoModal.phoneInvalidError')
        }]
      }
    })

    const handleSubmit = () => {
      if (!phoneObject.value?.valid) return
      setTimeout(async () => {
        try {
          await formRef.value.validate()
        } catch (e) {
          return
        }
        loading.value = true
        const user = toRaw(formState.value)
        store.dispatch('user/updateProfile', user).then(async () => {
          await store.dispatch('workspace/billing/refetchFrozenStatusesAndBillingInfo')
          await store.dispatch('workspace/updateEduTierApplication')
          await store.dispatch('workspace/handleEDU')
          success()
        }).catch((e) => {
          const [errorObj] = e.graphQLErrors
          const [message] = errorObj?.extensions?.response?.message || [e.message]
          const originalErrorMessage = e.graphQLErrors?.[0]?.extensions?.originalError?.message?.[0]
          const errorMessage = originalErrorMessage ? t(`errors.codes["${originalErrorMessage}"]`) : message || e.message
          error(errorMessage)
        }).then(() => {
          loading.value = false
        })
      })
    }

    const handlePhoneChange = (number, phoneObj) => {
      phoneObject.value = phoneObj
    }

    function getUserProfile ({ companyName, companySize, companyWebsite, firstName, lastName, jobTitle, companyIndustry, phone, desiredDevicesCount}) {
      companySize = companySize || COMPANY_SIZE_LIST[0]
      desiredDevicesCount = desiredDevicesCount || 1
      formState.value = {companyName, companySize, companyWebsite, firstName, lastName, jobTitle, companyIndustry, phone, desiredDevicesCount}
    }

    user.value && getUserProfile(user.value)

    return {
      user,
      rules,
      formRef,
      formState,
      userIsComplete,
      loading,
      phoneObject,
      companySizesList: COMPANY_SIZE_LIST,
      industryList: INDUSTRY_LIST,
      handleSubmit,
      handlePhoneChange
    }
  }
})
</script>

<template>
  <a-modal
    :open="!userIsComplete"
    :closable="false"
    :footer="null"
    width="768px"
    style="top: 60px;"
    wrap-class-name="auth-modal"
  >
    <a-row :gutter="[48,16]">
      <a-col
        :span="15"
        class="forms"
      >
        <div>
          <AuthHeading
            :title="$t('components.updateUserInfoModal.title')"
            :subtitle="isTrialTier ? $t('components.updateUserInfoModal.subtitle') : null"
          />
          <a-form
            ref="formRef"
            layout="vertical"
            :model="formState"
            style="flex: 1; display: flex; flex-direction: column"
            :rules="rules"
            @submit.prevent="handleSubmit"
          >
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.firstNameLabel')"
                  name="firstName"
                >
                  <a-input
                    v-model:value="formState.firstName"
                    :placeholder="$t('components.updateUserInfoModal.firstNamePlaceholder')"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.lastNameLabel')"
                  name="lastName"
                >
                  <a-input
                    v-model:value="formState.lastName"
                    :placeholder="$t('components.updateUserInfoModal.lastNamePlaceholder')"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.phoneLabel')"
                  name="phone"
                >
                  <vue-tel-input
                    ref="phoneInput"
                    v-model="formState.phone"
                    :class="{'has-error': !phoneObject?.valid && formState.phone}"
                    :input-options="{ placeholder: $t('components.updateUserInfoModal.phonePlaceholder') }"
                    mode="international"
                    @on-input="handlePhoneChange"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.jobTitleLabel')"
                  name="jobTitle"
                >
                  <a-input
                    v-model:value="formState.jobTitle"
                    :placeholder="$t('components.updateUserInfoModal.jobTitlePlaceholder')"
                    autofocus
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-form-item
              :label="$t('components.updateUserInfoModal.industryLabel')"
              name="companyIndustry"
            >
              <a-select
                v-model:value="formState.companyIndustry"
                :placeholder="$t('components.updateUserInfoModal.industryPlaceholder')"
                :options="industryList.map((key) => ({ value: key, label: $t(`components.updateUserInfoModal.industries.${key}`) }))"
              />
            </a-form-item>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.companyNameLabel')"
                  name="companyName"
                >
                  <a-input
                    v-model:value="formState.companyName"
                    :placeholder="$t('components.updateUserInfoModal.companyNamePlaceholder')"
                    autofocus
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.companyWebsiteLabel')"
                  name="companyWebsite"
                >
                  <a-input
                    v-model:value="formState.companyWebsite"
                    :placeholder="$t('components.updateUserInfoModal.companyWebsitePlaceholder')"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.profileSettings.companySizeLabel')"
                  name="companySize"
                >
                  <a-select
                    v-model:value="formState.companySize"
                    :placeholder="$t('components.profileSettings.companySizePlaceholder')"
                    :options="companySizesList.map((key) => ({ value: key, label: $t(`components.profileSettings.companySizes.${key}`) }))"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :label="$t('components.updateUserInfoModal.screensQuantityLabel')"
                  name="desiredDevicesCount"
                >
                  <a-input-number
                    v-model:value="formState.desiredDevicesCount"
                    min="1"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-button
              :loading="loading"
              type="primary"
              html-type="submit"
              size="large"
            >
              {{ $t('components.updateUserInfoModal.continueButtonText') }}
            </a-button>
          </a-form>
        </div>
      </a-col>
      <a-col
        :span="9"
        class="image-container"
      >
        <img
          src="../../assets/images/kc-login-pic.jpg"
          alt=""
        >
      </a-col>
    </a-row>
  </a-modal>
</template>

<style scoped lang="less">
.auth-modal {
  .ant-modal-body {
    padding: 0;
    transition: all .3s ease;
  }
  .forms {
    padding-top: 48px;
    padding-left: 72px !important;
    padding-bottom: 48px;
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .image-container {
    height: 650px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
