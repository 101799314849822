<template>
  <icon>
    <template #component>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="3"
            fill="#000000"
          >
            <g
              id="Fonts-style-Thumbnails/3-Hover"
              transform="translate(11.000000, 12.000000)"
            >
              <path
                id="M"
                d="M19.34375,16.5625 L20.46875,14.4375 L20.46875,26 L19.34375,26 L19.34375,16.5625 Z M21.59375,12.25 L22.71875,10.125 L22.71875,26 L21.59375,26 L21.59375,12.25 Z M23.84375,8 L24.96875,5.859375 L24.96875,26 L23.84375,26 L23.84375,8 Z M16.296875,16.03125 L15.65625,14.859375 L23.40625,0.125 L24.671875,0.125 L16.296875,16.03125 Z M15,13.578125 L14.34375,12.390625 L20.84375,0.125 L22.125,0.125 L15,13.578125 Z M7.46875,14.5625 L8.59375,16.6875 L8.59375,26 L7.46875,26 L7.46875,14.5625 Z M5.21875,10.28125 L6.34375,12.40625 L6.34375,26 L5.21875,26 L5.21875,10.28125 Z M2.96875,6 L4.09375,8.140625 L4.09375,26 L2.96875,26 L2.96875,6 Z M12.0625,19.25 L10.796875,19.25 L1.84375,2.265625 L1.84375,26 L0.71875,26 L0.71875,0.125 L1.984375,0.125 L12.0625,19.25 Z M14.609375,19.25 L13.328125,19.25 L3.265625,0.125 L4.546875,0.125 L14.609375,19.25 Z M26.09375,2.265625 L17.140625,19.25 L15.859375,19.25 L5.8125,0.125 L7.078125,0.125 L16.515625,18.0625 L25.9375,0.125 L27.21875,0.125 L27.21875,26 L26.09375,26 L26.09375,2.265625 Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AlignIcon',
  components: {
    Icon
  }
})
</script>
