<script setup>
import { computed, ref, watch } from 'vue'
import { debounce } from 'lodash-es'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import GeoSuggest from '@/components/GeoSuggest'
import { WeatherSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: WeatherSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, WeatherSlide)

const { location, metric } = slide.value.slideData
const geoSuggest = ref(null)
const geoInput = ref(null)
const isLoadingLocations = ref(false)
const selectedPlace = ref(location?.address || null)
const searchInput = ref(location?.address || '')
const isCustomLocation = ref(!!selectedPlace.value)
const selectedSuggestion = ref(null)
const selectedLocation = ref(null)
const temperatureUnit = ref(metric ? 'celsius' : 'fahrenheit')

const suggestionOptions = computed(() => {
  const suggestions = geoSuggest?.value?.suggestions ?? []
  return suggestions.map(({
    description,
    placeId
  }) => ({
    label: description,
    value: placeId
  }))
})

const onDecode = async ({
  formattedAddress: address,
  location: {
    lat: latitude,
    lng: longitude
  }
}) => {
  selectedLocation.value = {
    address,
    latitude,
    longitude
  }
  selectedPlace.value = address
  isLoadingLocations.value = false
  selectedSuggestion.value = null
  updateSlideData({
    location: selectedLocation.value
  })
  updateSlide()
  toggleDisabled(false)
}

const handleLocationSelect = (v) => {
  const suggestion = geoSuggest?.value?.suggestions.find(s => s.placeId === v)
  isLoadingLocations.value = true
  selectedSuggestion.value = suggestion
}

const handleLocationSearch = (value) => {
  searchInput.value = value
}

const handleCustomLocationChange = () => {
  if (isCustomLocation.value && (!selectedLocation.value && !location)) {
    toggleDisabled(true)
    return
  }
  updateSlideData({
    location: isCustomLocation.value ? selectedLocation.value : null
  })
  updateSlide()
  toggleDisabled(false)
}

watch(temperatureUnit, (value) => {
  updateSlideData({
    metric: value === 'celsius'
  })
  updateSlide()
})

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.weather.slideStep1.subTitle')"
    :title="$t('components.slides.weather.slideStep1.title')"
    no-border
  >
    <GeoSuggest
      ref="geoSuggest"
      :debounce="fn => debounce(fn, 500)"
      :search="searchInput"
      :suggestion="selectedSuggestion"
      @geocoded="onDecode"
    />
    <a-checkbox
      v-model:checked="isCustomLocation"
      style="margin-bottom: 16px;"
      @change="handleCustomLocationChange"
    >
      {{ $t('components.slides.weather.slideStep1.pickLocationManuallyCheckbox') }}
    </a-checkbox>
    <a-select
      v-if="isCustomLocation"
      ref="geoInput"
      v-model:value="selectedPlace"
      :disabled="isLoadingLocations"
      :filter-option="false"
      :loading="isLoadingLocations"
      :max-tag-text-length="10"
      :not-found-content="isLoadingLocations ? undefined : null"
      :options="suggestionOptions"
      :placeholder="$t('components.slides.weather.slideStep1.placeSelectPlaceholder')"
      :show-search="true"
      style="width: 100%; margin-bottom: 16px"
      @search="handleLocationSearch"
      @select="handleLocationSelect"
    >
      <template
        v-if="isLoadingLocations"
        #notFoundContent
      >
        <a-spin size="small" />
      </template>
    </a-select>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.weather.slideStep2.title')"
    no-border
  >
    <a-radio-group
      v-model:value="temperatureUnit"
      button-style="solid"
    >
      <a-radio-button value="fahrenheit">
        {{ $t('components.slides.weather.slideStep2.fahrenheit') }}
      </a-radio-button>
      <a-radio-button value="celsius">
        {{ $t('components.slides.weather.slideStep2.celsius') }}
      </a-radio-button>
    </a-radio-group>
  </SlideStep>
  <a-typography-text
    style="display: block; margin-top: 16px;"
    type="secondary"
  >
    {{ $t('components.slides.weather.providedBy') }}
  </a-typography-text>
</template>

<style lang="less">

</style>
