<script setup>
import { Canvas, Rect } from 'fabric'

import { computed, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from 'vuex'
import ActionBar from '@/components/slideModal/CustomTemplateEditor/components/ActionBar/ActionBar.vue'
const store = useStore()

const loading = computed(() => store.getters['customTemplate/loading'].editor
  || store.getters['customTemplate/loading'].json || store.getters['customTemplate/loading'].export)

onMounted(() => {
  setTimeout(initFabric, 300)
})

const initFabric = () => {
  store.dispatch('customTemplate/initFabric')
}

const handleSelectionClear = (e) => {
  if (e.target.id === 'viewport') {
    store.dispatch('customTemplate/clearSelection')
  }
}

</script>

<template>
  <a-layout-content
    id="editor"
    style="background-color: #E2E2E2; overflow: auto; position:relative;"
  >
    <ActionBar />
    <a-spin
      v-if="loading"
      :spinning="loading"
      class="spinner"
    />
    <div
      id="viewport"
      @click.stop="handleSelectionClear"
    >
      <canvas
        id="canvas"
      />
    </div>
  </a-layout-content>
</template>

<style scoped lang="less">
.ant-spin.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
#viewport {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  :deep(.canvas-container) {
    overflow: hidden;
    margin: auto auto;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
    #canvas {
      background-color: #fff;
    }
  }
}
</style>
