import { Image, Pattern, IText, loadSVGFromURL, parseSVGDocument, loadSVGFromString, Group } from 'fabric'
import assets from '@/components/slideModal/CustomTemplateEditor/assets'
import { loadJson } from '@/utils'
import { migrateFabricJSON } from '@/components/slideModal/CustomTemplateEditor/utils/migrateFabricJson'
import { initFabricCanvas } from '@/components/slideModal/CustomTemplateEditor/store/fabricHooks'
import { createFabricFilter } from '@/components/slideModal/CustomTemplateEditor/utils/createFabricFilter'
import fonts from '@/components/slideModal/CustomTemplateEditor/store/fonts'
import { getComposerAssets } from '@/components/slideModal/CustomTemplateEditor/utils/composerAssets'
import canvasManager from '@/components/slideModal/CustomTemplateEditor/canvasManager'
import { getTemplateJsonFromUrl } from '@/components/slideModal/CustomTemplateEditor/utils/getTemplateJsonFromUrl'

const getStickers = getComposerAssets()
const MAX_HISTORY_SIZE = 10

const DEFAULT_WIDTH = 1920
const DEFAULT_HEIGHT = 1080

const DEFAULT_DIMENTIONS = {
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT
}

function getAspectRatiosFromString(aspectRatio) {
  const [width, height] = aspectRatio.split(':')
  const [wTop, wBottom] = width.split('/')
  const [hTop, hBottom] = height.split('/')
  try {
    return [wBottom ? parseInt(wTop) / parseInt(wBottom) : parseInt(wTop), hBottom ? parseInt(hTop) / parseInt(hBottom) : parseInt(hTop)]
  } catch (e) {
    return [1,1]
  }
}

const filterNameMap = {
  sepia2: "sepia", // No sepia in 6.6.1
  removewhite: "removecolor", // RemoveWhite = RemoveColor
  gradienttransparency: "colormatrix", // GradientTransparency = ColorMatrix with mode "gradientTransparency"
}


export default {
  namespaced: true,
  state: {
    currentJsonUrl: null,
    currentAspectRatio: null,
    currentDimensions: DEFAULT_DIMENTIONS,
    activeObject: null,
    activeTab: 'background',
    gradients: assets.gradients,
    patterns: assets.patterns,
    filters: assets.filters,
    stickers: getStickers(),
    templates: [],
    history: [],
    historyIndex: -1,
    lastAction: null,
    loading: {
      templates: false,
      json: false,
      editor: true,
      export: false
    },
    zoom: 100
  },
  actions: {
    setActiveTab({ commit }, tab) {
      commit('SET_ACTIVE_TAB', tab)
    },
    setFileExportLoading({ commit }, loading) {
      commit('SET_LOADING_STATUS', { status: loading, key: 'export' })
    },
    initEditor({ commit, dispatch }, { jsonUrl = null, aspectRatio }) {
      commit('SET_LOADING_STATUS', { status: true, key: 'editor' })
      commit('SET_CURRENT_JSON_URL', jsonUrl)
      commit('SET_CURRENT_ASPECT_RATIO', aspectRatio)

      const [wAspect, hAspect] = aspectRatio ? getAspectRatiosFromString(aspectRatio) : [1,1]

      commit('SET_CURRENT_DIMENSIONS', { width: DEFAULT_WIDTH*wAspect, height: DEFAULT_HEIGHT*hAspect })
    },
    async initFabric({ commit, dispatch, getters }) {
      commit('SET_LOADING_STATUS', { status: false, key: 'editor' })
      await initFabricCanvas(commit, dispatch, {jsonUrl: getters.currentJsonUrl, dimensions: getters.currentDimensions})
    },
    clearSelection({ commit }, canvas) {
      commit('CLEAR_SELECTION', canvas)
      commit('SET_ACTIVE_TAB', 'background')
    },
    setCanvasBackgroundColor({ commit }, color) {
      commit('SET_CANVAS_BACKGROUND_COLOR', color)
      commit('ADD_TO_HISTORY', { actionType: 'background-color', rewriteSame: false })
    },
    setCanvasBackgroundImage({ commit }, imageUrl) {
      commit('SET_CANVAS_BACKGROUND_IMAGE', imageUrl)
      commit('ADD_TO_HISTORY', { actionType: 'background-image', rewriteSame: false })
    },
    setCanvasBackgroundPattern({ commit }, patternUrl) {
      commit('SET_CANVAS_BACKGROUND_PATTERN', patternUrl)
      commit('ADD_TO_HISTORY', { actionType: 'background-pattern', rewriteSame: false })
    },
    changeSelectedObjectOpacity({ commit }, opacity) {
      commit('CHANGE_SELECTED_OBJECT_OPACITY', opacity)
    },
    async addTextToCanvas({ commit, dispatch }, text) {
      await dispatch('fonts/loadFont', { fontFamily: 'Playfair Display', cb: () => {
        commit('ADD_TEXT_TO_CANVAS', text)
        commit('SET_ACTIVE_TAB', 'fonts')
        commit('ADD_TO_HISTORY', { actionType: 'add-text', rewriteSame: false })
      }})
    },
    addImageToCanvas({ commit }, imageUrl) {
      commit('ADD_IMAGE_TO_CANVAS', imageUrl)
      commit('ADD_TO_HISTORY', { actionType: 'add-image', rewriteSame: false })
    },
    addSvgToCanvas({ commit }, svgUrl) {
      commit('ADD_SVG_TO_CANVAS', svgUrl)
      commit('ADD_TO_HISTORY', { actionType: 'add-svg', rewriteSame: false })
    },
    setActiveObjectProperty({ commit }, { name, value }) {
      commit('SET_ACTIVE_OBJECT_PROPERTY', { name, value })
      commit('ADD_TO_HISTORY', { actionType: `change-object-${name}`, rewriteSame: true })
    },
    removeActiveObject({ commit }) {
      commit('REMOVE_ACTIVE_OBJECT')
      commit('ADD_TO_HISTORY', { actionType: 'remove-object' })
    },
    loadAndSetFont({commit, dispatch}, fontFamily) {
      dispatch('fonts/loadFont', {
        fontFamily,
        cb: () => dispatch('setActiveObjectProperty', { name: 'fontFamily', value: fontFamily })
      })
    },
    loadFont({ commit, dispatch }, fontFamily) {
      dispatch('fonts/loadFont', {
        fontFamily
      })
    },
    moveActiveObjectForwards({ commit }) {
      commit('CHANGE_ACTIVE_OBJECT_DEPTH', 'forward')
      commit('ADD_TO_HISTORY', { actionType: 'depth-change', rewriteSame: false })
    },
    moveActiveObjectBackwards({ commit }) {
      commit('CHANGE_ACTIVE_OBJECT_DEPTH', 'back')
      commit('ADD_TO_HISTORY', { actionType: 'depth-change', rewriteSame: false })
    },
    applyFilterToActiveObject({commit}, filterConfig) {
      commit('APPLY_FILTER_TO_ACTIVE_OBJECT', filterConfig)
      commit('ADD_TO_HISTORY', { actionType: 'apply-filter', rewriteSame: false })
    },
    moveActiveObject({ commit }, e) {
      commit('MOVE_ACTIVE_OBJECT', e)
      commit('ADD_TO_HISTORY', { actionType: 'move-object', rewriteSame: true })
    },
    handleKeydownOnActiveObject ({ commit, dispatch }, e) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        dispatch('moveActiveObject', e)
      }
      else if (e.key === 'Delete' || e.key === 'Backspace') {
        const activeObject = canvasManager.canvas.getActiveObject()
        if (!activeObject) return
        if ((activeObject.type === 'textbox' || activeObject.type === 'i-text') && activeObject.isEditing) {
          return
        }
        dispatch('removeActiveObject')
      }
      else if (e.key === 'Escape') {
        commit('CLEAR_SELECTION')
      }
      else {
        dispatch('handleKeydownOnCanvas', e)
      }
    },
    handleKeydownOnCanvas({ commit, dispatch }, e) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
      const isUndo = (isMac ? e.metaKey : e.ctrlKey) && e.key === "z" && !e.shiftKey
      const isRedo = (isMac ? e.metaKey : e.ctrlKey) && ((e.key === "Z" && e.shiftKey) || e.key === "y")

      if (isUndo) {
        dispatch("undo")
        e.preventDefault()
      } else if (isRedo) {
        dispatch("redo")
        e.preventDefault()
      }

    },
    changeZoom({ commit }, zoom) {
      commit('SET_ZOOM', zoom)
    },
    async fetchTemplates ({ commit, getters }) {
      if (getters.templates.length) return
      commit('SET_LOADING_STATUS', { status: true, key: 'templates' })
      try {
        const json = await loadJson(process.env.VUE_APP_CUSTOM_TEMPLATES_JSON)
        commit('SET_TEMPLATES', json)
      } catch (e) {
        commit('SET_TEMPLATES', [])
      } finally {
        commit('SET_LOADING_STATUS', { status: false, key: 'templates' })
      }
    },
    async loadJsonFromUrl ({ commit, dispatch }, url) {
      commit('SET_LOADING_STATUS', { status: true, key: 'json' })
      try {
        const json = migrateFabricJSON(await getTemplateJsonFromUrl(url))
        const cb = async () => {
          if (!canvasManager.canvas) return
          await canvasManager.canvas.loadFromJSON(json).catch((e) => {
            console.error('Could not load JSON', e)
          })
          if (json.width && json.height) {
            canvasManager.canvas.setDimensions({
              width: json.width,
              height: json.height
            });
          }
          canvasManager.canvas.renderAll()
          dispatch('autoAdjustZoom')
          commit('INITIALIZE_HISTORY')
          commit('SET_LOADING_STATUS', { status: false, key: 'json' })
        }
        dispatch('fonts/preloadFontsFromJson', { json, cb, onError: cb })
      }
      catch (e) {
        commit('SET_LOADING_STATUS', { status: false, key: 'json' })
      }
    },
    addToHistory({ commit }, { actionType, rewriteSame = false }) {
      commit('ADD_TO_HISTORY', { actionType, rewriteSame })
    },
    undo({ commit }) {
      commit('UNDO')
    },
    redo({ commit }) {
      commit('REDO')
    },
    async getCanvasImageAndJson({ dispatch}) {
      const json = {
        ...canvasManager.canvas.toJSON(),
        width: canvasManager.canvas.width,
        height: canvasManager.canvas.height
      }

      const img = await dispatch('getCanvasImage')
      return { img, json }
    },
    getCanvasImage({ }) {
      const viewport = canvasManager.canvas.viewportTransform
      canvasManager.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
      const img = canvasManager.canvas.toDataURL({ format: 'png' })
      canvasManager.canvas.viewportTransform = viewport
      return img
    },
    autoAdjustZoom({ commit }) {
      const viewport = window.document.getElementById('editor')
      const container = viewport.getBoundingClientRect()
      const scaleX = (container.width * 0.8) / canvasManager.canvas.width
      const scaleY = (container.height * 0.8) / canvasManager.canvas.height
      const scale = Math.min(scaleX, scaleY)
      let zoom = (scale * 100).toFixed(0);
      zoom = Math.min(zoom, 100)

      commit('SET_ZOOM', zoom)
    },
    disposeCanvas({ commit, dispatch}) {
      dispatch('setActiveTab', 'background')
      commit('SET_ACTIVE_OBJECT', null)
      commit('CLEAR_HISTORY')
      canvasManager.destroy()
    }
  },
  getters: {
    currentJsonUrl: state => state.currentJsonUrl,
    currentDimensions: state => state.currentDimensions,
    activeObject: state => state.activeObject,
    isActiveObject: state => !!state.activeObject,
    gradients: state => state.gradients,
    patterns: state => state.patterns,
    filters: state => state.filters,
    templates: state => state.templates,
    templatesLoading: state => state.loading.templates,
    templatesCategories: state => {
      if (!state.templates) return []
      const categories = new Set()
      state.templates.forEach(({tags = []}) => tags?.forEach(tag => categories.add(tag)))
      return Array.from(categories).sort()
    },
    templatesByTag: state => (tag) => state.templates.filter(({ tags = []}) => {
      return tag ? tags.includes(tag) : true
    }),
    stickers: state => state.stickers,
    activeTab: state => state.activeTab,
    loading: state => state.loading,
    zoom: state => state.zoom,
    history: state => state.history,
    historyIndex: state => state.historyIndex,
    canUndo: state => state.history.length > 0 && state.historyIndex > 0,
    canRedo: state => state.historyIndex < state.history.length - 1
  },
  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      state.activeTab = tab
    },
    SET_ACTIVE_OBJECT(state, object) {
      state.activeObject = object
    },
    CLEAR_SELECTION() {
      if (!canvasManager.canvas) return
      canvasManager.canvas.discardActiveObject()
      canvasManager.canvas.requestRenderAll()
    },
    SET_CANVAS_BACKGROUND_COLOR(state, color) {
      if (!canvasManager.canvas) return
      const canvas = canvasManager.canvas
      canvas.backgroundImage = null
      canvas.backgroundColor = color
      canvas.requestRenderAll()
    },
    SET_CANVAS_BACKGROUND_IMAGE(state, imageUrl) {
      if (!canvasManager.canvas) return
        const canvas = canvasManager.canvas
        canvas.backgroundColor = null
        canvas.backgroundImage = null

        Image.fromURL(imageUrl, {
          crossOrigin: 'anonymous'
        })
        .then((img) => {
          const scaleX = canvas.width / img.width
          const scaleY = canvas.height / img.height
          const scale = Math.max(scaleX, scaleY) // Масштабируем пропорционально

          img.set({
            scaleX: scale,
            scaleY: scale,
            originX: 'center',
            originY: 'center',
            left: canvas.width / 2,
            top: canvas.height / 2
          })
          canvas.backgroundImage = img
          canvas.requestRenderAll()
        })
        .catch((error) => console.error('Could not load image', error))
    },
    SET_CANVAS_BACKGROUND_PATTERN(state, patternUrl) {
      if (!canvasManager.canvas) return
      const canvas = canvasManager.canvas
      canvas.backgroundColor = null
      canvas.backgroundImage = null

      Pattern.fromObject({
        source: patternUrl,
        repeat: 'repeat',
        crossOrigin: 'anonymous'
      })
      .then((pattern) => {
        canvas.backgroundColor = pattern
        canvas.requestRenderAll()
      })
      .catch((error) => console.error('Could not load image', error))
    },
    CHANGE_SELECTED_OBJECT_OPACITY(state, opacity) {
      if (!canvasManager.canvas || !state.activeObject) return
      if (state.activeObject) {
        const normalizedOpacity = opacity > 1 ? opacity / 100 : opacity
        state.activeObject.set('opacity', normalizedOpacity)
        canvasManager.canvas.requestRenderAll()
      }
      else {
        console.error('No active object')
      }
    },
    ADD_TEXT_TO_CANVAS(state, text) {
      if (!canvasManager.canvas) return
      const canvas = canvasManager.canvas
      const activeObject = state.activeObject
      const newText = new IText('Double click me to edit my contents.', {
        fontWeight: 400,
        fontSize: 50,
        fontStyle: '',
        fontFamily: 'Playfair Display',
        textDecoration: '',
        fill: '#000',
        removeOnCancel: false,
        name: 'text'
      })
      canvas.add(newText)
      newText.set({
        top: activeObject ? activeObject.top + 25 : 25,
        left: activeObject ? activeObject.left + 25 : 25
      })
      canvas.setActiveObject(newText)
      canvas.requestRenderAll()
    },
    ADD_IMAGE_TO_CANVAS(state, imageUrl) {
      if (!canvasManager.canvas) return
      const canvas = canvasManager.canvas
      const activeObject = state.activeObject
      Image.fromURL(imageUrl, {
        crossOrigin: 'anonymous'
      })
      .then((img) => {
        img.set({
          left: activeObject ? activeObject.left + 25 : 25,
          top: activeObject ? activeObject.top + 25 : 25,
        })

        canvas.add(img)
        canvas.setActiveObject(img)
        canvas.requestRenderAll()
      })
      .catch((error) => console.error('Could not load image', error))
    },
    async ADD_SVG_TO_CANVAS(state, svgUrl) {
      if (!canvasManager.canvas) return
      const canvas = canvasManager.canvas
      const activeObject = state.activeObject

      loadSVGFromURL(svgUrl)
        .then((svg) => {
          const { objects, options } = svg
          if (!objects || objects.length === 0) {
            throw new Error('SVG не содержит объектов.')
          }

          const offsetLeft = activeObject ? activeObject.left + 25 : 25
          const offsetTop = activeObject ? activeObject.top + 25 : 25

          const group = new Group(objects, {
            ...options,
            left: offsetLeft,
            top: offsetTop,
          })

          if (!group) {
            throw new Error('Error occurred while creating group.')
          }

          canvas.add(group)
          canvas.setActiveObject(group)
          canvas.requestRenderAll()
        })
        .catch(error => console.error("Could not load SVG", error))
    },
    REMOVE_ACTIVE_OBJECT(state) {
      if (!canvasManager.canvas) return
      const activeObject = canvasManager.canvas.getActiveObject()
      if (activeObject) {
        canvasManager.canvas.remove(activeObject)
        canvasManager.canvas.requestRenderAll()
      }
      else {
        console.error('No active object')
      }
    },
    SET_ACTIVE_OBJECT_PROPERTY(state, property) {
      if (!canvasManager.canvas || !state.activeObject) return
      const activeObject = state.activeObject
      const { name, value } = property
      activeObject.set(name, value)
      canvasManager.canvas.requestRenderAll()
    },
    CHANGE_ACTIVE_OBJECT_DEPTH(state, direction) {
      if (!canvasManager.canvas) return
      const activeObject = canvasManager.canvas.getActiveObject()
      if (!activeObject) return

      switch (direction) {
        case 'forward':
          canvasManager.canvas.bringObjectForward(activeObject)
          break
        case 'back':
          canvasManager.canvas.sendObjectBackwards(activeObject)
          break
        case 'front':
          canvasManager.canvas.bringObjectToFront(activeObject)
          break
        case 'backmost':
          canvasManager.canvas.sendObjectToBack(activeObject)
          break
        default:
          console.warn(`Unknown direction: ${direction}`)
          return
      }
      activeObject.setCoords()
      canvasManager.canvas.requestRenderAll()
    },
    APPLY_FILTER_TO_ACTIVE_OBJECT(state, filterConfig) {
      if (!canvasManager.canvas) return
      const activeObject = state.activeObject
      if (!activeObject) {
        console.error('No active object')
        return
      }
      const filterName = filterConfig.name.toLowerCase()
      const correctedFilterName = filterNameMap[filterName] || filterName
      const correctedConfig = { ...filterConfig, name: correctedFilterName }
      const newFilter = createFabricFilter(correctedConfig)
      if (!newFilter) return

      const filtersArray = activeObject.filters
      const existingFilterIndex = filtersArray.findIndex(
        (f) => (f.customType || f.type) === (newFilter.customType || newFilter.type)
      )

      if (existingFilterIndex !== -1) {
        filtersArray.splice(existingFilterIndex, 1)
      } else {
        filtersArray.push(newFilter)
      }
      activeObject.applyFilters()
      canvasManager.canvas.requestRenderAll()
    },
    MOVE_ACTIVE_OBJECT(state, e) {
      if (!canvasManager.canvas || !state.activeObject) return
      const canvas = canvasManager.canvas
      const activeObject = state.activeObject
      if (activeObject) {
        const step = e.shiftKey ? 10 : 1
        switch (e.key) {
          case 'ArrowUp':
            activeObject.top -= step
            break
          case 'ArrowDown':
            activeObject.top += step
            break
          case 'ArrowLeft':
            activeObject.left -= step
            break
          case 'ArrowRight':
            activeObject.left += step
            break
        }
        activeObject.setCoords()
        canvas.requestRenderAll()
      }
      else {
        console.error('No active object')
      }
    },
    SET_ZOOM(state, zoom) {
      if (!canvasManager.canvas) return

      state.zoom = zoom
      const scale = zoom / 100

      canvasManager.canvas.setZoom(scale)
      canvasManager.canvas.wrapperEl.style.width = canvasManager.canvas.width * scale + 'px'
      canvasManager.canvas.wrapperEl.style.height = canvasManager.canvas.height * scale + 'px'

      canvasManager.canvas.requestRenderAll()
    },
    SET_LOADING_STATUS (state, { status, key }) {
      state.loading[key] = status
    },
    SET_TEMPLATES_LOADING(state, loading) {
      state.loading.templates = loading
    },
    SET_JSON_LOADING(state, loading) {
      state.loading.json = loading
    },
    SET_TEMPLATES(state, templates) {
      state.templates = templates
    },
    async LOAD_JSON(state, json) {

    },
    ADD_TO_HISTORY(state, { actionType = null, rewriteSame = false } = {}) {
      if (!canvasManager.canvas) return
      canvasManager.canvas.once('after:render', () => {
        const json = canvasManager.canvas.toJSON()

        if (rewriteSame && state.history.length > 0 && state.lastAction === actionType) {
          state.history[state.history.length - 1].snapshot = json
          return
        }

        if (state.historyIndex < state.history.length - 1) {
          state.history = state.history.slice(0, state.historyIndex + 1)
        }

        state.history.push({
          snapshot: json,
          actionType: actionType
        })

        if (state.history.length > MAX_HISTORY_SIZE) {
          state.history.shift()
        }

        state.historyIndex = state.history.length - 1
        state.lastAction = actionType
      })
    },
    UNDO(state) {
      if (!canvasManager.canvas) return
      if (state.historyIndex <= 0) return

      state.historyIndex--

      const { snapshot } = state.history[state.historyIndex]

      canvasManager.canvas.loadFromJSON(snapshot).then(() => {
        canvasManager.canvas.renderAll()
        state.lastAction = state.history[state.historyIndex].actionType
      })
    },
    REDO(state) {
      if (!canvasManager.canvas) return

      if (state.historyIndex >= state.history.length - 1) return

      state.historyIndex++

      const { snapshot } = state.history[state.historyIndex]

      canvasManager.canvas.loadFromJSON(snapshot).then(() => {
        canvasManager.canvas.renderAll()
        state.lastAction = state.history[state.historyIndex].actionType
      })
    },
    INITIALIZE_HISTORY(state) {
      if (!canvasManager.canvas) return;

      const emptyCanvasState = canvasManager.canvas.toJSON();
      state.history = [{ snapshot: emptyCanvasState, actionType: "initial" }];
      state.historyIndex = 0;
      state.lastAction = "initial";
    },
    CLEAR_HISTORY(state) {
      state.history = []
      state.historyIndex = -1
    },
    SET_CURRENT_JSON_URL(state, jsonUrl) {
      state.currentJsonUrl = jsonUrl
    },
    SET_CURRENT_ASPECT_RATIO(state, aspectRatio) {
      state.currentAspectRatio = aspectRatio
    },
    SET_CURRENT_DIMENSIONS(state, dimensions) {
      state.currentDimensions = dimensions
    }
  },
  modules: {
    fonts
  }
}
