<script setup>
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch, watchEffect } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import { cloneDeep } from 'lodash-es'
import { error, getIsAmPmFormatFromLocalStorage, setIsAmPmFormatToLocalStorage, success } from '@/utils'
import { EVENT_BASE } from '@/constants'
import CalendarEventModal from '@/components/inputModals/CalendarEventModal.vue'
import { LeftOutlined, PauseCircleFilled, RightOutlined } from '@ant-design/icons-vue'
import moment from 'moment'
import { useStore } from 'vuex'
import {
  eventCalendarToDto as eventToDto,
  isAnOverlapEvent,
  mapElementsToEvents,
  ONETIME_WINDOW_TYPE
} from '@/helpers/Calendar'
import { useI18n } from 'vue-i18n'
import CalendarPageSubtitle from '@/components/CalendarPageSubtitle.vue'
import NewEventModal from '@/components/inputModals/NewEventModal.vue'

const DEFAULT_EVENT_TYPE = ONETIME_WINDOW_TYPE

const store = useStore()
const { t } = useI18n()
const events = ref([])
const calendar = ref()
const calendarWrapper = ref()
const playlistsFilter = ref([])
const showCalendarEventModal = ref(false)
const showPlaylistSelectModal = ref(false)
const selectedEvent = reactive(cloneDeep(EVENT_BASE))
const schedule = computed(() => store.getters['groups/currentGroupSchedule'])
const parentSchedules = computed(() => store.getters['groups/currentGroupParentSchedules'])
const playlists = computed(() => store.getters['groups/currentGroupPlaylists'])
const isSmartGroup = computed(() => store.getters['groups/currentGroupTypeIsSmart'])
const currentGroupSchedulesLoading = computed(() => store.getters['groups/currentGroupSchedulesLoading'])
const scheduleId = computed(() => schedule.value?.id)
const scheduleElements = computed(() => schedule.value?.data?.elements || [])
const scheduleElementsFiltered = computed(() => scheduleElements.value.filter(e => {
  if (!playlistsFilter.value.length) return true
  return playlistsFilter.value.includes(e.playlistId)
}))
const parentScheduleElements = computed(() => parentSchedules.value || [])
const playlistsOptions = computed(() => playlists.value?.map(p => ({ label: p.name, value: p.id, color: p.color, disabled: p.isMainPlaylist })))
const newEvent = reactive({
  playlistId: null,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null
})
const calendarViewOptions = computed(()=>[
  { label: t('components.calendarPage.weekView'), value: 'timeGridWeek' },
  { label: t('components.calendarPage.monthView'), value: 'dayGridMonth' }
])
let calendarWrapperWidth = null
const calendarApi = ref(null)
const isAmPmFormat = ref(getIsAmPmFormatFromLocalStorage())

const playlistsFilterOptions = computed(() => {
  const usedPlaylists = new Set([...scheduleElements.value
    .filter(e => e.type !== 'MAIN')
    .map(e => e.playlistId) || []])
  return playlistsOptions.value.filter(p => usedPlaylists.has(p.value))
})

const handleDateSelect = ({ startStr, endStr }) => {
  newEvent.startDate = startStr
  newEvent.endDate = endStr
  newEvent.startTime = moment(startStr).format('HH:mm')
  newEvent.endTime = moment(endStr).format('HH:mm')

  const events = calendarApi.value?.getEvents()?.filter(e => e.extendedProps.element.type === 'ONETIME_WINDOW')
  if (isAnOverlapEvent(startStr, endStr, events, '')) {
    error(t('components.calendarPage.eventsOverlapError'))
    calendarApi.value?.unselect()
    return
  }
  showPlaylistSelectModal.value = true
  return true
}

const currentDateRange = ref()

const currentCalendarView = ref()

const handleEventClick = (input) => {
  if (input.event.display === 'background') {
    return
  }
  const timeTo = moment(input.event.endStr).format('HH:mm')
  const startDay = moment(input.event.startStr).format('MMM D')
  const endDay = moment(input.event.endStr).format('MMM D')
  selectedEvent.eventId = input.event.id
  selectedEvent.playlistName = input.event.title
  selectedEvent.playlistId = input.event.extendedProps.playlistId
  selectedEvent.color = input.event.extendedProps.color
  selectedEvent.paused = input.event.extendedProps.paused
  selectedEvent.timeFrom = moment(input.event.startStr).format('HH:mm')
  selectedEvent.timeTo = timeTo === selectedEvent.timeFrom ? '24:00' : timeTo
  selectedEvent.day = startDay === endDay ? startDay : `${startDay} - ${endDay}`
  selectedEvent.event = input.event
  showCalendarEventModal.value = true
}

onMounted(() => {
  window.addEventListener('click', detectWidthChange)
  calendarApi.value = calendar.value.getApi()
  updateCalendarData()
  calendarWrapperWidth = calendarWrapper.value.clientWidth
  nextTick(() => {
    calendarApi.value?.updateSize()
  })
})

onUnmounted(() => {
  window.removeEventListener('click', detectWidthChange)
})

const updateCalendarData = () => {
  currentCalendarView.value = calendarApi.value?.view.type
  if (currentCalendarView.value === 'dayGridMonth') {
    currentDateRange.value = moment(calendarApi.value.view.currentStart).format('MMMM YYYY')
  }
  else if (currentCalendarView.value === 'timeGridWeek') {
    const start = calendarApi.value.view.activeStart
    const end = calendarApi.value.view.activeEnd
    currentDateRange.value = `${moment(start).format('MMM D')} – ${moment(end).subtract(1, 'days').format('D, YYYY')}`
  }
}

const detectWidthChange = () => {
  setTimeout(() => {
    if (calendarWrapper.value !== null && calendarWrapper.value?.clientWidth !== calendarWrapperWidth) {
      calendarApi.value?.updateSize()
      calendarWrapperWidth = calendarWrapper.value?.clientWidth
    }
  }, 300)
}

const normalizeEvent = ({ playlistId, startWeeklyWindowTimeMs, endWeeklyWindowTimeMs, type, startDate, endDate }) => {
  return {
    playlistId,
    startWeeklyWindowTimeMs,
    endWeeklyWindowTimeMs,
    type,
    startDate,
    endDate
  }
}

const handleScheduleUpdate = async (elements) => {
  const payload = {
    id: scheduleId.value,
    input: {
      data: {
        elements
      }
    }
  }
  return store.dispatch('groups/updateCurrentGroupSchedule', payload).then(() => {
    success()
  })
}

const createEvent = () => {
  const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent)), {
    ...eventToDto(newEvent),
    type: DEFAULT_EVENT_TYPE
  }]
  handleScheduleUpdate(elements)
}

const removeEvent = (index) => {
  const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent))]
  elements.splice(index, 1)

  handleScheduleUpdate(elements)
}

const handleEventChange = async (info) => {
  const { startStr: startDate, endStr: endDate, id } = info.event
  const event = eventToDto({ startDate, endDate })
  const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent))]
  elements[id] = { ...elements[id], ...event }
  return handleScheduleUpdate(elements).catch(() => {
    info.revert()
  })
}

const handleEventDragStop = (info) => {
  let { startStr, endStr } = info.event
  if (info.event.allDay) {
    endStr = moment(startStr).add(1, 'day').format('YYYY-MM-DD')
    info.event.setEnd(endStr)
  }
  const events = calendarApi.value?.getEvents().filter(event => event.id !== info.event.id)
  if (isAnOverlapEvent(startStr, endStr, events)) {
    error(t('components.calendarPage.eventsOverlapError'))
    info.revert()
    return
  }
  return handleEventChange(info)
}

const handleEventResizeStop = (info) => {
  return handleEventChange(info)
}

const goToToday = () => {
  calendarApi.value.today()
  updateCalendarData()
}

const goToPrev = () => {
  calendarApi.value.prev()
  updateCalendarData()
}

const goToNext = () => {
  calendarApi.value.next()
  updateCalendarData()
}

const handleViewChange = (view) => {
  calendarApi.value.changeView(view)
  updateCalendarData()
}

const handleNewEventPlaylistSelect = (value) => {
  newEvent.playlistId = value
}

const onNewEventCreate = () => {
  showPlaylistSelectModal.value = false
  createEvent()
}

const onNewEventCancel = () => {
  showPlaylistSelectModal.value = false
  calendarApi.value?.unselect()
  newEvent.startDate = null
  newEvent.endDate = null
  newEvent.startTime = null
  newEvent.endTime = null
  newEvent.playlistId = null
}

const handleNewEventTimeChange = ({ timeFrom, timeTo }) => {
  const startDate = moment(newEvent.startDate).set({
    hour: parseInt(timeFrom.split(':')[0], 10),
    minute: parseInt(timeFrom.split(':')[1], 10)
  }).toDate()
  const endDate = moment(newEvent.endDate).set({
    hour: parseInt(timeTo.split(':')[0], 10),
    minute: parseInt(timeTo.split(':')[1], 10)
  }).toDate()
  newEvent.startDate = startDate
  newEvent.endDate = endDate
}

const handleEventModalUpdate = ({ timeFrom, timeTo, event }) => {
  const calendarEvent = event.event
  const { startStr, endStr } = calendarEvent
  const startDate = moment(startStr)
  const endDate = moment(endStr);

  const newStartDate = startDate.set({
    hour: parseInt(timeFrom.split(':')[0], 10),
    minute: parseInt(timeFrom.split(':')[1], 10),
  }).toDate()

  const newEndDate = endDate.set({
    hour: parseInt(timeTo.split(':')[0], 10),
    minute: parseInt(timeTo.split(':')[1], 10),
  }).toDate()

  const events = calendarApi.value?.getEvents()?.filter(e => e.id !== event.eventId)

  if (isAnOverlapEvent(newStartDate, newEndDate, events)) {
    error(t('components.calendarPage.eventsOverlapError'))
    return
  }

  calendarEvent.setStart(newStartDate)
  calendarEvent.setEnd(newEndDate)

  const elements = [...cloneDeep(scheduleElements.value.map(normalizeEvent))]
  elements[event.eventId] = { ...elements[event.eventId], ...{ startDate: newStartDate, endDate: newEndDate } }
  handleScheduleUpdate(elements).catch((e) => {
    error(e.message)
    calendarEvent.setStart(startStr)
    calendarEvent.setEnd(endStr)
  })
}

const playlistFiltering = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const calendarOptions = computed(() => ({
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
    momentPlugin
  ],
  headerToolbar: null,
  dayHeaderContent: (args) => {
    if (args.view.type === 'dayGridMonth') {
      const day = moment().day(args.dow)
      return day.locale('en-US').format('ddd')
    } else {
      return moment(args.date).locale('en-US').format('D ddd')
    }
  },
  initialView: 'timeGridWeek',
  events: events.value,
  eventOverlap: function (stillEvent, movingEvent) {
    return stillEvent.display === 'background'
  },
  editable: true,
  height: '100%',
  nowIndicator: true,
  selectable: true,
  eventColor: '#00c792',
  selectAllow: function (info) {
    return !moment().isAfter(info.start)
  },
  eventAllow: function (dropInfo) {
    return dropInfo.start > new Date()
  },
  selectMirror: true,
  scrollTimeReset: false,
  scrollTime: '08:00:00',
  slotDuration: '00:15:00',
  snapDuration: '00:15:00',
  firstDay: 0,
  slotLabelInterval: {
    minutes: 30
  },
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: isAmPmFormat.value
  },
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: isAmPmFormat.value
  },
  dayMaxEvents: true,
  weekends: true,
  eventDidMount: function (info) {
    info.el.parentNode.style.zIndex = info.event.extendedProps.order
  },
  select: handleDateSelect,
  eventClick: handleEventClick,
  eventsSet: () => {},
  eventDrop: handleEventDragStop,
  eventResize: handleEventResizeStop
}))

watch([scheduleElementsFiltered, parentScheduleElements], ([a, b]) => {
  const parentElements = cloneDeep(b).map((s, index) => {
    return s.data?.elements.map((e) => ({
      ...e,
      background: true,
      index: (index + 1) * 2
    }))
  }).flat(1)
  events.value = mapElementsToEvents([...a.map(e => ({ ...e, index: 0 })), ...parentElements],true)
})

watchEffect(() => {
  store.dispatch('groups/getCurrentGroupSchedules')
})

watch(isAmPmFormat, (newValue) => {
  setIsAmPmFormatToLocalStorage(newValue)
})

</script>

<template>
  <a-layout-content style="background: #fff; height: 100%; overflow-x: auto; display: flex; flex-direction: column">
    <a-spin :spinning="currentGroupSchedulesLoading">
      <NewEventModal
        :is-am-pm="isAmPmFormat"
        :new-event="newEvent"
        :playlists-options="playlistsOptions"
        :open="showPlaylistSelectModal"
        @create="onNewEventCreate"
        @update-time="handleNewEventTimeChange"
        @update-playlist-id="handleNewEventPlaylistSelect"
        @cancel="onNewEventCancel"
      />
      <a-page-header
        style="position: sticky; z-index: 3; top: 0;"
      >
        <template #title>
          <div style="display: flex; gap: 8px; align-items: center">
            {{ currentDateRange }}
            <a-segmented
              size="small"
              style="font-weight: normal;"
              :value="currentCalendarView"
              :options="calendarViewOptions"
              @change="handleViewChange"
            />
          </div>
        </template>
        <template #extra>
          <div style="display: flex; gap: 8px; align-items: center;">
            <a-button
              size="small"
              @click="goToToday"
            >
              {{ $t('components.calendarPage.today') }}
            </a-button>
            <a-button
              size="small"
              @click="goToPrev"
            >
              <LeftOutlined />
            </a-button>
            <a-button
              size="small"
              @click="goToNext"
            >
              <RightOutlined />
            </a-button>
            <a-switch
              v-model:checked="isAmPmFormat"
              :checked-children="$t('components.calendarPage.ampmFormat')"
              :un-checked-children="$t('components.calendarPage.24HFormat')"
            />
          </div>
        </template>
        <CalendarPageSubtitle v-if="!isSmartGroup" />
        <a-select
          v-model:value="playlistsFilter"
          show-search
          mode="multiple"
          max-tag-count="responsive"
          style="margin-top: 24px; min-width: 300px;"
          :placeholder="$t('components.calendarPage.playlistFilterPlaceholder')"
          :options="playlistsFilterOptions"
          :filter-option="playlistFiltering"
        />
      </a-page-header>
      <div
        ref="calendarWrapper"
        class="schedule-calendar"
      >
        <CalendarEventModal
          v-model:visible="showCalendarEventModal"
          :is-am-pm="isAmPmFormat"
          :event="selectedEvent"
          @remove="removeEvent"
          @update="handleEventModalUpdate"
        />
        <FullCalendar
          ref="calendar"
          class="demo-app-calendar"
          :options="calendarOptions"
        >
          <template #eventContent="{timeText, event}">
            <b
              v-if="event.extendedProps.paused"
              style="color: rgba(0,0,0,0.3)"
            >{{ timeText }}</b>
            <b v-else>{{ timeText }}</b>
            <a-tooltip
              v-if="event.extendedProps.paused"
              :title="$t('components.calendarPage.pausedTooltipTitle')"
              placement="topLeft"
            >
              <i style="color: #000; font-weight: normal"><PauseCircleFilled
                style="margin-right: 2px;"
              /> {{ event.title }}</i>
            </a-tooltip>
            <i v-else>{{ event.title }}</i>
          </template>
        </FullCalendar>
      </div>
    </a-spin>
  </a-layout-content>
</template>

<style lang="less">
@import '../styles/variables';

.schedule-calendar {
  padding: 16px;
  flex: 1;
  .fc .fc-col-header-cell-cushion {
    word-spacing: 100vw;
    cursor: default;
    color: @text-color-secondary-dark;
    font-size: 12px;
    &::first-line {
      font-size: 14px;
    }
  }
  .fc {
    .fc-timegrid-col {
      &.fc-day-past {
        background: repeating-linear-gradient(
            -45deg,
            rgba(0, 0, 0, 0.015),
            rgba(0, 0, 0, 0.015) 5px,
            rgba(0, 0, 0, 0.03) 5px,
            rgba(0, 0, 0, 0.03) 10px
        )
      }
    }
    .fc-daygrid-day {
      .fc-daygrid-day-number {
        color: @text-color-secondary;
      }
      &.fc-day-today {

        .fc-daygrid-day-number {
          color: var(--ant-primary-color);
        }
      }
      &.fc-day-past {
        background: repeating-linear-gradient(
            -45deg,
            rgba(0, 0, 0, 0.02),
            rgba(0, 0, 0, 0.02) 5px,
            rgba(0, 0, 0, 0.04) 5px,
            rgba(0, 0, 0, 0.04) 10px
        )
      }
    }
  }
}
</style>
