<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash'
import { FolderFilled, RollbackOutlined, HomeOutlined } from '@ant-design/icons-vue'
import { error, formatDate, formatFileSize, getIconNameByMimetype } from '@/utils'
import { ACCEPTED_MIME_TYPES, MEDIA_TYPES, MEDIA_TYPES_ENUM } from '@/constants'
import { filesIcons } from '@/helpers/Icons'
import FixedFooter from '@/components/FixedFooter'
import { SlideAsset } from '@/helpers/Slides'
import StickyFooter from '@/components/StickyFooter.vue'

const COLUMNS = [
  {
    title: '',
    dataIndex: 'mimeType',
    align: 'center',
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    fixed: 'left',
    defaultSortOrder: 'descend'
  }, {
    title: 'fileName',
    dataIndex: 'name',
    key: 'fileName',
    resizable: true,
    ellipsis: true,
    width: 240,
    maxWidth: 800,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.name.localeCompare(b.name),
      multiple: 2
    }
  }, {
    title: 'modifiedDate',
    dataIndex: 'client_modified',
    key: 'modifiedDate',
    width: 200,
    minWidth: 100,
    maxWidth: 400,
    sorter: {
      compare: (a, b) => a.createdAtSeconds - b.createdAtSeconds
    }
  }, {
    title: 'size',
    dataIndex: 'size',
    key: 'size',
    width: 100,
    minWidth: 100,
    maxWidth: 300,
    sorter: {
      compare: (a, b) => a.size - b.size
    }
  }]

const SOURCE_TYPE = 'MICROSOFT_ACCOUNT'

const props = defineProps({
  microsoftAccountId: String,
  mediaTypes: {
    type: String,
    validator (value) {
      return MEDIA_TYPES.indexOf(value) !== -1
    }
  },
  selection: {
    type: String,
    validator (value) {
      return ['single', 'multiple', 'none'].indexOf(value) !== -1
    }
  },
  excludeHeadings: {
    type: Array,
    default: () => []
  }
})

const store = useStore()
const currentPath = ref([])
const items = ref([])
const selectedKeys = ref([])
const loading = ref(true)
const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])
const isBackDisabled = computed(() => !currentPath.value?.length || loading.value)
const path = computed(() => currentPath.value?.at(-1) || '')
const acceptedMimeTypes = props.mediaTypes ? ACCEPTED_MIME_TYPES[props.mediaTypes] : null
const durationForVideoIds = reactive({})

const isVideo = computed(() => props.mediaTypes?.includes(MEDIA_TYPES_ENUM.VIDEO))

onMounted(() => {
  props.microsoftAccountId && fetchFolderFiles()
})

const bindEntriesToState = (entries) => {
  const files = entries.map(e => {
    const icon = e.mimetype ? filesIcons[getIconNameByMimetype(e.mimetype)] : null
    return {
      key: e.id,
      id: e.id,
      name: e.name,
      icon,
      metadata: e.metadata,
      client_modified: e.lastModifiedDateTime,
      mimeType: e.objectType !== 'DRIVE_FILE' ? 'folder' : e.mimetype,
      disabled: acceptedMimeTypes && !acceptedMimeTypes.includes(e.mimetype),
      ...e
    }
  })
  items.value = files
}

const fetchFolderFiles = async () => {
  loading.value = true
  const data = await store.dispatch('media/fetchSharepointFiles', {
    microsoftSocialAccountId: props.microsoftAccountId,
    params: path.value?.params || {}
  }).catch(e => {
    error(e.message)
  })
  bindEntriesToState(data)
  loading.value = false
}

const openFolder = ({ id, objectType, name }) => {
  let key
  switch (objectType) {
    case 'DRIVE':
      key = 'driveId'
      break
    case 'DRIVE_FOLDER':
      key = 'parentFolderId'
      break
    case 'SITE':
      key = 'parentSiteId'
      break
  }

  const existingIndex = currentPath.value.findIndex(item => item.params?.[key] === id)
  if (existingIndex !== -1) {
    currentPath.value = currentPath.value.slice(0, existingIndex + 1)
  } else {
    currentPath.value.push({ name, params: { [key]: id } })
  }

  fetchFolderFiles()
}

const goBack = () => {
  currentPath.value.pop()
  fetchFolderFiles()
}

const goToFolder = (p) => {
  if (!p) {
    if (currentPath.value.length === 0) return
    currentPath.value = []
    fetchFolderFiles()
    return
  }
  if (path.value === p) return
  currentPath.value = currentPath.value.slice(0, currentPath.value.indexOf(p) + 1)
  fetchFolderFiles()
}

const onSelectChange = (selection) => {
  selectedKeys.value = cloneDeep(selection)
  selectMedia(selection)
}

const selectMedia = async (selection = []) => {
  store.commit('media/SET_SELECTED_MEDIA', [])
  const selected = await Promise.all(
    selection.map(async id => {
      const { name, metadata } = items.value.find(f => f.id === id) || {}
      let duration = metadata?.duration ? metadata.duration / 1000 : null

      return new SlideAsset({
        id,
        name,
        sourceType: SOURCE_TYPE,
        metadata: {
          duration
        },
        mediaReference: {
          socialMicrosoftAccountId: props.microsoftAccountId,
          microsoftMediaId: id,
          sourceType: SOURCE_TYPE
        }
      })
    })
  )
  store.commit('media/SET_SELECTED_MEDIA', selected)
}

const rowSelection = computed(() => {
  if (props.selection === 'multiple') {
    return {
      onChange: onSelectChange,
      selectedRowKeys: selectedKeys.value,
      getCheckboxProps: record => ({
        disabled: record.disabled
      })
    }
  }
  return null
})

const customRow = (record) => {
  if (props.selection === 'single') {
    return {
      onClick: () => {
        if (record.disabled) return
        selectedKeys.value = [record.id]
        selectMedia([record.id])
      }
    }
  } else if (props.selection === 'multiple') {
    return {
      onClick: () => {
        if (record.disabled) return
        if (!selectedKeys.value?.includes(record.id)) {
          selectedKeys.value.push(record.id)
        } else {
          selectedKeys.value = selectedKeys.value.filter(r => r !== record.id)
        }
        selectMedia(cloneDeep(selectedKeys.value))
      }
    }
  }
  return null
}

const rowClassName = (record) => {
  const className = props.selection === 'single' && !record.disabled ? 'selectable ' : ''
  return selectedKeys.value?.includes(record.id) ? className + 'ant-table-row-selected selected' : className
}

const deselectAll = () => {
  store.commit('media/CLEAR_SELECTED_MEDIA')
}

const handleResizeColumn = (w, col) => {
  col.width = w
}

watch(selectedMediaIds, (mediaIds) => {
  selectedKeys.value = mediaIds
})
</script>

<template>
  <a-layout-content style="overflow-x: auto">
    <a-space>
      <a-button
        :style="{margin: '0 0 0 16px'}"
        :disabled="isBackDisabled"
        @click="goBack"
      >
        <template #icon>
          <RollbackOutlined />
        </template>
        {{ $t('components.sharePointView.backButtonText') }}
      </a-button>
      <a-breadcrumb
        separator=">"
      >
        <a-tooltip :title="$t('components.sharePointView.home')">
          <a-breadcrumb-item
            href=""
            @click.stop.prevent="goToFolder(undefined)"
          >
            <HomeOutlined />
          </a-breadcrumb-item>
        </a-tooltip>
        <template
          v-for="(p, i) in currentPath"
          :key="i"
        >
          <a-breadcrumb-item>
            <a
              @click="goToFolder(p)"
            >
              {{ p.name }}
            </a>
          </a-breadcrumb-item>
        </template>
      </a-breadcrumb>
    </a-space>
    <a-table
      class="storage-table table-padded"
      size="small"
      position="topCenter"
      :scroll="{ x: 600, y: 500 }"
      :loading="loading"
      :data-source="items"
      :columns="COLUMNS"
      :row-selection="rowSelection"
      :custom-row="customRow"
      :row-class-name="rowClassName"
      @resize-column="handleResizeColumn"
    >
      <template #headerCell="{ column }">
        <template v-if="column.key">
          {{ $t(`components.sharePointView.${column.key}`) }}
        </template>
      </template>
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.dataIndex === 'mimeType'">
          <a-tooltip v-if="text === 'folder'">
            <template #title>
              {{ $t('components.sharePointView.goToFolderTooltipTitle') }}
            </template>
            <FolderFilled
              style="font-size: 18px; cursor: pointer"
              @click.prevent.stop="openFolder(record)"
            />
          </a-tooltip>
          <component
            :is="record.icon"
            v-else
          />
        </template>
        <template v-else-if="column.dataIndex === 'name'">
          <div
            class="img-spacer"
          >
            <template v-if="record.mimeType === 'folder'">
              <a
                class="table-link"
                @click.prevent.stop="openFolder(record)"
              >
                {{ text }}
              </a>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'size'">
          {{ formatFileSize(text) }}
        </template>
        <template v-else-if="column.dataIndex === 'client_modified'">
          {{ formatDate(text) }}
        </template>
        <template v-else>
          {{ text }}
        </template>
      </template>
    </a-table>
  </a-layout-content>
  <StickyFooter v-if="selectedMediaIds?.length">
    <FixedFooter>
      <template #left>
        {{ selectedMediaIds.length }} {{ selectedMediaIds.length === 1 ? 'File' : 'Files' }} Selected
      </template>
      <a-button
        size="small"
        @click="deselectAll"
      >
        {{ $t('components.sharePointView.deselectAllButtonText') }}
      </a-button>
    </FixedFooter>
  </StickyFooter>
</template>

<style lang="less" scoped>
  .img-spacer {
    height: 40px;
    display: flex;
    align-items: center;
  }
</style>
