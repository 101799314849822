import { filters } from "fabric"

export const createFabricFilter = (filterConfig) => {
  const { name, options } = filterConfig

  switch (name.toLowerCase()) {
    case "grayscale":
      return new filters.Grayscale()
    case "invert":
      return new filters.Invert()
    case "sepia":
      return new filters.Sepia()
    case "brightness":
      return new filters.Brightness({
        brightness: (options.brightness?.current || 50) / 100
      })
    case "noise":
      return new filters.Noise({
        noise: options.noise.current || 100
      })
    case "pixelate":
      return new filters.Pixelate({
        blocksize: options.blocksize.current
      })
    case "contrast":
      return new filters.Contrast({
        contrast: options.contrast?.current || 0
      })
    case "gamma":
      return new filters.Gamma({
        gamma: options.gamma?.current || [1, 1, 1]
      })
    case "tint":
      return Object.assign(new filters.BlendColor({
        color: options.color.current,
        mode: "tint",
        alpha: options.opacity?.current || 0.5
      }), { customType: "tint" });
    case "multiply":
      return Object.assign(new filters.BlendColor({
        color: options.color.current,
        mode: "multiply",
        alpha: options.alpha?.current || 0.5
      }), { customType: "multiply" });
    case "blend":
      return Object.assign(new filters.BlendColor({
        color: options.color.current,
        mode: options.mode.current,
        alpha: options.alpha.current
      }), { customType: "blend" });
    case "removecolor":
      return new filters.RemoveColor({
        color: options.color?.current || "#FFFFFF",
        distance: options.distance?.current || 0.1
      })
    case "colormatrix":
      return new filters.ColorMatrix({
        matrix: [
          1, 0, 0, 0, 0,
          0, 1, 0, 0, 0,
          0, 0, 1, 0, 0,
          0, 0, 0, options.threshold?.current / 100 || 0.5, 0
        ]
      })
    default:
      console.warn(`Unknown filter name: ${name}`)
      return null
  }
}
