<script setup>
import { TwitterCircleFilled } from '@ant-design/icons-vue'
import { computed, reactive, watch, watchEffect } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { getSocialHashtagsOrMentionsOnChange, SocialTwitterSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import { MAX_SOCIAL_ACCOUNTS_IN_SLIDE } from '@/constants'
import AddSocialAccountButton from '@/components/AddSocialAccountButton'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: SocialTwitterSlide,
  groupId: String,
  disabled: Boolean,
  zoning: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, SocialTwitterSlide, {
  setMute: props.zoning,
  disableOnNewSlide: true
})

const store = useStore()
const {
  hashtags,
  feedType,
  includeOwnTweets,
  showLikesCount,
  socialTwitterAccountIds
} = slide.value.slideData
const state = reactive({
  accounts: [...socialTwitterAccountIds || []],
  hashtags: hashtags.map(h => h.replace('#', '')),
  feedType,
  includeOwnTweets,
  showLikesCount
})
const twitterAccounts = computed(() => store.getters['social/socialTwitterAccounts'].map(toStandard))
const isValid = computed(() => (state.hashtags?.length > 0 || state.includeOwnTweets) && state.accounts?.length > 0)

const onHashtagsChange = (value) => {
  state.hashtags = getSocialHashtagsOrMentionsOnChange(value)
  updateSlideData({
    hashtags: state.hashtags.map(h => `#${h}`),
  })
  updateSlide()
}

const handleSocialAccountsChange = (socialAccounts) => {
  state.accounts = state.accounts.slice(0, MAX_SOCIAL_ACCOUNTS_IN_SLIDE)
}

watch(() => state.accounts, (value) => {
  if (!value?.length) {
    return
  }
  const twitterAccountsIds = twitterAccounts.value.filter(a => state.accounts.includes(a.id)).map(a => a.id)
  updateSlideData({
    socialTwitterAccountIds: twitterAccountsIds.length ? twitterAccountsIds : null
  })
  updateSlide()
})

watch(() => state.feedType, (value) => {
  updateSlideData({
    feedType: value
  })
  updateSlide()
})

watch(() => state.includeOwnTweets, (value) => {
  updateSlideData({
    includeOwnTweets: value
  })
  updateSlide()
})

watch(() => state.showLikesCount, (value) => {
  updateSlideData({
    showLikesCount: value
  })
  updateSlide()
})

watch(() => twitterAccounts.value, (value, oldValue) => {
  if (value?.length - oldValue?.length === 1) {
    const newSocialAccount = value?.find(({ id }) => !oldValue.some(({ id: oldId }) => oldId === id))
    newSocialAccount && state.accounts?.push(newSocialAccount.id)
  }
})

watchEffect(() => {
  toggleDisabled(!isValid.value)
})

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.socialTwitter.slideStep1.subTitle')"
    :title="$t('components.slides.socialTwitter.slideStep1.title')"
  >
    <template #extra>
      <AddSocialAccountButton :socials="['twitter']" />
    </template>
    <a-select
      v-model:value="state.accounts"
      :placeholder="$t('components.slides.socialTwitter.slideStep1.accountSelectPlaceholder')"
      mode="multiple"
      style="width: 100%"
      @change="handleSocialAccountsChange"
    >
      <a-select-opt-group v-if="twitterAccounts.length > 0">
        <template #label>
          <span>
            <TwitterCircleFilled />
            Twitter
          </span>
        </template>
        <a-select-option
          v-for="account in twitterAccounts"
          :key="account.id"
          :value="account.id"
        >
          <div>
            @ {{ account.name }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.socialTwitter.slideStep2.subTitle')"
    :title="$t('components.slides.socialTwitter.slideStep2.title')"
  >
    <a-select
      :placeholder="$t('components.slides.socialTwitter.slideStep2.tagsInputPlaceholder')"
      :value="state.hashtags"
      mode="tags"
      style="width: 100%"
      @change="onHashtagsChange"
    />
  </SlideStep>
  <SlideStep :title="$t('components.slides.socialTwitter.slideStep3.title')">
    <a-radio-group v-model:value="state.feedType">
      <a-radio value="TOP">
        {{ $t('components.slides.socialTwitter.slideStep3.popularFirst') }}
      </a-radio>
      <a-radio value="RECENT">
        {{ $t('components.slides.socialTwitter.slideStep3.recentFirst') }}
      </a-radio>
    </a-radio-group>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialTwitter.slideStep4.title')"
    :sub-title="$t('components.slides.socialTwitter.slideStep4.subTitle')"
    multiline
  >
    <a-checkbox v-model:checked="state.includeOwnTweets">
      {{ $t('components.slides.socialTwitter.slideStep4.includeOwnPosts') }}
    </a-checkbox>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.socialTwitter.slideStep5.title')"
    :sub-title="$t('components.slides.socialTwitter.slideStep5.subTitle')"
    multiline
    no-border
  >
    <a-checkbox v-model:checked="state.showLikesCount">
      {{ $t('components.slides.socialTwitter.slideStep5.showLikesCount') }}
    </a-checkbox>
  </SlideStep>
</template>

<style scoped>

</style>
