import { ref } from 'vue'

/**
 * @typedef {Object} SlideSettings
 * @property {boolean} [setMute] - If slide is in zoning mode (optional)
 * @property {boolean} [disableOnNewSlide] - If slide should be disabled on new slide (optional)
 */

const defaultSettings = {
  isZoning: false,
  disableOnNewSlide: false
}

/**
 * @param {Function} emit - emitter
 * @param {Object} slideObj - Slide object
 * @param {Function} slideClass - New slide class
 * @param {SlideSettings} [settings={}] - Slide settings
 */

export function useSlideControls(emit, slideObj, slideClass, settings = {} ) {
  const { setMute, disableOnNewSlide } = { ...defaultSettings, ...settings }
  const slide = ref(slideObj || new slideClass())
  const isNewSlide = !slideObj

  const updateSlide = () => {
    emit('update-slide', slide.value)
  }

  const toggleDisabled = (status) => {
    emit('update:disabled', status)
  }

  const updateSlideData = (data) => {
    slide.value.updateSlideData(data)
  }

  if (setMute) {
    if (slide.value.isMutable) {
      slide.value.setMute(true)
    }
  }

  if (disableOnNewSlide && isNewSlide) {
    toggleDisabled(true)
  }

  emit('init-name', {
    name: slide.value.name,
    defaultName: slide.value.getDefaultName()
  })

  return {
    isNewSlide: !slideObj,
    slide,
    updateSlide,
    toggleDisabled,
    updateSlideData
  }
}
