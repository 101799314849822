<script setup>
import BackgroundTab from './tabs/BackgroundTab/BackgroundTab.vue'
import FontsTab from '@/components/slideModal/CustomTemplateEditor/components/tabs/FontsTab.vue'
import FiltersTab from '@/components/slideModal/CustomTemplateEditor/components/tabs/FiltersTab.vue'
import TemplatesTab from '@/components/slideModal/CustomTemplateEditor/components/tabs/TemplatesTab.vue'
import ImagesTab from '@/components/slideModal/CustomTemplateEditor/components/tabs/ImagesTab/ImagesTab.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const activeTab = computed(() => store.getters['customTemplate/activeTab'])

const TABS_MAP = {
  'filters': FiltersTab,
  'background': BackgroundTab,
  'fonts': FontsTab,
  'templates': TemplatesTab,
  'images': ImagesTab
}

const SIDER_WIDTH = 300
</script>

<template>
  <a-layout-sider
    theme="light"
    :width="SIDER_WIDTH"
  >
    <a-layout class="full-height">
      <a-layout-header
        class="dark"
        style="height: 48px; line-height: 48px; padding: 0 16px; color: #fff"
      >
        {{ $t(`components.customTemplateEditor.tabs.${activeTab}`) }}
      </a-layout-header>
      <a-layout-content style="padding: 16px; overflow: hidden; height: 100%;">
        <div style="display: flex; flex-direction: column; gap: 16px; overflow: hidden; height: 100%;">
          <component :is="TABS_MAP[activeTab]" />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout-sider>
</template>

<style scoped lang="less">

</style>
