<script setup>

import { computed, reactive, ref, watch } from 'vue'
import { FontColorsOutlined, AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined  } from '@ant-design/icons-vue'
import { ColorPicker } from 'vue3-colorpicker'
import { useStore } from 'vuex'

const ALIGNMENT_MENU = [
  {
    label: 'Left',
    value: 'left',
    icon: AlignLeftOutlined
  },
  {
    label: 'Center',
    value: 'center',
    icon: AlignCenterOutlined
  },
  {
    label: 'Right',
    value: 'right',
    icon: AlignRightOutlined
  }
]

const STYLES_PROPERTIES = {
  bold: {
    name: 'fontWeight',
    value: (bold) => bold ? 'bold' : 'normal'
  },
  italic: {
    name: 'fontStyle',
    value: (italic) => italic ? 'italic' : 'normal'
  },
  underline: {
    name: 'underline',
    value: (underline) => underline
  }
}

const MIN_FONT_SIZE = 5
const MAX_FONT_SIZE = 200

const store = useStore()
const fontColor = ref('#000000')
const textAlignment = ref('left')
const fontSize = ref('40pt')
const fontSizeRef = ref(null)

const styles = reactive({
  bold: false,
  italic: false,
  underline: false
})

const activeObject = computed(() => store.getters['customTemplate/activeObject'])
const currentAlignment = computed(() => {
  return ALIGNMENT_MENU.find((alignment) => alignment.value === textAlignment.value)
})

const toggleProperty = (key) => {
  styles[key] = !styles[key]
  store.dispatch('customTemplate/setActiveObjectProperty', {
    name: STYLES_PROPERTIES[key].name,
    value: STYLES_PROPERTIES[key].value(styles[key])
  })
}


const changeColor = (color) => {
  fontColor.value = color
  store.dispatch('customTemplate/setActiveObjectProperty', {
    name: 'fill',
    value: color
  })
}

const handleAlignmentChange = (alignment) => {
  textAlignment.value = alignment
  store.dispatch('customTemplate/setActiveObjectProperty', {
    name: 'textAlign',
    value: alignment
  })
}

const handleColorChange = (color) => {
  changeColor(color)
}

const disableMinus = computed(() => {
  return parseInt(fontSize.value, 10) <= MIN_FONT_SIZE
})

const disablePlus = computed(() => {
  return parseInt(fontSize.value, 10) >= MAX_FONT_SIZE
})

const increaseFontSize = (event) => {
  const step = event.shiftKey ? 10 : 1
  let value = parseInt(fontSize.value, 10) || MIN_FONT_SIZE;
  value = Math.min(value + step, MAX_FONT_SIZE)
  fontSize.value = `${value}pt`
  setFontSize(value)
}

const decreaseFontSize = (event) => {
  const step = event.shiftKey ? 10 : 1
  let value = parseInt(fontSize.value, 10) || MIN_FONT_SIZE
  value = Math.max(value - step, MIN_FONT_SIZE)
  fontSize.value = `${value}pt`
  setFontSize(value)
}

const handleFontSizeInput = (e) => {
  const value = e.target.value.replace(/\D/g, '')
  fontSize.value = value
}

const handleFontSizeBlur = () => {
  if (fontSize.value.trim() === '') {
    fontSize.value = ''
  } else {
    let value = parseInt(fontSize.value, 10) || MIN_FONT_SIZE
    fontSize.value = `${Math.max(MIN_FONT_SIZE, Math.min(MAX_FONT_SIZE, value))}pt`
  }
  setFontSize(fontSize.value)
}

const setFontSize = (value) => {
  store.dispatch('customTemplate/setActiveObjectProperty', {
    name: 'fontSize',
    value
  })
}

const initState = () => {
  if (!activeObject.value) return
  fontColor.value = activeObject.value?.fill
  textAlignment.value = activeObject.value?.textAlign
  fontSize.value = activeObject.value?.fontSize + 'pt'
  styles.bold = activeObject.value?.fontWeight === 'bold'
  styles.italic = activeObject.value?.fontStyle === 'italic'
  styles.underline = activeObject.value?.underline
}

initState()

watch(()=> activeObject.value, () => {
  initState()
})

</script>

<template>
  <div id="text-controls">
    <a-input-group
      compact
      style="width: 150px; display: flex;"
    >
      <a-button
        :disabled="disableMinus"
        @click="decreaseFontSize"
      >
        -
      </a-button>
      <a-input
        ref="fontSizeRef"
        v-model:value="fontSize"
        placeholder="Size"
        style="text-align: center;"
        @input="handleFontSizeInput"
        @blur="handleFontSizeBlur"
        @keypress.enter.prevent="fontSizeRef.blur()"
        @keydown.delete.backspace.stop
      />
      <a-button
        :disabled="disablePlus"
        @click="increaseFontSize"
      >
        +
      </a-button>
    </a-input-group>
    <a-dropdown trigger="['click']">
      <template #overlay>
        <a-menu>
          <a-menu-item
            v-for="alignment in ALIGNMENT_MENU"
            :key="alignment.value"
            @click="handleAlignmentChange(alignment.value)"
          >
            <template #icon>
              <component :is="alignment.icon" />
            </template>
            {{ alignment.label }}
          </a-menu-item>
        </a-menu>
      </template>
      <a-tooltip :title="`Alignment ${currentAlignment.label}`">
        <a-button style="width: 32px; padding: 0; text-align: center">
          <template #icon>
            <component :is="currentAlignment.icon" />
          </template>
        </a-button>
      </a-tooltip>
    </a-dropdown>
    <a-dropdown trigger="['click']">
      <template #overlay>
        <ColorPicker
          v-model="fontColor"
          disable-alpha
          picker-type="chrome"
          is-widget
          format="hex"
          @pure-color-change="handleColorChange"
        />
      </template>
      <a-tooltip title="Color">
        <a-button
          style="width: 32px; padding: 0; text-align: center;"
          :style="{
            color: fontColor
          }"
        >
          <template #icon>
            <FontColorsOutlined />
          </template>
        </a-button>
      </a-tooltip>
    </a-dropdown>
    <a-tooltip title="Bold">
      <a-button
        :type="styles.bold ? 'primary' : 'text'"
        style="width: 32px; padding: 0; text-align: center"
        @click="toggleProperty('bold')"
      >
        <b>B</b>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Italic">
      <a-button
        :type="styles.italic ? 'primary' : 'text'"
        style="width: 32px; padding: 0; text-align: center"
        @click="toggleProperty('italic')"
      >
        <i>I</i>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Underline">
      <a-button
        :type="styles.underline ? 'primary' : 'text'"
        style="width: 32px; padding: 0; text-align: center"
        @click="toggleProperty('underline')"
      >
        <u>U</u>
      </a-button>
    </a-tooltip>
  </div>
</template>

<style scoped lang="less">
#text-controls{
  display: flex;
  gap: 8px;
}
</style>
