import ua from './ua.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import pt from './pt.json'
import pl from './pl.json'
import de from './de.json'
import ja from './ja.json'
import nl from './nl.json'
import zh from './zh.json'
import no from './no.json'
export default {
  en,
  ua,
  es,
  fr,
  it,
  pt,
  pl,
  de,
  ja,
  nl,
  zh,
  no
}
