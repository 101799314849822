<script setup>
import { onMounted, ref } from 'vue'
import { isURL } from 'validator'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { DEFAULT_PROTOCOL } from '@/constants'
import { normalizeUrl } from '@/utils'
import { WebsiteSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: WebsiteSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, WebsiteSlide, {disableOnNewSlide: true})

const inputRef = ref(null)
const websiteUrl = ref(normalizeUrl(slide.value.slideData.url))

onMounted(() => {
  inputRef.value && inputRef.value.focus()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}

const handleWebsiteUrlChange = () => {
  const stripUrl = normalizeUrl(websiteUrl.value)
  if (!isURL(DEFAULT_PROTOCOL + stripUrl)) {
    toggleDisabled(true)
    return true
  }
  websiteUrl.value = stripUrl
  updateSlideData({
    url: DEFAULT_PROTOCOL + stripUrl
  })
  updateSlide()
  toggleDisabled(false)
}

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.web.slideStep1.subTitle')"
    :title="$t('components.slides.web.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-input
        ref="inputRef"
        v-model:value="websiteUrl"
        :addon-before="DEFAULT_PROTOCOL"
        :placeholder="$t('components.slides.web.slideStep1.websiteUrlPlaceholder')"
        @change="handleWebsiteUrlChange"
      />
    </a-form>
  </SlideStep>
</template>

<style lang="less">

</style>
