<script setup>
import { ColorPicker } from 'vue3-colorpicker'
import ImagesList from '@/components/slideModal/CustomTemplateEditor/components/ImagesList.vue'
import TabWrapper from '@/components/slideModal/CustomTemplateEditor/components/TabWrapper.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const gradientsList = computed(() => store.getters['customTemplate/gradients'])

const handleGradientChange = (item) => {
  store.dispatch('customTemplate/setCanvasBackgroundImage', item.src)
}

const handleColorChange = (color) => {
  store.dispatch('customTemplate/setCanvasBackgroundColor', color)
}

</script>

<template>
  <TabWrapper>
    <ColorPicker
      picker-type="chrome"
      is-widget
      disable-alpha
      format="hex"
      @pure-color-change="handleColorChange"
    />
    <ImagesList
      :list="gradientsList"
      @click="handleGradientChange"
    />
  </TabWrapper>
</template>

<style scoped lang="less">
.tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  :deep(.vc-colorpicker) {
    width: auto;
    .vc-display {
      :nth-child(2) {
        :nth-child(4) {
          display: none;
        }
      }
      .vc-input-toggle {
        font-size: 9px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
