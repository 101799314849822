<script setup>
import { computed, inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { error, formatDate, formatFileSize, success } from '@/utils'
import {
  DeleteOutlined,
  ExportOutlined,
  FileTextOutlined,
  FolderFilled,
  PictureOutlined,
  RollbackOutlined,
  YoutubeOutlined,
  CalendarOutlined, EditOutlined, EyeOutlined, CheckOutlined
} from '@ant-design/icons-vue'
import { MEDIA_TYPES } from '@/constants'
import { useStore } from 'vuex'
import { SlideAsset } from '@/helpers/Slides'
import dayjs from 'dayjs'
import EditableTableCell from '@/components/EditableTableCell.vue'

const compareWithFolderPriority = (a, b, field, order = 'ascend') => {
  const isAFile = !a.isFolder
  const isBFile = !b.isFolder

  if (!isAFile && isBFile) return order === 'descend' ? 1 : -1
  if (isAFile && !isBFile) return order === 'descend' ? -1 : 1

  if (typeof a[field] === 'string' && typeof b[field] === 'string') {
    return a[field].localeCompare(b[field], undefined, { numeric: true, sensitivity: 'base' })
  } else {
    return (a[field] || 0) - (b[field] || 0)
  }
}

const SOURCE_TYPE = 'WORKSPACE_MEDIA'
const props = defineProps({
  padded: Boolean,
  filter: Object,
  visible: Boolean,
  showMove: {
    type: Boolean,
    default: false
  },
  groupId: {
    type: String,
    default: null
  },
  selection: {
    type: String,
        validator (value) {
      return ['single', 'multiple', 'none'].indexOf(value) !== -1
    },
    default: 'multiple'
  },
  selectOnUpload: {
    type: Boolean,
    default: false
  },
  folderSelectDisabled: Boolean,
      excludeHeadings: {
    type: Array,
    default: () => []
  },
  disableActions: Boolean,
  types: {
    type: Array,
        validator (value) {
      return value.every(t =>MEDIA_TYPES.includes(t))
    }
  }
})

const emit = defineEmits(['select', 'move', 'change-folder', 'update:parent-id', 'set-expiration'])

const store = useStore()
const currentPath = ref([])
const sortedInfo = ref({columnKey: null, order: null})
const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])
const subscribed = computed(() => store.getters['media/subscribed'])
const parentDirectoryMediaId = computed(() => store.getters['media/parentDirectoryMediaId'])
const uploadingQueue = computed(() => store.getters['media/uploadingQueue'])
const mediaList = computed(() => store.getters['media/mediaList'])
const loading = computed(() => store.getters['media/mediaListLoading'])
const selectedKeys = ref(selectedMediaIds.value || [])
const supportedTypes = ref(props.types ? [...props.types, 'DIRECTORY'] : [...MEDIA_TYPES])
const mediaRetryState = reactive({})
const editableData = reactive({})

const { deleteMedia, eventBus, updateMedia, updateDirectory } = inject('mediaService')

const tableMediaList = computed(() => {
  return mediaList.value?.filter(m => !m.tag)
    .filter(({ mimetype }) => supportedTypes.value?.includes(mimetype.split('_')[0]) ?? true)
    .filter(m => props.filter ? m[props.filter?.dataIndex].toLowerCase().includes(props.filter?.value.toLowerCase()) : true)
    .map(({ mimetype, generatedMedia, createdAt, type, size, id, isUploaded, ...other }) => {
      const isFolder = type === 'DIRECTORY'
      let progress = null
      if (uploadingQueue.value?.[id]) {
        progress = uploadingQueue.value[id].progress
        size = uploadingQueue.value[id].size
      }
      const thumbnailUrl = generatedMedia?.find(({ tag }) => tag === 'thumbnail')
      const thumbnail = thumbnailUrl?.url || null
      const isImage = mimetype.startsWith('IMAGE')
      const isVideo = mimetype.startsWith('VIDEO')
      const isDocument = mimetype.startsWith('DOCUMENT')
      const source = isImage ? generatedMedia?.find(({ tag }) => tag === 'content')?.url : thumbnail
      const createdAtSeconds = +moment(createdAt)
      const hasThumbnail = isFolder || (!!thumbnail && isUploaded)
      const disabled = isFolder && props.folderSelectDisabled
      return { key: id, type, createdAtSeconds, disabled, isFolder, size, progress, thumbnail, source, hasThumbnail, generatedMedia, createdAt, id, mimetype, isImage, isVideo, isDocument, ...other }
    }).sort((a, b) => {
      if (a.isFolder && !b.isFolder) return -1
      if (!a.isFolder && b.isFolder) return 1
      return a.name.localeCompare(b.name)
    })
})

const hasAllThumbnails = computed(() => {
  return tableMediaList.value?.every(({ hasThumbnail }) => hasThumbnail)
})

const rowSelection = computed(() => {
  if (props.selection === 'multiple') {
    return {
      onChange: onSelectChange,
      selectedRowKeys: selectedKeys.value,
      getCheckboxProps: record => ({
        disabled: record.disabled
      })
    }
  }
  return null
})

const columns = computed(() => {
  const sorted = sortedInfo.value || {columnKey: null, order: null}
  const order = sorted.order

  return [{
    title: '',
    dataIndex: 'mimetype',
    align: 'center',
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    fixed: 'left'
  }, {
    title: 'File Name',
    dataIndex: 'name',
    key: "name",
    resizable: true,
    ellipsis: true,
    width: 350,
    maxWidth: 800,
    fixed: 'left',
    sorter: {
      compare: (a, b) => compareWithFolderPriority(a, b, 'name', order)
    },
    sortOrder: sorted.columnKey === 'name' && sorted.order
  }, {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: "createdAt",
    width: 200,
    minWidth: 100,
    maxWidth: 400,
    sorter: {
      compare: (a, b) => compareWithFolderPriority(a, b, 'createdAtSeconds', order)
    },
    defaultSortOrder: 'descend',
    sortOrder: sorted.columnKey === 'createdAt' && sorted.order
  }, {
    title: 'Preview',
    key: "url",
    dataIndex: 'url',
    width: 100,
    minWidth: 100,
    maxWidth: 110
  }, {
    title: 'Expires',
    key: "autoDeleteOptions",
    dataIndex: 'autoDeleteOptions',
    width: 100,
    minWidth: 100,
    maxWidth: 110
  }, {
    title: 'Size',
    dataIndex: 'size',
    key: "size",
    width: 120,
    minWidth: 120,
    maxWidth: 300,
    sorter: {
      compare: (a, b) => compareWithFolderPriority(a, b, 'size', order)
    },
    sortOrder: sorted.columnKey === 'size' && sorted.order
  }, {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    width: 120,
    minWidth: 120,
    maxWidth: 120
  }].filter(c => !props.excludeHeadings.includes(c.dataIndex))
})

onMounted(() => {
  getMediaList()
  eventBus.on('fileUploaded', ({ id }) => {
    handleFileUpload(id)
  })
})

onUnmounted(() => {
  unsubscribeFromMediaList()
  eventBus.off('fileUploaded', handleFileUpload)
})

const getMediaList = async () => {
  return store.dispatch('media/getMediaList', props.groupId)
}

const handleFileUpload = (id) => {
  if (props.selectOnUpload) {
    if (tableMediaList.value.find(m => m.id === id)) {
      selectedKeys.value?.push(id)
      selectMedia(cloneDeep(selectedKeys.value))
    }
  }
}

const openExpirationModal = ({ mediaId, autoDeleteOptions = {} }) => {
  emit('set-expiration', {mediaId, autoDeleteOptions})
}

const openFolder = (id) => {
  currentPath.value.push(id)
  emit('change-folder')
  unsubscribeFromMediaList()
  setParentDirectoryMediaId()
}

const goBack = () => {
  currentPath.value.pop()
  unsubscribeFromMediaList()
  setParentDirectoryMediaId()
  emit('change-folder')
}

const setParentDirectoryMediaId = () => {
  store.commit('media/SET_PARENT_DIRECTORY_MEDIA_ID', currentPath.value.at(-1) || null)
}

const deleteFile = (id) => {
  deleteMedia(
    { payload: { id },
      success: () => {
        store.commit('media/REMOVE_SELECTED_MEDIA_BY_ID', id)
        success()
      },
      error
    })
}

const onSelectChange = (selection) => {
  selection = selection.filter(id=>{
    const media = tableMediaList.value.find(m => m.id === id)
    return !media.isVideo || (media.size && media.progress === null)
  })
  selectedKeys.value = cloneDeep(selection)
  selectMedia(selection)
}

const selectMedia = (selection = []) => {
  const selected = selection?.map(id => {
    const { thumbnail, name, metadata, source } = tableMediaList.value.find(m => m.id === id) || {}
    return new SlideAsset({
      id,
      name,
      thumbnail,
      metadata,
      source,
      mediaReference: {
        sourceType: SOURCE_TYPE,
        workspaceMediaId: id
      }
    })
  })
  store.commit('media/SET_SELECTED_MEDIA', selected)
}

const customRow = (record) => {
  if (props.selection === 'single') {
    return {
      onClick: () => {
        if (record.isFolder || (record.isVideo && (!record.size || record.progress !== null))) return
        selectedKeys.value = [record.id]
        selectMedia([record.id])
      }
    }
  } else if (props.selection === 'multiple') {
    return {
      onClick: () => {
        if (record.isFolder || (record.isVideo && (!record.size || record.progress !== null))) return
        if (!selectedKeys.value?.includes(record.id)) {
          selectedKeys.value.push(record.id)
        } else {
          selectedKeys.value = selectedKeys.value.filter(r => r !== record.id)
        }
        selectMedia(cloneDeep(selectedKeys.value))
      }
    }
  }
  return null
}

const rowClassName = (record) => {
  const className = (props.selection === 'single' || props.selection === 'multiple') && !record.isFolder ? 'selectable ' : ''
  return selectedKeys.value?.includes(record.id) ? className + 'ant-table-row-selected selected' : className
}

const subscribeToMediaList = () => {
  if (subscribed.value) return
  return store.dispatch('media/subscribeToMediaList', parentDirectoryMediaId.value)
}

const unsubscribeFromMediaList = () => {
  if (!subscribed.value) return
  return store.commit('media/UNSUBSCRIBE_FROM_MEDIA')
}

const formatDayJSDate = (date) => {
  return dayjs(date).utc().format('MMM DD YYYY')
}

const handleFileMove = (id) => {
  selectMedia([id])
  emit('move')
}

const handleTableChange = (pagination, filters, sorter) => {
  sortedInfo.value = sorter.order ? sorter : { columnKey: null, order: null };
}

const handleImageError = (record, maxRetries = 3, delay = 500) => {
  if (!mediaRetryState[record.id]) {
    mediaRetryState[record.id] = {
      retryCount: 0,
      key: record.id
    }
  }
  const state = mediaRetryState[record.id];
  if (state.retryCount < maxRetries) {
    state.retryCount += 1;
    setTimeout(() => {
      state.key = `${record.id}-${state.retryCount}`
    }, delay * state.retryCount)
  }
}

const handleResizeColumn = (w, col) => {
  col.width = w
}

const handleNameChange = ({id, isFolder}) => {
  const newName = editableData[id]
  if (newName) {
    const action = isFolder ? updateDirectory : updateMedia
    action({ id, input: { name: newName } })
    delete editableData[id]
  }
}

const startNameEdit = id => {
  editableData[id] = tableMediaList.value.find(item => id === item.id)?.name
}

watch(hasAllThumbnails, (value) => {
  if (!value) {
    subscribeToMediaList()
  } else {
    unsubscribeFromMediaList()
  }
})

watch(loading, (value) => {
  !value && !hasAllThumbnails.value && !subscribed.value && subscribeToMediaList()
})

watch(selectedMediaIds, (mediaIds) => {
  selectedKeys.value = mediaIds
})

watch(parentDirectoryMediaId, () => {
  getMediaList()
})

watch(() => props.groupId, () => {
  selectedKeys.value = []
  currentPath.value = []
  setParentDirectoryMediaId()
  unsubscribeFromMediaList()
  getMediaList()
})

</script>

<template>
  <a-button
    :disabled="!parentDirectoryMediaId"
    :style="{margin: padded ? '0 0 0 16px': '0 0 16px 0'}"
    @click="goBack"
  >
    <template #icon>
      <RollbackOutlined />
    </template>
    {{ $t('components.mediaTable.backButtonText') }}
  </a-button>
  <a-table
    class="storage-table"
    size="small"
    :scroll="{ x: 600 }"
    :loading="loading"
    :class="{'table-padded':padded}"
    :data-source="tableMediaList"
    :columns="columns"
    :row-selection="rowSelection"
    :custom-row="customRow"
    :row-class-name="rowClassName"
    @change="handleTableChange"
    @resize-column="handleResizeColumn"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key">
        {{ $t(`components.mediaTable.${column.key}`) }}
      </template>
    </template>
    <template #bodyCell="{ column, record, text }">
      <template v-if="column.dataIndex === 'actions' && record.mimetype !== 'BACK'">
        <div class="actions">
          <a-tooltip>
            <template #title>
              {{ $t('components.mediaTable.expirationSettingsTooltipTitle') }}
            </template>
            <a-button
              @click.stop="openExpirationModal({ mediaId: record.id, autoDeleteOptions: record.autoDeleteOptions })"
            >
              <template #icon>
                <CalendarOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-tooltip v-if="showMove">
            <template #title>
              {{ $t('components.mediaTable.moveTooltipTitle') }}
            </template>
            <a-button
              @click.stop="handleFileMove(record.id)"
            >
              <template #icon>
                <ExportOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-tooltip>
            <template #title>
              {{ $t('components.mediaTable.deleteTooltipTitle') }}
            </template>
            <a-popconfirm
              placement="left"
              :title="$t(`components.mediaTable.${record.isFolder ? 'popConfirmDeleteFolder' : 'popConfirmDeleteFile'}`)"
              :ok-text="$t('components.mediaTable.popConfirmOkText')"
              :cancel-text="$t('components.mediaTable.popConfirmCancelText')"
              @confirm="deleteFile(record.id)"
            >
              <a-button @click.stop>
                <template #icon>
                  <DeleteOutlined style="font-size: 12px" />
                </template>
              </a-button>
            </a-popconfirm>
          </a-tooltip>
        </div>
      </template>
      <template v-if="column.dataIndex === 'createdAt'">
        {{ formatDate(text) }}
      </template>
      <template v-if="column.dataIndex === 'autoDeleteOptions'">
        {{ text?.deleteAt ? formatDayJSDate(text.deleteAt) : $t('components.mediaTable.noExpiration') }}
      </template>
      <template v-if="column.dataIndex === 'size'">
        <span v-if="record.progress !== null">{{ text ? `${formatFileSize(text)} - ` : '' }} {{ record.progress }}%</span>
        <span v-else-if="!text && !record.isFolder">Processing</span>
        <span v-else>{{ formatFileSize(text) }}</span>
      </template>
      <template v-if="column.key === 'name'">
        <EditableTableCell
          v-if="!disableActions"
          v-model:name="editableData[record.id]"
          @start-edit="startNameEdit(record.id)"
          @cancel="delete editableData[record.id]"
          @save="handleNameChange({id: record.id, isFolder: record.isFolder})"
        >
          <template #text>
            <template v-if="record.isFolder">
              <a
                class="table-link"
                @click.prevent.stop="openFolder(record.id)"
              >
                {{ text }}
              </a>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </EditableTableCell>
        <span v-else>
          {{ text }}
        </span>
      </template>
      <template v-if="column.dataIndex === 'url'">
        <a-image
          v-if="!record.isFolder && record.hasThumbnail"
          :key="mediaRetryState[record.id]?.key || record.id"
          :height="40"
          :src="record.thumbnail"
          :preview="{src: record.source}"
          @error="handleImageError(record, 5)"
          @click.stop.prevent
        />
        <a-spin
          v-else-if="!record.isFolder && !record.hasThumbnail"
          spinning
        >
          <div class="img-spacer" />
        </a-spin>
        <div
          v-else
          class="img-spacer"
        />
      </template>
      <template v-if="column.dataIndex === 'mimetype'">
        <span />
        <a-tooltip v-if="record.mimetype === 'DIRECTORY'">
          <template #title>
            Go to folder
          </template>
          <FolderFilled
            style="font-size: 18px; cursor: pointer"
            @click.prevent.stop="openFolder(record.id)"
          />
        </a-tooltip>
        <PictureOutlined
          v-else-if="record.isImage"
          style="font-size: 18px;"
        />
        <YoutubeOutlined
          v-else-if="record.isVideo"
          style="font-size: 18px;"
        />
        <FileTextOutlined
          v-else-if="record.isDocument"
          style="font-size: 18px;"
        />
      </template>
    </template>
  </a-table>
</template>

<style lang="less">
@import "../../../styles/variables.less";
.actions {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  > span {
    cursor: pointer;
  }
}
.storage-table {
  .ant-image {
    width: 100%;
    cursor: pointer;
    .ant-image-img {
      height: inherit;
      object-fit: cover;
    }
  }
  .ant-table-row  {
    &.selectable {
      cursor: pointer;
    }
    &.selected {
      .ant-table-cell {
        background-color: var(--ant-primary-1);
      }
    }
  }
  .table-link {
    color: @text-color;
  }
  .img-spacer {
    height: 40px;
  }
}

</style>
