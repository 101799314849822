<script setup>
import { onMounted, ref } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { GoogleReviewsSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { debounce } from 'lodash-es'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: GoogleReviewsSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const { t } = useI18n()
const store = useStore()
const slide = props.slideObj || new GoogleReviewsSlide()
const { placeId } = slide.slideData
const placeDetails = ref(slide.placeDetails)
const selectRef = ref(null)
const googlePlace = ref('')
const googleReviewsPlaceId = ref(placeId)
const data = ref([{
  value: placeId,
  label: placeDetails.value?.name || placeId
}]);

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

onMounted(() => {
  selectRef.value && selectRef.value.focus()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}

const updateSlide = () => {
  emit('update-slide', slide)
}

const updateSlideData = () => {
  slide.updateSlideData({
    placeId: googleReviewsPlaceId.value,
  })
}

const toggleDisabled = (status) => {
  emit('update:disabled', status)
}

const debouncedOnSearch = debounce((val) => {
  store.dispatch('slides/getGooglePlaceAutocomplete', { query: val }).then(d => {
    data.value = d.map(item => {
      return {
        value: item.place_id,
        label: item.description,
      }
    })
  })
}, 300)

const handleSearch = val => {
  debouncedOnSearch(val)
}

const handleChange = val => {
  googleReviewsPlaceId.value = val;
  updateSlideData()
  updateSlide()
  toggleDisabled(!googleReviewsPlaceId.value)
}
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.google_reviews.slideStep1.subTitle')"
    :title="$t('components.slides.google_reviews.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-select
        ref="selectRef"
        v-model:value="googleReviewsPlaceId"
        show-search
        :placeholder="$t('components.slides.google_reviews.slideStep1.googleReviewsPlaceIdPlaceholder')"
        style="width: 100%"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        :options="data"
        @search="handleSearch"
        @change="handleChange"
      />
    </a-form>
  </SlideStep>
</template>

<style lang="less">

</style>
