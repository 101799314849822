<template>
  <icon>
    <template #component>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="1"
            fill="#000000"
          >
            <g
              id="Fonts-style-Thumbnails/1-Hover"
              transform="translate(10.000000, 13.000000)"
            >
              <polygon
                id="M"
                points="21.171875 0.453125 29.328125 0.453125 29.328125 3.390625 26.484375 3.390625 26.484375 21.0625 29.328125 21.0625 29.328125 24 20.46875 24 20.46875 21.0625 23.25 21.0625 23.25 3.390625 15.15625 24.25 14.453125 24.25 6.421875 3.390625 6.421875 21.0625 9.015625 21.0625 9.015625 24 0.65625 24 0.65625 21.0625 3.515625 21.0625 3.515625 3.390625 0.65625 3.390625 0.65625 0.453125 8.734375 0.453125 14.9375 16.578125"
              />
            </g>
          </g>
        </g>
      </svg>
    </template>
  </icon>
</template>

<script>
import { defineComponent } from 'vue'
import Icon from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AlignIcon',
  components: {
    Icon
  }
})
</script>
