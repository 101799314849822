<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { error } from '@/utils'

const GTM_METHOD_MAP = {
  google: 'google',
  microsoft: 'ms',
  oidc: 'sso'
}

defineProps({
  token: String
})

const route = useRoute()
const router = useRouter()
const store = useStore()
const gtm = inject('gtm')
const userId = computed(() => store.getters['user/userId'])
const isSignup = computed(() => route.name === 'Signup')
const workspaceLogo = computed(() => store.getters['workspace/media/logo'] || store.getters['auth/logo'])

onMounted(async () => {
  window.addEventListener('message', receiveMessage, false)
})

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage)
})

const receiveMessage = ({ data }) => {
  try {
    data = JSON.parse(data)
  } catch (e) {
    return
  }

  let {
    action,
    platform,
    state,
    code,
    error: errorMessage
  } = data

  if (errorMessage) {
    return error(errorMessage)
  }

  if (state && code) {
    platform = 'oidc'
    action = 'login'
  }

  if (!action || !platform) return

  let input, dispatchActionName
  switch (action) {
    case 'login':
      switch (platform) {
        case 'google':
          input = { code }
          dispatchActionName = 'auth/loginWithGoogle'
          break
        case 'microsoft':
          input = { code }
          dispatchActionName = 'auth/loginWithMicrosoft'
          break
        case 'oidc':
          input = { code, state }
          dispatchActionName = 'auth/loginWithOIDC'
          break
      }
  }
  const method = GTM_METHOD_MAP[platform]
  if (!input || !dispatchActionName) return
  store.dispatch(dispatchActionName, input).then((isSignup) => {
    const event = isSignup
      ? { event: 'RegistrationForm', action: 'show', method, label: 'success', userId: userId.value }
      : { event: `Login`, action: 'success', method, label: 'success', userId:userId.value }
    gtm.push(event)
    redirectHome()
  }).catch(e => {
    error(e.message)
  })
}

const redirectHome = () => {
  router.push({name: 'Home', query: { ...route.query }})
}

const toggleGlobalStyles = (add) => {
  const body = document.body
  if (add) {
    body.classList.add('auth-page-active')
  } else {
    body.classList.remove('auth-page-active')
  }
}

onMounted(() => {
  toggleGlobalStyles(true)
})

onUnmounted(() => {
  toggleGlobalStyles(false)
})

</script>

<template>
  <div id="auth-page">
    <header class="auth-header">
      <div class="logo">
        <a
          href="https://kitcast.tv"
          target="_blank"
        >
          <img
            :src="workspaceLogo || require( `@/assets/kitcast-logo-black.svg`)"
            alt=""
          >
        </a>
      </div>
      <router-link
        v-if="isSignup"
        :to="{name:'Login'}"
      >
        <a-button
          class="btn-black"
        >
          {{ $t('components.authPage.loginButtonText') }}
        </a-button>
      </router-link>
    </header>
    <main class="auth-main">
      <div>
        <div class="form-container">
          <transition
            mode="out-in"
            name="fade"
          >
            <router-view :token="token" />
          </transition>
        </div>
      </div>
      <div class="bg-container" />
    </main>
  </div>
</template>

<style scoped lang="less">
#auth-page {
  position: relative;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  .auth-header {
    padding: 0 64px;
    position: absolute;
    top: 0;
    left: 0;
    height: 72px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    .btn-black {
      background-color: transparent;
      &:not(:hover) {
        color: #000;
        border-color: #000;
      }
      &:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
      }
    }
  }
  .auth-main {
    display: flex;
    height: 100%;
    > div {
      flex: 0 0 50%;
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: auto;
        .form-container {
          width: 472px;
          padding: 120px 20px 80px 20px;
        }
      }
      &.bg-container {
        background: url("../../assets/images/kc-auth-bg.png") no-repeat center center;
        background-size: cover;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .auth-main {
      > div {
        flex: 1;
        &.bg-container {
          display: none;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .auth-header {
      padding: 0 20px;
      background-color: #ffffff;
    }
    .auth-main {
      > div:first-child {
        .form-container {
          width: 100%;
        }
      }
    }
  }
}
</style>
