<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { TastySlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: TastySlide,
  groupId: String,
  zoning: Boolean,
  disabled: Boolean
})
const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, TastySlide, {
  setMute: props.zoning,
  disableOnNewSlide: true
})

const MAX_TAGS_COUNT = 5

const store = useStore()
const { tags } = slide.value.slideData
const continueVideoOnRestarts = ref(!!slide.value.slideData.continueVideoOnRestarts)
const tastyTags = computed(() => store.getters['slides/tastyTags'] || [])
const tastyTagsOptions = computed(() => tastyTags.value?.map(({name, display_name}) => ({
  label: display_name,
  value: name
})))

const selectedTags = ref(tags || [])

onMounted(()=>{
  store.dispatch('slides/getTastyTags')
})

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const handleTagsChange = (value) => {
  if (selectedTags.value?.length > MAX_TAGS_COUNT) {
    selectedTags.value = selectedTags.value.filter(v => v !== value)
  }
  if (!selectedTags.value?.length) {
    return toggleDisabled(true)
  }
  updateSlideData({
    tags: selectedTags.value
  })
  updateSlide()
  toggleDisabled(false)
}

watch(() => continueVideoOnRestarts.value, () => {
  updateSlideData({
    continueVideoOnRestarts: continueVideoOnRestarts.value
  })
  updateSlide()
})
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.tasty.slideStep1.subTitle')"
    :title="$t('components.slides.tasty.slideStep1.title')"
  >
    <a-select
      v-model:value="selectedTags"
      style="width: 100%;"
      show-search
      mode="multiple"
      :options="tastyTagsOptions"
      allow-clear
      :filter-option="filterOption"
      placeholder="Select Topic"
      @select="handleTagsChange"
      @deselect="handleTagsChange"
    />
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.tasty.slideStep2.title')"
    no-border
  >
    <a-radio-group v-model:value="continueVideoOnRestarts">
      <div style="margin-bottom: 8px;">
        <a-radio
          :value="true"
        >
          {{ $t('components.slides.tasty.slideStep2.continuePlayback') }}
        </a-radio>
      </div>
      <div>
        <a-radio
          :value="false"
        >
          {{ $t('components.slides.tasty.slideStep2.playNewRecipe') }}
        </a-radio>
      </div>
    </a-radio-group>
  </SlideStep>
</template>

<style lang="less">
.preview-video {
  width: 100%;
  display: block;
}

</style>
