const assetBase = process.env.VUE_APP_STATIC_ASSETS + '/composer/images/'

const gradients = [
  {
    src: 'gradient_1.jpg',
    preview: 'gradient_1_preview.jpg',
  },
  {
    src: 'gradient_2.jpg',
    preview: 'gradient_2_preview.jpg',
  },
  {
    src: 'gradient_3.jpg',
    preview: 'gradient_3_preview.jpg',
  },
  {
    src: 'gradient_4.jpg',
    preview: 'gradient_4_preview.jpg',
  },
  {
    src: 'gradient_5.jpg',
    preview: 'gradient_5_preview.jpg',
  },
  {
    src: 'gradient_6.jpg',
    preview: 'gradient_6_preview.jpg',
  },
  {
    src: 'gradient_7.jpg',
    preview: 'gradient_7_preview.jpg',
  },
  {
    src: 'gradient_8.jpg',
    preview: 'gradient_8_preview.jpg',
  },
  {
    src: 'gradient_9.jpg',
    preview: 'gradient_9_preview.jpg',
  },
  {
    src: 'gradient_10.jpg',
    preview: 'gradient_10_preview.jpg',
  },
  {
    src: 'gradient_11.jpg',
    preview: 'gradient_11_preview.jpg',
  },
  {
    src: 'gradient_12.jpg',
    preview: 'gradient_12_preview.jpg',
  },
  {
    src: 'gradient_13.jpg',
    preview: 'gradient_13_preview.jpg',
  }
]
const patterns = [
  'a.jpg',
  'aa.png',
  'ae.jpg',
  'af.png',
  'ag.jpg',
  'ah.png',
  'ai.png',
  'aj.png',
  'ak.png',
  'am.jpg',
  'an.jpg',
  'ao.gif',
  'ap.jpg',
  'aq.jpg',
  'ar.png',
  'as.gif',
  'at.png',
  'au.jpg',
  'ax.jpg',
  'az.png',
  'b.jpg',
  'ba.jpg',
  'bc.png',
  'c.jpg',
  'd.png',
  'f.jpg',
  'h.jpg',
  'i.jpg',
  'j.jpg',
  'l.jpg',
  'p.gif',
  'q.png',
  'r.png',
  's.png',
  't.gif',
  'u.png',
  'v.png',
  'x.png',
  'y.png',
  'z.png',
  'zz.jpg'
]

export const filters = [
  { name: 'grayscale' },
  { name: 'invert' },
  { name: 'sepia' },
  {
    name: 'removeWhite',
    options: {
      distance: { current: 0.1 },
      threshold: { current: 50 }
    }
  },
  {
    name: 'brightness',
    options: {
      brightness: { current: 50 }
    }
  },
  {
    name: 'noise',
    options: {
      noise: { current: 40, max: 600 }
    }
  },
  {
    name: 'GradientTransparency',
    displayName: 'Gradient',
    options: {
      threshold: { current: 40 }
    }
  },
  {
    name: 'pixelate',
    options: {
      blocksize: { max: 40, current: 2 }
    }
  },
  {
    name: 'tint',
    options: {
      opacity: { current: 0.5, min: 0.1, max: 1, step: 0.1 },
      color: { colorpicker: true, current: '#FF4081' }
    }
  },
  {
    name: 'multiply',
    options: {
      color: { colorpicker: true, current: '#FF4081' }
    }
  },
  {
    name: 'blend',
    options: {
      mode: { current: 'add', select: true, available: ['add', 'multiply', 'subtract', 'diff', 'screen', 'lighten', 'darken'] },
      alpha: { current: 0.5, min: 0.1, max: 1, step: 0.1 },
      color: { colorpicker: true, current: '#FF4081' }

    }
  }
]

export default {
  gradients: gradients.map(g => ({
    src: assetBase + 'gradients/' + g.src,
    thumbnail:assetBase + 'gradients/' + g.preview,
    key: g.src
  })),
  patterns: patterns.map(p => ({
    src: assetBase + 'patterns/' + p,
    thumbnail: assetBase + 'patterns/' + p,
    key: p
  })),
  filters: filters.map(f => ({
    name: f.name,
    thumbnail: `${assetBase}filters/${f.name.toLowerCase()}.png` ,
    key: f.name.toLowerCase(),
    filter: f
  }))
}
