<script setup>

import { computed, ref } from 'vue'
import MediaView from '@/views/MediaView.vue'
import { useStore } from 'vuex'
import ModalHeading from '@/components/ModalHeading.vue'

const store = useStore()
const showMedia = computed(() => store.getters.isModalVisible('media'))
const mediaView = ref(null)

const closeModal = () => {
  store.commit('media/CLEAR_PARENT_DIRECTORY_MEDIA_ID')
  store.commit('media/CLEAR_SELECTED_MEDIA')
  store.dispatch('closeModal', 'media')
  setTimeout(() => {
    mediaView.value && mediaView.value.resetView && mediaView.value.resetView()
  }, 300)
}

</script>

<template>
  <a-modal
    :open="showMedia"
    width="100%"
    :footer="null"
    wrap-class-name="full-modal media-modal"
    destroy-on-close
    @ok="closeModal"
    @cancel="closeModal"
  >
    <template #title>
      <ModalHeading
        :title="$t('components.mediaModal.title')"
        :subtitle="$t('components.mediaModal.subtitle')"
      />
    </template>
    <MediaView
      ref="mediaView"
      selection="multiple"
      external-selection="none"
    />
  </a-modal>
</template>

<style lang="less">

</style>
