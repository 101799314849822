<template>
  <DesignElement
    :no-border="noBorder"
    :title="$t('components.smartTemplateConstructor.backgroundPicker.title')"
  >
    <div class="background-picker">
      <div class="background-picker-element">
        <a-typography-text type="secondary">
          {{ $t('components.smartTemplateConstructor.backgroundPicker.animatedBackground') }}
        </a-typography-text>
        <div class="grid">
          <div
            v-for="animation in animationItems"
            :key="animation"
            :class="{active: background.animation === animation}"
            class="grid-item"
            @click="setBackgroundAnimation(animation)"
          >
            <AnimationPreview :animation="ANIMATIONS[animation]" />
          </div>
        </div>
      </div>
      <div>
        <MediaPicker
          :is-selected="customMediaSelected"
          :media-types="['IMAGE', 'VIDEO']"
          :selected="asset"
          @update="setBackgroundMedia"
        />
        <ColorPicker
          v-model:color="backgroundColor"
          :is-selected="colorSelected"
          title="Plain color"
          @selected="onColorSelect"
        />
      </div>
    </div>
  </DesignElement>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import DesignElement from '@/components/slideModal/smartTemplateConstructor/designElements/DesignElement'
import ColorPicker from '@/components/slideModal/smartTemplateConstructor/designElements/ColorPicker'
import { ANIMATIONS, DEFAULT_ANIMATIONS } from '@/components/slideModal/smartTemplateConstructor/animations/animations'
import AnimationPreview from '@/components/slideModal/smartTemplateConstructor/animations/AnimationPreview'
import MediaPicker from '@/components/slideModal/smartTemplateConstructor/generalElements/MediaPicker'

const DEFAULT_BACKGROUND_COLOR = '#000000'

export default defineComponent({
  name: 'BackgroundPicker',
  components: {
    MediaPicker,
    AnimationPreview,
    ColorPicker,
    DesignElement
  },
  props: {
    background: Object,
    noBorder: Boolean,
    asset: Object,
    animations: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update-background', 'update-assets'],
  setup (props, { emit }) {
    const layout = ref('center')
    const animationItems = computed(() => Object.keys(DEFAULT_ANIMATIONS).concat(props.animations))
    const backgroundColor = props.background?.color ? ref(props.background.color) : ref(DEFAULT_BACKGROUND_COLOR)
    const customMediaSelected = ref(false)
    const colorSelected = ref(false)

    const setBackgroundAnimation = (animation) => {
      emit('update-background', { animation })
      customMediaSelected.value = false
      colorSelected.value = false
    }

    const setBackgroundColor = (color = DEFAULT_BACKGROUND_COLOR) => {
      emit('update-background', { color })
      customMediaSelected.value = false
      colorSelected.value = true
    }

    const setBackgroundMedia = (media) => {
      emit('update-background', { [media.mediaType === 'IMAGE' ? 'mediaImageReference' : 'mediaVideoReference']: media.mediaReference })
      emit('update-assets', {
        assets: [media],
        key: 'background'
      })
      customMediaSelected.value = true
      colorSelected.value = false
    }

    const onColorSelect = () => {
      setBackgroundColor(backgroundColor.value)
    }

    watch(() => backgroundColor.value, (value) => {
      if (!value) {
        value = backgroundColor.value = DEFAULT_BACKGROUND_COLOR
      }
      setBackgroundColor(value)
    })

    watch(() => colorSelected.value, (value) => {
      if (value) {
        backgroundColor.value = backgroundColor.value || DEFAULT_BACKGROUND_COLOR
      }
    })

    return {
      layout,
      animationItems,
      backgroundColor,
      colorSelected,
      customMediaSelected,
      ANIMATIONS,
      setBackgroundMedia,
      setBackgroundAnimation,
      setBackgroundColor,
      onColorSelect
    }
  }
})

</script>

<style lang="less">
@import "@/styles/variables.less";

.background-picker {
  .background-picker-element {
    margin-bottom: 16px;

    .grid {
      gap: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 8px;

      .grid-item {
        border-radius: 4px;
        position: relative;
        aspect-ratio: 16/9;
        background: @bg-dark-grey;
        overflow: hidden;
        z-index: 1;

        &.active, &:hover {
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            box-shadow: inset 0 0 1px 3px var(--ant-primary-color);
          }
        }

        &:hover:not(.active) {
          cursor: pointer;
        }
      }
    }
  }
}

</style>
