<script setup>
import { onMounted, ref } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { TripAdvisorSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { debounce } from 'lodash-es'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'
import { useSlideControls } from '@/composables/useSlideControls'

const props = defineProps({
  title: String,
  slideObj: TripAdvisorSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])
const { slide, updateSlide, toggleDisabled, updateSlideData } = useSlideControls(emit, props.slideObj, TripAdvisorSlide)

const store = useStore()
const { locationId } = slide.value.slideData
const locationDetails = ref(slide.value.locationDetails)
const selectRef = ref(null)
const tripAdvisorLocationId = ref(locationId)
const locationsList = ref([{
  value: locationId,
  label: locationDetails.value?.name || locationId
}]);

onMounted(() => {
  selectRef.value && selectRef.value.focus()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}

const debouncedOnSearch = debounce((val) => {
  store.dispatch('slides/searchTripAdvisorLocation', { searchQuery: val }).then(d => {
    locationsList.value = d.map(item => {
      return {
        value: item.location_id,
        label: `${item.name} [${item.address_obj.address_string}]`,
      }
    })
  })
}, 300)

const handleLocationSearch = val => {
  debouncedOnSearch(val)
}

const handleLocationChange = val => {
  tripAdvisorLocationId.value = val
  updateSlideData({
    locationId: val
  })
  updateSlide()
  toggleDisabled(false)
}
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.tripAdvisor.slideStep1.subTitle')"
    :title="$t('components.slides.google_reviews.slideStep1.title')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-select
        ref="selectRef"
        v-model:value="tripAdvisorLocationId"
        show-search
        :placeholder="$t('components.slides.tripAdvisor.slideStep1.tripAdvisorLocationIdPlaceholder')"
        style="width: 100%"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        :options="locationsList"
        @search="handleLocationSearch"
        @change="handleLocationChange"
      />
    </a-form>
  </SlideStep>
</template>

<style lang="less">

</style>
