import WebFont from 'webfontloader'
import axios from 'axios'
const FONTS_KEY = 'AIzaSyAJ_Bang1mGaNYOR_Cc0P45uZyeanpMGko'

export default {
  namespaced: true,
  state: {
    loadedFonts: [],
    fonts: localStorage.getItem('googleFonts') ? JSON.parse(localStorage.getItem('googleFonts')) : [],
    loading: {
      fonts: false
    }
  },
  actions: {
    fetchFontsList({ commit, getters })  {
      if (getters.fonts.length > 0) return
      axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key='+FONTS_KEY)
        .then(({ data }) => {
          commit('SET_FONTS', data.items)
        })
        .catch(err => console.error(err))
    },
    preloadFontsFromJson({ commit, state }, {json, cb, onError}) {
      const objects = json.objects
      const usedFonts = [...new Set(objects
        .filter(obj => obj.fontFamily)
        .map(obj => obj.fontFamily)
        .filter(font => font !== 'Times New Roman')
      )]
      if (usedFonts.length === 0 || usedFonts.every(font => state.loadedFonts.includes(font))) {
        cb && cb()
        return
      }
      WebFont.load({
        google: {
          families: usedFonts
        },
        active() {
          commit('ADD_LOADED_FONTS', usedFonts)
          cb && cb()
        },
        inactive() {
          console.error('Fonts loading error', usedFonts)
          onError && onError(usedFonts);
        }
      })
    },
    loadFonts({ state, commit }, fonts) {
      if (fonts.length === 0) return
      if (fonts.every(font => state.loadedFonts.includes(font))) return
      commit('SET_FONTS_LOADING', true)
      WebFont.load({
        google: {
          families: fonts
        },
        active() {
          commit('ADD_LOADED_FONTS', fonts)
          commit('SET_FONTS_LOADING', false)
        }
      })
    },
    loadFont({ state, commit }, { fontFamily, cb }) {
      if (state.loadedFonts.includes(fontFamily)) {
        cb && cb()
        return
      }
      WebFont.load({
        google: {
          families: [fontFamily]
        },
        active() {
          commit('ADD_LOADED_FONTS', [fontFamily])
          cb && cb()
        }
      })
    }
  },
  getters:{
    fonts: state => state.fonts,
    fontsLoading: state => state.loading.fonts
  },
  mutations: {
    SET_FONTS_LOADING(state, status) {
      state.loading.fonts = status
    },
    ADD_LOADED_FONTS(state, fonts) {
      state.loadedFonts = [...new Set([...state.loadedFonts, ...fonts])]
    },
    SET_FONTS(state, fonts) {
      localStorage.setItem('googleFonts', JSON.stringify(fonts))
      state.fonts = fonts
    }
  }
}
