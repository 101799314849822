// canvasManager.js
import { Canvas } from 'fabric'

const canvasManager = {
  canvas: null,

  init(elementId, options = {}) {
    if (this.canvas) {
      return this.canvas
    }

    this.canvas = new Canvas(elementId, {
      selection: false,
      renderOnAddRemove: false,
      ...options
    })

    return this.canvas
  },

  get() {
    return this.canvas
  },

  destroy() {
    if (this.canvas) {
      this.canvas.dispose()
      this.canvas = null
    }
  }
}

export default canvasManager
