<script setup>
import CardSelectItem from '@/components/CardSelectItem'

defineProps({
  view: String,
  data: Array
})

const emit = defineEmits(['update:view'])

const updateView = (view) => {
  emit('update:view', view)
}

</script>

<template>
  <div class="slide-types">
    <CardSelectItem
      v-for="viewItem in data"
      :key="viewItem.view"
      :type="viewItem.view"
      :text="viewItem.text"
      :active="view === viewItem.view"
      :image="viewItem.image"
      :disabled="viewItem.disabled"
      centered
      @change-type="updateView"
    />
  </div>
</template>

<style lang="less">
.slide-types {
  display: flex;
  gap: 8px;
  .slide-type {
    flex: 1 1 0;
    width: auto;
    cursor: pointer;
    &:hover:not([disabled="true"]) {
      text-decoration: underline;
    }
    &[disabled="true"] {
      cursor: default;
      pointer-events: none;
      > figure {
        opacity: .3;
        .icon-container {
          opacity: 1;
        }
      }
    }
    &.active {
      > figure {
        overflow: hidden;
        .icon-container {
          background-color: #000;
          border-color: var(--ant-primary-color);
        }
      }
    }
    width: 200px;
    >figure {
      .icon-container {
        overflow: hidden;
        opacity: .5;
        aspect-ratio: 16/9;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px #f0f0f0;
        border-radius: 3px;
        background-color: #fff;
        margin-bottom: 8px;
        transition: background-color .3s ease;
        svg {
          transition: fill .3s ease;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
