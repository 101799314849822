<template>
  <div>
    <a-result
      v-if="emailSent"
      status="success"
      :title="$t('components.resetPasswordForm.successTitle')"
    >
      <template #extra>
        <router-link :to="{name: 'Login'}">
          <a-button>{{ $t('components.resetPasswordForm.backToLoginButtonText') }}</a-button>
        </router-link>
      </template>
    </a-result>
    <template v-else>
      <AuthHeading
        :title="$t('components.resetPasswordForm.welcome')"
        :subtitle="$t('components.resetPasswordForm.resetProcessDescription')"
      />
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formState"
        style="margin-top: 32px;"
        :rules="rules"
        @submit.prevent="resetPassword"
      >
        <a-form-item
          :label="$t('components.resetPasswordForm.emailLabel')"
          name="email"
        >
          <a-input
            v-model:value="formState.email"
            placeholder="name@company.com"
          />
        </a-form-item>
        <a-form-item style="margin-bottom: 16px; margin-top: 16px;">
          <a-button
            type="primary"
            block
            html-type="submit"
            size="large"
            :loading="loading"
          >
            <span v-if="!loading">{{ $t('components.resetPasswordForm.sendResetLinkText') }}</span>
            <span v-else>{{ $t('components.resetPasswordForm.sending') }}</span>
          </a-button>
        </a-form-item>
      </a-form>
      <div class="form-footer">
        <div class="spacer" />
        <p style="text-align: center; margin-top: 24px;">
          {{ $t('components.resetPasswordForm.noAccount') }}
          <router-link :to="{name: 'Signup'}">
            <a-typography-link>
              {{ $t('components.resetPasswordForm.signUpLink') }}
            </a-typography-link>
          </router-link>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'
import { error, success } from '@/utils'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AuthHeading from '@/views/AuthPage/components/AuthHeading.vue'

export default defineComponent({
  name: 'ResetPasswordForm',
  components: {
    AuthHeading
  },
  emits: ['change-action'],
  setup () {
    const store = useStore()
    const formRef = ref()
    const loading = ref(false)
    const { t } = useI18n()
    const emailSent = ref(false)
    const formState = reactive({
      email: ''
    })
    const rules = computed(()=>{
      return {
        email: [{
          required: true,
          type: 'email',
          trigger: 'change',
          message: t('components.resetPasswordForm.emailInvalidError')
        }]
      }
    })

    const resetPassword = async () => {
      if (loading.value) return
      setTimeout(async () => {
        try {
          await formRef.value.validate()
        } catch (e) {
          return
        }
        loading.value = true
        store.dispatch('user/requestPasswordReset', {email: formState.email}).then(() => {
          success()
          emailSent.value = true
        }).catch(e => {
          error(t('components.resetPasswordForm.accountDoesNotExist'))
        }).then(() => {
          loading.value = false
        })
      })
    }

    return {
      formState,
      loading,
      emailSent,
      rules,
      formRef,
      resetPassword
    }
  }
})
</script>

<style lang="less">
.form-footer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
