
import {
  GET_AVAILABLE_SMART_GROUPS,
  GET_AVAILABLE_STANDARD_GROUPS,
  GET_GROUP_BY_ID,
  GET_GROUP_PLAYLISTS_BY_ID,
  GET_GROUP_SCHEDULES
} from '@/graphql/queries'
import {
  CREATE_PLAYLIST,
  CREATE_SMART_GROUP,
  CREATE_STANDARD_GROUP,
  DELETE_PLAYLIST_BY_ID,
  DELETE_SMART_GROUP_BY_ID,
  DELETE_STANDARD_GROUP_BY_ID,
  UPDATE_SCHEDULE_BY_ID,
  UPDATE_SMART_GROUP_BY_ID,
  UPDATE_SMART_GROUP_BY_ID_EXTENDED,
  UPDATE_STANDARD_GROUP_BY_ID,
  UPDATE_STANDARD_GROUP_BY_ID_EXTENDED
} from '@/graphql/mutations'
import { updateEntityById } from '@/utils'
import { SMART_GROUP_TYPE, STANDARD_GROUP_TYPE } from '@/constants'
import { copyText } from 'vue3-clipboard'
import { apolloCall } from '@/helpers/Graphql'

function getGroupConfig(type) {
  const isStandardGroup = type === STANDARD_GROUP_TYPE
  return {
    isStandardGroup,
    key: isStandardGroup ? 'standardGroups' : 'smartGroups',
    createMutation: isStandardGroup ? CREATE_STANDARD_GROUP : CREATE_SMART_GROUP,
    deleteMutation: isStandardGroup ? DELETE_STANDARD_GROUP_BY_ID : DELETE_SMART_GROUP_BY_ID,
    updateMutation: isStandardGroup
      ? UPDATE_STANDARD_GROUP_BY_ID
      : UPDATE_SMART_GROUP_BY_ID,
    updateExtendedMutation: isStandardGroup
      ? UPDATE_STANDARD_GROUP_BY_ID_EXTENDED
      : UPDATE_SMART_GROUP_BY_ID_EXTENDED,
    createAction: isStandardGroup
      ? 'ADD_AVAILABLE_STANDARD_GROUP'
      : 'ADD_AVAILABLE_SMART_GROUP',
    updateAction: isStandardGroup
      ? 'UPDATE_AVAILABLE_STANDARD_GROUP'
      : 'UPDATE_AVAILABLE_SMART_GROUP',
  }
}

const sortGroups = (groups, sort) => {
    if (!sort) return groups
    groups = groups.sort((a, b) => {
      const titleA = a.title.toLowerCase()
      const titleB = b.title.toLowerCase()
      if (sort === 'asc') {
        return titleA.localeCompare(titleB) // По возрастанию
      } else if (sort === 'desc') {
        return titleB.localeCompare(titleA) // По убыванию
      }
      return 0
    })
    return groups
}


function getMaxDepth(tree) {
  if (!tree.children || tree.children.length === 0) {
    return 1
  }
  let maxChildDepth = 0
  for (let child of tree.children) {
    let childDepth = getMaxDepth(child)
    maxChildDepth = Math.max(maxChildDepth, childDepth)
  }
  return 1 + maxChildDepth;
}

const setGroupsDepth = (input, maxDepth, depth = 0) => {
  if (Array.isArray(input) && input.length) {
    input.map(g => {
      g.depth = depth
      g.maxGroupDepth = getMaxDepth(g) - 1
      if (depth + 1 === maxDepth) {
        g.children = []
        return
      }
      g.children && setGroupsDepth(g.children, maxDepth, depth + 1)
    })
  }
}

const removeGroupsNodesWithNoChildren = (tree) => {
  for (let i = tree.length - 1; i >= 0; i--) {
    if (tree[i].isPlaylist) {
      continue
    }
    if (tree[i].children.length === 0) {
      tree.splice(i, 1)
    } else {
      removeGroupsNodesWithNoChildren(tree[i].children)
    }
  }
}

function setFieldForAllChildren({ node, field, value }) {
  if (!node) return
  node[field] = value
  if (node.children && node.children.length > 0) {
    node.children.forEach((node) => setFieldForAllChildren({node, field, value}))
  }
}

const createGroupIdMap = (groups = []) => {
  return groups.reduce((acc, group) => {
    acc[group.id] = group.name
    return acc
  }, {})
}

const createPlaylistIdMap = (groups = []) => {
  return groups.reduce((acc, group) => {
    const playlistsMap = group.playlists?.reduce((plAcc, playlist) => {
      plAcc[playlist.id] = playlist.name
      return plAcc
    }, {})
    return { ...acc, ...playlistsMap }
  }, {})
}

const JamfConfigTemplate = `<dict>
      <key>secret</key>
      <string>%TOKEN%</string>
      <key>externalDeviceId</key>
      <string>$UDID</string>
      <key>deviceName</key>
      <string>$DEVICENAME</string>
      <key>groupId</key>
      <string>%GROUP_ID%</string>
      </dict>`

export default {
  namespaced: true,
  state: {
    availableStandardGroups: null,
    availableSmartGroups: null,
    currentGroup: null,
    currentGroupSchedules: [],
    currentGroupDevices: [],
    currentGroupPlaylists: [],
    currentGroupForcedPlaylist: null,
    currentGroupRootGroup: null,
    loading: {
      standardGroups: false,
      smartGroups: false,
      currentGroup: false,
      schedules: false,
      playlists: false
    }
  },
  actions: {

    async getAvailableStandardGroups ({ commit }) {
      const { listAvailableStandardGroups } = await apolloCall({
        commit,
        query: GET_AVAILABLE_STANDARD_GROUPS,
        key: 'standardGroups'
      })
      commit('SET_AVAILABLE_STANDARD_GROUPS', listAvailableStandardGroups)
    },
    async getAvailableSmartGroups ({ commit }, setEmpty) {
      if (setEmpty) {
        return commit('SET_AVAILABLE_SMART_GROUPS', [])
      }
      const { listAvailableSmartGroups } = await apolloCall({
        commit,
        query: GET_AVAILABLE_SMART_GROUPS,
        key: 'smartGroups'
      })
      commit('SET_AVAILABLE_SMART_GROUPS', listAvailableSmartGroups)
    },
    async createGroup ({ commit }, { input, type }) {
      const { key, createMutation, createAction, isStandardGroup} = getGroupConfig(type)
      if (!isStandardGroup) {
        delete input.language
      }
      const result = await apolloCall({
        commit,
        mutation: createMutation,
        variables: { input },
        key
      })
      const group = result?.createStandardGroup || result?.createSmartGroup
      commit(createAction, group)
      return group
    },
    async updateGroup({ commit, getters }, { id, input, type }) {
      const { key, updateExtendedMutation, updateAction, isStandardGroup} = getGroupConfig(type)
      if (!isStandardGroup) {
        delete input.language
      }

      const result = await apolloCall({
        commit,
        mutation: updateExtendedMutation,
        variables: { id, input },
        key,
      })

      const group = result?.updateStandardGroupById || result?.updateSmartGroupById
      const isCurrentGroup = getters.currentGroupId === id
      if (group) {
        commit(updateAction, group)
        if (isCurrentGroup) {
          commit('UPDATE_CURRENT_GROUP', group)
        }
      }

      return group
    },
    async updateCurrentGroup ({ dispatch, getters }, { input }) {
      const id = getters.currentGroupId
      const type = getters.currentGroupType
      await dispatch('updateGroup', { id, input, type })
    },
    async deleteGroup({ commit, dispatch }, { id, type }) {
      const { key, deleteMutation, isStandardGroup} = getGroupConfig(type)

      await apolloCall({
        commit,
        mutation: deleteMutation,
        variables: { id },
        key
      })

      if (isStandardGroup) {
        await dispatch('getAvailableStandardGroups')
      } else {
        await dispatch('getAvailableSmartGroups')
      }
    },
    async setCurrentGroup ({ commit, getters, dispatch, state }, { groupId }) {
      if (getters.availableStandardGroups.some(g => g.id === groupId) || getters.availableSmartGroups.some(g => g.id === groupId)) {
        await Promise.all([
          dispatch('fetchCurrentGroup', { groupId }),
          dispatch('fetchGroupPlaylists', { groupId }),
        ])
      }
      else {
        throw new Error('Group not found')
      }
    },
    async fetchCurrentGroup ({ commit, getters, dispatch, state }, { groupId }) {
      if (!groupId || groupId === getters.currentGroupId) {
        return
      }
      const { getGroupById: group } = await apolloCall({
        commit,
        query: GET_GROUP_BY_ID,
        variables: { id: groupId },
        key: 'currentGroup'
      })
      await commit('SET_CURRENT_GROUP', group)
      dispatch('setCurrentGroupRootGroup')
      commit('SET_CURRENT_GROUP_FORCED_PLAYLIST', group.schedule?.recursiveForcedPlaylist)
    },

    async fetchGroupPlaylists({ commit }, { groupId }) {
      if (!groupId) return
      const { getGroupById: { playlists } } = await apolloCall({
        commit,
        query: GET_GROUP_PLAYLISTS_BY_ID,
        variables: { id: groupId },
        key: 'playlists'
      })

      if (playlists) {
        commit('SET_CURRENT_GROUP_PLAYLISTS', playlists)
      }
    },
    async fetchCurrentGroupPlaylists({ dispatch, getters }) {
      const groupId = getters.currentGroupId
      return await dispatch('fetchGroupPlaylists', { groupId })
    },
    setCurrentGroupRootGroup ({commit, getters}) {
      let groupId = getters.currentGroupId
      const findRootGroup = (groupId, groups) => {
        const group = groups.find(g => g.id === groupId)
        if (!group) return null
        if (!group.parentGroupId) return group
        return findRootGroup(group.parentGroupId, groups)
      }
      commit('SET_CURRENT_GROUP_ROOT_GROUP', findRootGroup(groupId, getters.availableStandardGroups))
    },
    async createCurrentGroupPlaylist ({ getters, commit, state }, payload) {
      const groupId = getters.currentGroupId
      const { createPlaylist } = await apolloCall({
        commit,
        mutation: CREATE_PLAYLIST,
        variables: { input: { ...payload, groupId: getters.currentGroupId } },
        key: 'playlists'
      })
      commit('ADD_CURRENT_GROUP_PLAYLIST', createPlaylist)
      commit('ADD_PLAYLIST_TO_AVAILABLE_GROUPS', { playlist: createPlaylist, groupId })
      return createPlaylist
    },
    async deleteCurrentGroupPlaylist ({ getters, commit, state }, payload) {
      const { id } = payload
      await apolloCall({
        commit,
        mutation: DELETE_PLAYLIST_BY_ID,
        variables: payload,
        key: 'playlists'
      })
      commit('REMOVE_CURRENT_GROUP_PLAYLIST', id)
      if (id === getters.currentGroupForcedPlaylistId) {
        commit('REMOVE_CURRENT_GROUP_FORCED_PLAYLIST')
      }
    },
    async getCurrentGroupSchedules ({ getters, commit }) {
      const groupId = getters.currentGroupId
      if (!groupId) return
      const { getHierarchySchedulesByGroupId } = await apolloCall({
        commit,
        query: GET_GROUP_SCHEDULES,
        variables: { groupId },
        key: 'schedules'
      })
      commit('SET_CURRENT_GROUP_SCHEDULES', getHierarchySchedulesByGroupId)
    },
    async updateCurrentGroupSchedule ({ state, commit, getters }, input) {
      const { updateScheduleById } = await apolloCall({
        commit,
        mutation: UPDATE_SCHEDULE_BY_ID,
        variables: input,
        key: 'schedules'
      })
      commit('UPDATE_CURRENT_GROUP_SCHEDULE', updateScheduleById)
      commit('SET_CURRENT_GROUP_FORCED_PLAYLIST', updateScheduleById?.data?.forcedPlaylist)
    },
    async updateGroupScheduleByGroupId ({ commit, getters }, { groupId, input }) {
      const { getGroupById: { schedule: { id: scheduleId } } } = await apolloCall({
        commit,
        query: GET_GROUP_BY_ID,
        variables: { id: groupId },
        key: 'currentGroup'
      })
      const { updateScheduleById } = await apolloCall({
        commit,
        mutation: UPDATE_SCHEDULE_BY_ID,
        variables: { id: scheduleId, input },
        key: 'schedules'
      })
      commit('UPDATE_CURRENT_GROUP_SCHEDULE', updateScheduleById)
      commit('SET_CURRENT_GROUP_FORCED_PLAYLIST', updateScheduleById?.data?.forcedPlaylist)
    },
    copyGroupJAMFSettingsToClipboard ({getters, rootGetters}, {groupId, cb}) {
      const token = rootGetters['workspace/deviceInitializationToken']
      groupId = groupId || getters.currentGroupId
      if (!token || !groupId) return
      copyText(JamfConfigTemplate.replace('%TOKEN%', token).replace('%GROUP_ID%', groupId), undefined, (error, event) => {
        if (error) {
        } else {
          cb && cb()
        }
      })
    },
    async resetModule ({ commit }) {
      commit('CLEAR_GROUPS_DATA')
    }
  },
  getters: {
    availableGroups: state => [...state.availableStandardGroups, ...state.availableSmartGroups],
    availableStandardGroups: state => state.availableStandardGroups || [],
    availableSmartGroups: state => state.availableSmartGroups || [],
    availableStandardGroupsIdMap: (state, getters) => createGroupIdMap(getters.availableStandardGroups),
    availableStandardGroupsPlaylistsIdMap: (state, getters) => createPlaylistIdMap(getters.availableStandardGroups),
    availableSmartGroupsIdMap: (state, getters) => createGroupIdMap(getters.availableSmartGroups),
    availableSmartGroupsPlaylistsIdMap: (state, getters) => createPlaylistIdMap(getters.availableSmartGroups),
    availableStandardGroupsTree: (state, getters) => ({ groupIdToExclude, excludeBranch, playlistIdToExclude, nonSelectableParentGroupId, includeRoot, setDepth, maxGroupsDepth, selectablePlaylists, checkable, sort = 'asc' } = {}) => {
      const availableGroups = getters.availableStandardGroups
      if (!availableGroups.length) return []
      selectablePlaylists = selectablePlaylists || !!playlistIdToExclude
      let groups = availableGroups.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map(({ id, name, parentGroupId, playlists, language, createdAt }, index) => {
        playlists = playlists.filter(p => p.id !== playlistIdToExclude)
        return {
          key: id,
          title: name,
          parentGroupId,
          createdAt,
          playlists,
          language,
          groupId: id,
          isGroup: true,
          value: id,
          selectable: !checkable && groupIdToExclude !== id && nonSelectableParentGroupId !== id,
          checkable: checkable || !selectablePlaylists,
          children: (!selectablePlaylists || excludeBranch)
            ? []
            : [...playlists.map(({ id: playlistId, name }) => {
              return {
                key: playlistId,
                title: name,
                parentGroupId: id,
                children: [],
                value: playlistId,
                checkable: true,
                isPlaylist: true
              }
            })]
        }
      })

      groups = sortGroups(groups, sort)

      const map = {}
      let node
      let nodeToExclude
      const roots = includeRoot
        ? [{
          key: null,
          title: 'Root',
          value: 'ROOT',
          parentGroupId: null,
          children: []
        }]
        : []

      for (let i = 0; i < groups.length; i += 1) {
        map[groups[i].key] = i
        if (groupIdToExclude && excludeBranch && groupIdToExclude === groups[i].key) {
          nodeToExclude = groups[i]
        }
      }

      for (let i = 0; i < groups.length; i += 1) {
        node = groups[i]
        if (node.parentGroupId) {
          groups[map[node.parentGroupId]]?.children.push(node)
        } else {
          roots.push(node)
        }
      }

      if (roots.length === 0) {
        for (let i = 0; i < groups.length; i += 1) {
          node = groups[i]
          if (!map[node.parentGroupId]) {
            roots.push(node)
          }
        }
      }

      if (excludeBranch && nodeToExclude) {
        setFieldForAllChildren({
          node: nodeToExclude,
          field: 'selectable',
          value: false
        })
      }

      (setDepth || maxGroupsDepth) && setGroupsDepth(roots, maxGroupsDepth)
      selectablePlaylists && removeGroupsNodesWithNoChildren(roots)
      if (roots[0]) {
        roots[0].first = true
      }
      return roots
    },
    availableSmartGroupsTree: state => ({ groupIdToExclude, playlistIdToExclude, selectablePlaylists, checkable, sort } = {}) => {
      if (!state.availableSmartGroups) return []
      const availableGroups = state.availableSmartGroups
      selectablePlaylists = selectablePlaylists || !!playlistIdToExclude
      let groups = availableGroups.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).filter(({id}) => id !== groupIdToExclude)?.map(({ id, name, parentGroupId, playlists }) => {
        playlists = playlists.filter(p => p.id !== playlistIdToExclude)
        return {
          key: id,
          title: name,
          playlists,
          groupId: id,
          isGroup: true,
          value: id,
          selectable: !checkable && groupIdToExclude !== id,
          checkable: checkable || !selectablePlaylists,
          children: !selectablePlaylists
            ? []
            : [...playlists.map(({ id: playlistId, name }) => {
              return {
                key: playlistId,
                title: name,
                parentGroupId: id,
                children: [],
                value: playlistId,
                checkable: true,
                isPlaylist: true
              }
            })]
        }
      })

      selectablePlaylists && removeGroupsNodesWithNoChildren(groups)

      groups = sortGroups(groups, sort)

      return groups
    },
    rootGroup: state => state.currentGroupRootGroup,
    rootGroupMainPlaylist: state => state.currentGroupRootGroup?.playlists?.find(p => p.isMainPlaylist) || null,
    rootGroupId: state => state.currentGroupRootGroup?.id,
    someGroupsLoading: (state, getters) => getters.availableStandardGroupsLoading || getters.availableSmartGroupsLoading,
    availableStandardGroupsLoading: state => state.loading.standardGroups,
    availableStandardGroupsLoaded: state => !!state.availableStandardGroups,
    availableSmartGroupsLoading: state => state.loading.smartGroups,
    availableSmartGroupsLoaded: state => !!state.availableSmartGroups,
    availableGroupsLoaded: state => !!state.availableSmartGroups && !!state.availableStandardGroups,
    currentGroup: state => state.currentGroup,
    currentGroupLoaded: state => !!state.currentGroup,
    currentGroupName: state => state.currentGroup?.name,
    currentGroupId: state => state.currentGroup?.id,
    currentGroupType: state => state.currentGroup?.type,
    currentGroupTypeIsSmart: state => state.currentGroup?.type === SMART_GROUP_TYPE,
    currentGroupPlaylists: state => state.currentGroupPlaylists,
    currentGroupPlaylistsScheduled: state => state.currentGroupPlaylists?.filter(p=>p.isScheduled),
    currentGroupPlaylistsUnscheduled: state => state.currentGroupPlaylists?.filter(p=>!p.isScheduled),
    currentGroupMainPlaylist: state => state.currentGroupPlaylists?.find(p => p.isMainPlaylist) || null,
    currentGroupPlaylistsMovable: state => state.currentGroupPlaylists?.length > 1,
    currentGroupDefaultPlaylistId: state => state.currentGroupPlaylists?.[0]?.id,
    currentGroupIsLoading: state => state.loading.currentGroup,
    currentGroupDeviceMatchingRule: state => state.currentGroup?.deviceMatchingRule || {},
    currentGroupSchedule: state => state.currentGroupSchedules?.[0],
    currentGroupSchedulesLoading: state => state.loading.schedules,
    currentGroupScheduleId: state => state.currentGroup?.schedule?.id,
    currentGroupParentSchedules: state => state.currentGroupSchedules?.slice(1),
    currentGroupForcedPlaylist: (state, getters) => {
      return state.currentGroupForcedPlaylist ? {
        ...state.currentGroupForcedPlaylist,
        groupName: getters.availableStandardGroups?.find( g => g.id === state.currentGroupForcedPlaylist.groupId)?.name
      } : null
    },
    currentGroupForcedPlaylistId: state => state.currentGroupForcedPlaylist?.id,
    currentGroupHasForcedPlaylist: state => !!state.currentGroupForcedPlaylist,
    playlistsFetching: state => state.loading.playlists,
  },
  mutations: {
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_AVAILABLE_STANDARD_GROUPS (state, availableGroups) {
      state.availableStandardGroups = availableGroups
    },
    ADD_AVAILABLE_STANDARD_GROUP (state, availableGroup) {
      state.availableStandardGroups = [...state.availableStandardGroups, availableGroup]
    },
    UPDATE_AVAILABLE_STANDARD_GROUP (state, availableGroup) {
      state.availableStandardGroups = updateEntityById(state, 'availableStandardGroups', availableGroup)
    },
    SET_AVAILABLE_SMART_GROUPS (state, availableGroups) {
      state.availableSmartGroups = availableGroups
    },
    ADD_AVAILABLE_SMART_GROUP (state, availableGroup) {
      state.availableSmartGroups = [...state.availableSmartGroups, availableGroup]
    },
    UPDATE_AVAILABLE_SMART_GROUP (state, availableGroup) {
      state.availableSmartGroups = updateEntityById(state, 'availableSmartGroups', availableGroup)
    },
    SET_CURRENT_GROUP (state, group) {
      state.currentGroup = group
    },
    SET_CURRENT_GROUP_ROOT_GROUP(state, group) {
      state.currentGroupRootGroup = group
    },
    SET_CURRENT_GROUP_FORCED_PLAYLIST (state, forcedPlaylist) {
      state.currentGroupForcedPlaylist = forcedPlaylist
    },
    REMOVE_CURRENT_GROUP_FORCED_PLAYLIST (state) {
      state.currentGroupForcedPlaylist = null
    },
    UPDATE_CURRENT_GROUP (state, group) {
      state.currentGroup = { ...state.currentGroup, ...group }
    },
    UPDATE_CURRENT_GROUP_SCHEDULE (state, schedule) {
      state.currentGroupSchedules = [
        schedule,
        ...state.currentGroupSchedules.slice(1)
      ]
    },
    SET_CURRENT_GROUP_SCHEDULES (state, groups) {
      state.currentGroupSchedules = groups
    },
    SET_CURRENT_GROUP_PLAYLISTS (state, playlists) {
      state.currentGroupPlaylists = playlists
    },
    UPDATE_CURRENT_GROUP_PLAYLIST (state, playlist) {
      state.currentGroupPlaylists = updateEntityById(state, 'currentGroupPlaylists', playlist)
    },
    ADD_CURRENT_GROUP_PLAYLIST (state, playlist) {
      state.currentGroupPlaylists = [...state.currentGroupPlaylists, playlist]
    },
    ADD_PLAYLIST_TO_AVAILABLE_GROUPS (state, {
      playlist,
      groupId
    }) {
      const group = state.availableStandardGroups.find(g => g.id === groupId) || state.availableSmartGroups.find(g => g.id === groupId)
      if (!group) return
      group.playlists = [...group.playlists, playlist]
    },
    REMOVE_CURRENT_GROUP_PLAYLIST (state, playlistId) {
      state.currentGroupPlaylists = state.currentGroupPlaylists?.filter(pl => pl.id !== playlistId)
    },
    CLEAR_GROUPS_DATA (state) {
      state.availableStandardGroups = null
      state.availableSmartGroups = null
      state.currentGroup = null
    }
  }
}
