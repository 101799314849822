<script setup>
import { useStore } from 'vuex'
import { computed, watch, watchEffect } from 'vue'
import ImagesList from '@/components/slideModal/CustomTemplateEditor/components/ImagesList.vue'

const filterNameMapReverse = {
  removecolor: "removewhite",
  colormatrix: "gradienttransparency",
}

const store = useStore()
const filtersList = computed(() => store.getters['customTemplate/filters'])
const activeObject = computed(() => store.getters['customTemplate/activeObject'])

const opacity = computed({
  get: () => activeObject.value ? (activeObject.value.opacity * 100) : 100,
  set: (value) => {
    if (activeObject.value) {
      store.dispatch('customTemplate/changeSelectedObjectOpacity', value / 100)
    }
  }
})

const activeFilters = computed(() => {
  if (!activeObject.value || activeObject.value.type !== 'image') return []
  return activeObject.value.filters.map((f) => {
    const filterName = (f.customType || f.type).toLowerCase()
    return filterNameMapReverse[filterName] || filterName
  })
})


const applyFilter = ({ filter }) => {
  store.dispatch('customTemplate/applyFilterToActiveObject', filter)
}

</script>

<template>
  <div style="display: flex; gap: 8px; width: 100%; align-items: center">
    Opacity
    <a-slider
      id="test"
      v-model:value="opacity"
      style="flex: 1;"
    />
    {{ opacity.toFixed(0) }}%
  </div>
  <a-divider style="margin: 0;" />
  <ImagesList
    :active-keys="activeFilters"
    :list="filtersList"
    @click="applyFilter"
  />
</template>

<style scoped lang="less">

</style>
